export enum ValidationErrorCode {
  NOT_UNIQUE = "NOT_UNIQUE",
  NOT_EMPTY = "NOT_EMPTY",
  NOT_NULL = "NOT_NULL",
  NOT_BLANK = "NOT_BLANK",
  DEFAULT_ERROR_CODE = "DEFAULT_ERROR_CODE",
  DATE_PERIOD_OVERLAPPING = "DATE_PERIOD_OVERLAPPING",
}

export default class ValidationModel {
  constructor(
    public field = "",
    public rejectedValue = "",
    public errorCode?: ValidationErrorCode
  ) {}

  static of(model: ValidationModel): ValidationModel {
    return new ValidationModel(
      model.field,
      model.rejectedValue,
      model.errorCode
    );
  }

  static ofArray(items: Array<ValidationModel>): Array<ValidationModel> {
    return items.map((item) => ValidationModel.of(item));
  }
}
