import { AxiosResponse } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
  GradientType,
  PredictedResultItem,
  REPORT_DATA_BY_REPORT_TYPE,
  ReportDataStore,
  ReportDataType,
  ReportFilter,
  ReportItemRowObject,
  ReportMetaType,
  ReportStoreType,
  ReportType,
} from "@/reports/models";
import ReportProvide from "@/reports/utils/ReportProvide";
import ReportUtil from "@/reports/utils/ReportUtil";
import {
  Application,
  DICTIONARIES_BY_REPORT_TYPE,
  DictionaryType,
  FilterId,
  FilterModel,
  FilterPreviewId,
  NotificationType,
  TrackerOrigin,
} from "@/shared/models";
import axios from "@/shared/plugins/axios";
import { setCommonFilter } from "@/shared/utils/CommonFilterUtil";
import EventSourceWrapper, {
  EventSourceFormat,
} from "@/shared/utils/EventSourceWrapper";

interface ReportData {
  data: string;
  filter: ReportFilter;
  dataType: ReportDataType;
}

@Module
export default class ReportStore extends VuexModule {
  localReport: ReportFilter | null = null;
  currentReport: ReportFilter | null = null;
  report: ReportStoreType = {
    TOTAL: new ReportDataStore(),
    SUB_TOTAL: new ReportDataStore(),
    DATA: new ReportDataStore(),
    DAU_DYNAMICS: new ReportDataStore(),
    REVENUE_SPENDINGS_DAILY_DYNAMICS: new ReportDataStore(),
    CUMULATED_REVENUE_SPENDINGS_PROFIT: new ReportDataStore(),
    CUMULATED_ARPU_STRUCTURE: new ReportDataStore(),
    CUMULATED_ARPU: new ReportDataStore(),
    CUMULATED_ARPU_COHORT: new ReportDataStore(),
    ROAS_N_DAY: new ReportDataStore(),
    ROAS_N_DAY_COHORT: new ReportDataStore(),
    RETENTION_RATE: new ReportDataStore(),
    RETENTION_RATE_COHORT: new ReportDataStore(),
    CUMULATED_PLAYTIME_PER_ACTIVE_USER: new ReportDataStore(),
    CUMULATED_PLAYTIME_PER_ACTIVE_USER_COHORT: new ReportDataStore(),
    SSB: new ReportDataStore(),
    SSB_CHART: new ReportDataStore(),
    MONTH_TOTAL: new ReportDataStore(),
    PREDICTED_CASH_COUNTRY: new ReportDataStore(),
    REVENUE_DIFF_PERCENT_DATE_COUNTRY: new ReportDataStore(),
    REVENUE_DIFF_PERCENT_DATE_NETWORK: new ReportDataStore(),
    ARPDAU_DIFF_DATE_COUNTRY: new ReportDataStore(),
    ARPDAU_DIFF_DATE_NETWORK: new ReportDataStore(),
    MONITORING: new ReportDataStore(),
  };
  reportPredict: Map<string, Record<string, any>> | null = null;
  reportPredictLoading = false;
  reportEventSource?: EventSourceWrapper;
  clickedFilterPreviewId: FilterPreviewId | null = null;
  isCommonReportFilterEnabled = false;
  commonReportFilter: Record<string, any> | null = null;
  tabHash?: string;
  reportKey: number | null = null;
  resetCache = false;
  currentDateAs: string | null = null;
  isReportCanceled = false;

  get isReportDataLoaded(): boolean {
    return (
      Object.values(this.report).every(({ loading }) => !loading) &&
      !this.reportPredictLoading
    );
  }

  get isReportLoading(): boolean {
    return Object.values(this.report).some(({ loading }) => loading);
  }

  @Mutation
  setTabHash(payload: string) {
    this.tabHash = payload;
  }

  @Mutation
  setCommonReportFilter(payload: Record<string, any>) {
    if (payload) {
      setCommonFilter(this.tabHash as string, payload);
    }

    this.commonReportFilter = payload;
  }

  @Mutation
  toggleCommonReportFilter() {
    this.isCommonReportFilterEnabled = !this.isCommonReportFilterEnabled;
  }

  @Mutation
  updateReportResultItems({
    dataType,
    filter,
  }: {
    dataType: ReportDataType;
    filter: ReportFilter;
  }) {
    if (!this.reportPredict || !this.report[dataType].items.length) {
      return;
    }

    this.report[dataType].items = (
      this.report[dataType].items as Array<PredictedResultItem>
    ).map((item) => {
      const keyList: Array<string> = [dataType];

      filter.groupByFilter.groupBy.forEach((filterId) => {
        const key = item.data[filterId.toLowerCase()];

        if (key) {
          keyList.push(key);
        }
      });

      if (item.date) {
        keyList.push(item.date);
      }

      const predict = this.reportPredict?.get(keyList.sort().join(""));

      if (!predict) {
        return item;
      }

      return ReportProvide.createResultItem(
        new ReportItemRowObject(
          Object.values(item.toRecord()).concat(Object.values(predict)),
          Object.keys(item.toRecord())
            .concat(Object.keys(predict))
            .reduce(
              (result, header, index) =>
                Object.assign(result, { [header]: index }),
              {}
            )
        ),
        filter,
        dataType
      );
    });
  }

  @Mutation
  fillReportPredict({ data, filter }: { data: string; filter: ReportFilter }) {
    const predictData = JSON.parse(data) as Record<
      ReportDataType,
      Array<Array<any>>
    >;

    this.reportPredict = Object.entries(predictData).reduce(
      (
        result: Map<string, Record<string, any>>,
        [dataType, dataTypeItems]: Array<any>
      ) => {
        const predictKeys = dataTypeItems[0];
        const groupByFilters = filter.groupByFilter.groupBy.map((filterId) =>
          filterId.toLowerCase()
        );
        const groupByIndexes: Array<number> = predictKeys.flatMap(
          (key: string, index: number) =>
            groupByFilters.includes(key) ? [index] : []
        );

        dataTypeItems.forEach((dataTypeItem: Array<any>, itemIndex: number) => {
          if (!itemIndex) {
            return;
          }

          const keyList = [dataType];
          const predictItem: Record<string, any> = {};

          dataTypeItem.forEach((value, index) => {
            // Decision will work, when we have installDate or date key
            if (
              groupByIndexes.includes(index) ||
              ["installDate", "date"].includes(predictKeys[index])
            ) {
              keyList.push(value);

              return;
            }

            predictItem[predictKeys[index]] = value;
          });

          result.set(keyList.sort().join(""), predictItem);
        });

        return result;
      },
      new Map([])
    );

    this.reportPredictLoading = false;
  }

  @Mutation
  setReportPredictLoading(value: boolean) {
    this.reportPredictLoading = value;
  }

  @Mutation
  setLoadingData(reportType: ReportType) {
    REPORT_DATA_BY_REPORT_TYPE[reportType].forEach((dataType) => {
      this.report[dataType].loading = true;
    });
  }

  @Mutation
  resetLoadingData(reportType: ReportType) {
    REPORT_DATA_BY_REPORT_TYPE[reportType].forEach((dataType) => {
      this.report[dataType].loading = false;
    });
  }

  @Mutation
  setLoadingDataByReportDataType({
    dataType,
    payload,
  }: {
    dataType: ReportDataType;
    payload: boolean;
  }) {
    this.report[dataType].loading = payload;
  }

  @Mutation
  setServerErrorMsgByReportDataType({
    dataType,
    payload,
  }: {
    dataType: ReportDataType;
    payload: string;
  }) {
    this.report[dataType].serverErrorMsg = payload;
  }

  @Mutation
  setReportEventSource(payload: EventSourceWrapper) {
    this.reportEventSource = payload;
  }

  @Mutation
  resetReportData() {
    this.report = Object.entries(this.report).reduce(
      (result, [type]) => ({
        ...result,
        [type]: new ReportDataStore(),
      }),
      {} as ReportStoreType
    );
    this.reportPredict = null;
    this.reportPredictLoading = false;
    this.reportEventSource?.close();
    this.reportEventSource = undefined;
  }

  @Mutation
  setClickedFilterPreviewId(previewId?: FilterPreviewId) {
    this.clickedFilterPreviewId = previewId ?? null;
  }

  @Mutation
  updateLocalReport(report?: ReportFilter) {
    this.localReport = report || null;
  }

  @Mutation
  updateCurrentReport(report?: ReportFilter) {
    this.currentReport = report || null;
  }

  @Mutation
  setReportKey(key?: number) {
    this.reportKey = key || null;
  }

  @Mutation
  setResetCache(value: boolean) {
    this.resetCache = value;
  }

  @Mutation
  setCurrentDateAs(value: string | null) {
    this.currentDateAs = value;
  }

  @Mutation
  setIsReportCanceled(value: boolean) {
    this.isReportCanceled = value;
  }

  @Mutation
  fillReportMetadata({
    data,
    dataType,
  }: {
    data: string;
    dataType: ReportDataType;
  }) {
    const meta: ReportMetaType = {
      ...JSON.parse(data),
      filledRows: this.report[dataType].chunks.length,
    };
    const chunks = new Array(meta.totalRows);

    this.report[dataType].chunks.forEach(
      (chunk, index) => (chunks[index] = chunk)
    );
    this.report[dataType].meta = meta;
    this.report[dataType].chunks = chunks;
  }

  @Mutation
  updateReportChunks({
    data,
    dataType,
  }: {
    data: string;
    dataType: ReportDataType;
  }) {
    const chunks = JSON.parse(data);
    const meta = this.report[dataType].meta;

    if (meta) {
      chunks.forEach(
        (chunk: Array<any>, index: number) =>
          (this.report[dataType].chunks[index + meta.filledRows] = chunk)
      );
      meta.filledRows += chunks.length;
    } else {
      this.report[dataType].chunks.push(...chunks);
    }
  }

  @Mutation
  fillReportResults({ filter, dataType }: ReportData) {
    const { meta, chunks } = this.report[dataType];

    if (!meta || meta.totalRows !== meta.filledRows) {
      return;
    }

    this.report[dataType] = new ReportDataStore(
      ReportProvide.getResultData(filter, meta.headers, chunks, dataType)
    );
  }

  @Action
  async loadReportDictionaries({
    reportType,
    apps,
    query,
  }: {
    reportType: ReportType;
    apps: Array<Application>;
    query: Record<string, any>;
  }) {
    this.context.commit("clearDictionaries");
    this.context.commit("resetReportData");
    this.context.commit("setReportKey");

    const dictionaryTypes = DICTIONARIES_BY_REPORT_TYPE[reportType];
    const origins: Array<TrackerOrigin> | null = dictionaryTypes.includes(
      DictionaryType.SOURCES
    )
      ? ReportUtil.getTrackerOrigins(reportType)
      : null;

    return this.context
      .dispatch("loadDictionaries", {
        app: apps.map(({ id }) => id).join(","),
        dictionaryTypes,
        origins,
      })
      .then(() => {
        let filter;

        if (Object.keys(query).length) {
          filter = query;
        } else if (this.commonReportFilter) {
          filter = {
            ...this.commonReportFilter,
            ...(this.commonReportFilter.isMultiApps
              ? {}
              : { apps: [this.commonReportFilter.app.id] }),
          };
        }

        this.context.commit(
          "updateCurrentReport",
          ReportUtil.getReportFilterByReportType(reportType, apps, filter)
        );

        if (
          query &&
          this.isCommonReportFilterEnabled &&
          !this.commonReportFilter
        ) {
          this.context.commit("setCommonReportFilter", this.currentReport);
        }
      });
  }

  @Action({ commit: "setReportEventSource" })
  async getReportData(report: ReportFilter) {
    this.context.commit("resetReportData");
    this.context.commit("setReportKey");
    this.context.commit("setLoadingData", report.reportId);

    const resetCache: string = this.resetCache ? `?resetCache=true` : "";
    const hasFirebaseCountries =
      report.reportId === ReportType.FIREBASE_FILL_RATE &&
      report.filter.some(
        ({ id }: FilterModel) => id === FilterId.EVENTS_COUNTRY
      );

    return axios
      .post(
        `/api/app/${report.getApp}/reportTables/${report.reportId}/generate${resetCache}`,
        {
          // TODO: temp solution for Firebase Fill Rate report countries
          ...(hasFirebaseCountries
            ? {
                ...report,
                filter: report.filter.reduce(
                  (
                    result: Array<Record<string, any>>,
                    filter: Record<string, any>
                  ) => {
                    if (filter.id === FilterId.EVENTS_COUNTRY) {
                      result.push({
                        ...filter,
                        values: filter.values.map((country: string) =>
                          this.context.getters.countryNameByValue[
                            country.toLowerCase()
                          ]?.slice(3)
                        ),
                      });
                    } else {
                      result.push(filter);
                    }

                    return result;
                  },
                  []
                ),
              }
            : report),
          ...(this.currentDateAs ? { currentDateAs: this.currentDateAs } : {}),
          availableApps: undefined,
        }
      )
      .then((result: AxiosResponse<string>) => {
        this.context.commit("setResetCache", false);
        this.context.commit("setCurrentDateAs", null);
        this.context.commit("setReportKey", result.data);

        if (report.hasPredict) {
          this.context.commit("setReportPredictLoading", true);
        }

        return (() => {
          const eventSource = new EventSourceWrapper(
            `/api/app/${report.getApp}/reportTables/${report.reportId}/${result.data}`,
            EventSourceFormat.PLAIN
          );

          eventSource.addEventListener(
            "reportVisualizationMetadata",
            (data, dataType) => {
              this.context.commit("fillReportMetadata", {
                data,
                dataType,
              });
              this.context.commit("fillReportResults", {
                filter: report,
                dataType,
              });

              if (report.hasPredict) {
                this.context.commit("updateReportResultItems", {
                  dataType,
                  filter: report,
                });
              }

              if (this.isReportDataLoaded) {
                this.context.commit("setIsReportCanceled", false);
                eventSource.close();
              }
            }
          );

          eventSource.addEventListener(
            "reportVisualizationChunk",
            (data, dataType) => {
              this.context.commit("updateReportChunks", {
                data,
                dataType,
              });
              this.context.commit("fillReportResults", {
                filter: report,
                dataType,
              });

              if (report.hasPredict) {
                this.context.commit("updateReportResultItems", {
                  dataType,
                  filter: report,
                });
              }

              if (this.isReportDataLoaded) {
                this.context.commit("setIsReportCanceled", false);
                eventSource.close();
              }
            }
          );

          if (report.hasPredict) {
            eventSource.addEventListener("reportPredict", (data: any) => {
              this.context.commit("fillReportPredict", {
                data,
                filter: report,
              });

              REPORT_DATA_BY_REPORT_TYPE[report.reportId].forEach(
                (dataType) => {
                  this.context.commit("updateReportResultItems", {
                    dataType,
                    filter: report,
                  });
                }
              );

              if (this.isReportDataLoaded) {
                eventSource.close();
              }
            });
          }

          eventSource.addEventListener(
            "serverError",
            (event: any, dataType) => {
              const data = JSON.parse(event);

              if (dataType === ReportDataType.MONITORING) {
                this.context.commit("setServerErrorMsgByReportDataType", {
                  dataType,
                  payload: data?.msg,
                });
              } else {
                this.context.dispatch(
                  "addError",
                  data?.msg ?? "An error occurred while generating the report"
                );
              }

              this.context.commit("setLoadingDataByReportDataType", {
                dataType,
                payload: false,
              });

              if (this.isReportDataLoaded) {
                this.context.commit("setIsReportCanceled", false);
                eventSource.close();
              }
            }
          );

          eventSource.addEventListener("canceled", () => {
            this.context.commit("resetLoadingData", report.reportId);
            this.context.commit("setIsReportCanceled", false);
            eventSource.close();
          });

          return eventSource;
        })();
      })
      .catch((error) => {
        if (
          error.response.status === 403 &&
          error.response.data.allowedAdNetworks
        ) {
          this.context.commit("addNotification", {
            message: `${
              error.response.data.msg
            } <br /><em>${error.response.data.allowedAdNetworks.join(
              ", "
            )}</em>`,
            type: NotificationType.ERROR,
            timeout: -1,
          });
        } else {
          this.context.dispatch("addError", error.response.data.msg);
        }

        this.context.commit("resetLoadingData", report.reportId);
      });
  }

  @Action
  downloadFile({
    apps,
    reportType,
    key,
    visualization,
    format,
    gradientType,
  }: {
    apps: string;
    reportType: ReportType;
    key: number;
    visualization: ReportDataType;
    format: string;
    gradientType: GradientType;
  }) {
    return axios
      .get(
        `/api/app/${apps}/reportTables/${reportType}/${key}/visualizations/${visualization}/export/${format}?gradientType=${gradientType}`,
        { responseType: "blob" }
      )
      .then(({ data }) => {
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");

        link.href = href;
        link.setAttribute(
          "download",
          `${ReportUtil.getReportNameByReportType(
            reportType
          )} (${visualization}).${format.toLowerCase()}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  @Action
  cancelCalculationReport(report: ReportFilter) {
    this.context.commit("setIsReportCanceled", true);

    return axios
      .delete(
        `/api/app/${report.getApp}/reportTables/${report.reportId}/${this.reportKey}`
      )
      .then(({ data }) => {
        if (!data) {
          this.context.commit("resetReportData");
          this.context.commit("setIsReportCanceled", false);
        }
      })
      .catch(() => {
        this.context.commit("setIsReportCanceled", false);
      });
  }
}
