import moment, { Moment } from "moment";

import DateFormats from "./DateFormats";

export default class DateUtil {
  static isWeekend(date: string) {
    const weekday = moment(date).isoWeekday();

    return weekday >= 6;
  }

  static format(date: any, format: string): string {
    return moment(date).format(format);
  }

  static formatDate(date: any): string {
    return DateUtil.format(date, DateFormats.DEFAULT_DATE_FORMAT);
  }

  static formatDateTime(date: any): string {
    return DateUtil.format(date, DateFormats.DEFAULT_DATE_TIME_FORMAT);
  }

  static formatForDatePicker(date: Moment | string): string {
    return DateUtil.format(date, DateFormats.DATES_PICKER_VALUE_FORMAT);
  }

  static formatForMonthPicker(date: Moment | string): string {
    return DateUtil.format(date, DateFormats.MONTH_PICKER_VALUE_FORMAT);
  }

  static formatFromDefaultToDatePicker(date: string): string {
    return DateUtil.formatForDatePicker(this.reverseDateString(date));
  }

  static formatFromDefaultToMonthPicker(date: string): string {
    return DateUtil.formatForMonthPicker(this.reverseDateString(date));
  }

  static formatRate(
    date: any,
    minAndMax: Array<string>,
    amount: 1 | 7,
    unit: "d" | "M"
  ): string {
    const to = moment.max([moment(date), moment(minAndMax[0])]);
    const from = moment.min([
      moment(date).add(amount, unit).subtract(1, "d"),
      moment(minAndMax[1]),
    ]);
    if (
      to.year() == from.year() &&
      to.month() == from.month() &&
      to.date() == from.date()
    ) {
      return to.format(DateFormats.DEFAULT_DATE_FORMAT);
    }
    if (to.year() == from.year()) {
      return (
        to.format("DD MMM") +
        " - " +
        from.format(DateFormats.DEFAULT_DATE_FORMAT)
      );
    }
    return (
      to.format(DateFormats.DEFAULT_DATE_FORMAT) +
      " - " +
      from.format(DateFormats.DEFAULT_DATE_FORMAT)
    );
  }

  static getLastDayOfMonth(date: string): string {
    return moment(date)
      .add(1, "M")
      .subtract(1, "d")
      .format(DateFormats.DATES_PICKER_VALUE_FORMAT);
  }

  static today(): string {
    return DateUtil.formatForDatePicker(moment());
  }

  static yesterday(): string {
    return DateUtil.formatForDatePicker(moment().subtract(1, "days"));
  }

  static twoDaysAgo(): string {
    return DateUtil.formatForDatePicker(moment().subtract(2, "days"));
  }

  static oneMonthAgo(): string {
    return DateUtil.formatForDatePicker(moment().subtract(1, "month"));
  }

  static halfYearAgo(): string {
    return DateUtil.formatForDatePicker(moment().subtract(182, "days"));
  }

  static addDays(date: string, days: number): string {
    return DateUtil.formatForDatePicker(moment(date).add(days - 1, "days"));
  }

  static subtractDays(date: string, days: number): string {
    return DateUtil.formatForDatePicker(
      moment(date).subtract(days - 1, "days")
    );
  }

  static addYears(date: string, years: number): string {
    return DateUtil.formatForDatePicker(moment(date).add(years, "years"));
  }

  static subtractYears(date: string, years: number): string {
    return DateUtil.formatForDatePicker(moment(date).subtract(years, "years"));
  }

  static daysAgoFromCurrent(days: number): string {
    return DateUtil.formatForDatePicker(moment().subtract(days, "days"));
  }

  static daysAfterCurrent(days: number): string {
    return DateUtil.formatForDatePicker(moment().add(days, "days"));
  }

  static startOfYear(date: string): string {
    return DateUtil.formatForDatePicker(moment(date).startOf("year"));
  }

  static endOfYear(date?: string): string {
    return DateUtil.formatForDatePicker(moment(date).endOf("year"));
  }

  static startOfLastYear(date: string): string {
    return DateUtil.formatForDatePicker(
      moment(date).subtract(1, "years").startOf("year")
    );
  }

  static endOfLastYear(date: string): string {
    return DateUtil.formatForDatePicker(
      moment(date).subtract(1, "years").endOf("year")
    );
  }

  static startOfMonth(date: string): string {
    return DateUtil.formatForDatePicker(moment(date).startOf("month"));
  }

  static endOfMonth(date?: string): string {
    return DateUtil.formatForDatePicker(moment(date).endOf("month"));
  }

  static startOfLastMonth(date: string): string {
    return DateUtil.formatForDatePicker(
      moment(date).subtract(1, "months").startOf("month")
    );
  }

  static endOfLastMonth(date: string): string {
    return DateUtil.formatForDatePicker(
      moment(date).subtract(1, "months").endOf("month")
    );
  }

  static getRelativeDate(date: string, initialDate: string): string {
    return DateUtil.formatForDatePicker(
      moment(date).add(moment().diff(moment(initialDate), "days"), "days")
    );
  }

  static getDaysDifference(dateA: string, dateB: string): number {
    return moment(dateB).diff(moment(dateA), "days");
  }

  static getMonthByMonthNUmber(monthNumber: number, format = "MMMM"): string {
    return moment(monthNumber, "M").format(format);
  }

  static getMonthNumberByMonthName(month: string): number {
    return Number(moment().month(month).format("M"));
  }

  static isValidDate(date: string): boolean {
    return moment(date).isValid();
  }

  static isAfter(dateA: string, dateB: string): boolean {
    return moment(this.reverseDateString(dateA)).isAfter(
      this.reverseDateString(dateB)
    );
  }

  static isSameOrBefore(dateA: string, dateB: string): boolean {
    return moment(dateA).isSameOrBefore(dateB);
  }

  static reverseDateString(date: string) {
    return date.split("-").reverse().join("-");
  }
}
