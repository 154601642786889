import { cloneDeep, uniq } from "lodash";

import { GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import {
  BaseGradientFlags,
  BaseReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { GroupByType, ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportDataType } from "./ReportVisualization";
import { ReportMetricType } from "./ReportMetricType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  Application,
  FilterPreview,
  FilterPreviewId,
  AdRevenueMethod,
  ProvidedBannerType,
  DefaultValues,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { MetricsConstructorReportFilterExtension } from "./ReportFilterExtension";
import { ReportItemRowObject } from "./ReportItemRowObject";
import { TimeSpentReportType } from "./TimeSpentReportType";

export enum MetricType {
  PREDEFINED = "PREDEFINED",
  USER = "USER",
}

export enum MetricStepId {
  BASIC_SETTINGS = "BASIC_SETTINGS",
  BASIC_METRICS = "BASIC_METRICS",
  CALCULATION_SETTINGS = "CALCULATION_SETTINGS",
  RESULT_SETTINGS = "RESULT_SETTINGS",
}

export enum BasicMetricType {
  INSTALLS_COUNT_ADJUST = "INSTALLS_COUNT_ADJUST",
  INSTALLS_COUNT_NETWORK = "INSTALLS_COUNT_NETWORK",
  SPEND = "SPEND",
  REVENUE = "REVENUE",
  REVENUE_EVENTS_COUNT = "REVENUE_EVENTS_COUNT",
  REVENUE_ACTIVE_USERS_COUNT = "REVENUE_ACTIVE_USERS_COUNT",
  TIME_SPENT = "TIME_SPENT",
  DAILY_ACTIVE_USERS_COUNT = "DAILY_ACTIVE_USERS_COUNT",
}

export enum CalculationModeType {
  FULL = "FULL",
  PYRAMID = "PYRAMID",
}

export enum OperationType {
  WITHOUT_PROCESSING = "WITHOUT_PROCESSING",
  ADD = "ADD",
  SUBTRACT = "SUBTRACT",
  MULTIPLY = "MULTIPLY",
  DIVIDE = "DIVIDE",
  PERCENT = "PERCENT",
}

export const OPERATION_BY_OPERATION_TYPE = new Map<OperationType, string>([
  [OperationType.WITHOUT_PROCESSING, ""],
  [OperationType.ADD, "+"],
  [OperationType.SUBTRACT, "-"],
  [OperationType.MULTIPLY, "*"],
  [OperationType.DIVIDE, "/"],
  [OperationType.PERCENT, "%"],
]);

export enum AggregatorStrategyType {
  SUM = "SUM",
  AVG = "AVG",
  WEIGHTED_AVERAGE = "WEIGHTED_AVERAGE",
}

export enum FormatterType {
  CONDITIONAL_ROUND_MORE_THAN_100_NUMBER_FORMATTER = "CONDITIONAL_ROUND_MORE_THAN_100_NUMBER_FORMATTER",
  ZERO_DIGITS_NUMBER_FORMATTER = "ZERO_DIGITS_NUMBER_FORMATTER",
  TWO_DIGITS_NUMBER_FORMATTER = "TWO_DIGITS_NUMBER_FORMATTER",
  FOUR_DIGITS_NUMBER_FORMATTER = "FOUR_DIGITS_NUMBER_FORMATTER",
}

export const FRACTION_DIGITS_BY_FORMATTING_TYPE = new Map<
  FormatterType,
  number
>([
  [FormatterType.ZERO_DIGITS_NUMBER_FORMATTER, 0],
  [FormatterType.TWO_DIGITS_NUMBER_FORMATTER, 2],
  [FormatterType.FOUR_DIGITS_NUMBER_FORMATTER, 4],
]);

export class PredefinedMetricModel {
  constructor(
    public type = MetricType.PREDEFINED,
    public id?: ReportMetricType,
    public days: Array<number> = []
  ) {}

  static of(model: PredefinedMetricModel): PredefinedMetricModel {
    return new PredefinedMetricModel(
      model.type,
      model.id,
      model.days?.length ? model.days.map((item) => Number(item)) : []
    );
  }

  static getDaysItemsByMetricType(id?: ReportMetricType): Array<number> {
    return id
      ? {
          INSTALLS_COUNT_ADJUST: () => [],
          INSTALLS_COUNT_NETWORK: () => [],
          SPEND: () => [],
          CPI_ADJUST: () => [],
          CPI_NETWORK: () => [],
          CPS: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          SUBSCRIPTION_COUNT: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          SUBSCRIPTION_USERS_COUNT: () => [
            1, 3, 7, 14, 30, 60, 120, 150, 180, 365,
          ],
          IN_APP_PURCHASE_COUNT: () => [
            1, 3, 7, 14, 30, 60, 120, 150, 180, 365,
          ],
          IN_APP_PAYING_USERS_COUNT: () => [
            1, 3, 7, 14, 30, 60, 120, 150, 180, 365,
          ],
          AVERAGE_SUBSCRIPTION: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          AVERAGE_PURCHASE: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          CONVERSION_TO_SUBSCRIPTION_USER: () => [
            1, 3, 7, 14, 30, 60, 120, 150, 180, 365,
          ],
          CONVERSION_TO_IN_APP_PAYING_USER: () => [
            1, 3, 7, 14, 30, 60, 120, 150, 180, 365,
          ],
          ROAS_SUBSCRIPTION: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          ROAS_IN_APP: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          ROAS_SUBSCRIPTION_IN_APP: () => [
            1, 3, 7, 14, 30, 60, 120, 150, 180, 365,
          ],
          REVENUE_SUBSCRIPTION: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          REVENUE_IN_APP: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          REVENUE_SUBSCRIPTION_IN_APP: () => [
            1, 3, 7, 14, 30, 60, 120, 150, 180, 365,
          ],
          ARPPU: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          CAC: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          RETURN_RATE: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          ARPDAU_DAILY: () => [],
          ARPDAU_COHORT: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          PLAY_TIME_CUMULATIVE: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          TIME_SPENT_CUMULATIVE: () => [
            1, 3, 7, 14, 30, 60, 120, 150, 180, 365,
          ],
          PLAY_TIME: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          TIME_SPENT: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          REVENUE_COHORT: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          REVENUE_DAILY: () => [],
          REVENUE_AD: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          ROAS: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          ROAS_AD: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          ARPU: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          ARPU_CUMULATIVE: () => [1, 3, 7, 14, 30, 60, 120, 150, 180, 365],
          CONVERSION_TO_SUBSCRIPTION_IN_APP_PAYING_USER: () => [
            1, 3, 7, 14, 30, 60, 120, 150, 180, 365,
          ],
          DAILY_ACTIVE_USERS_COUNT: () => [],
          LIFE_TIME_VALUE: () => [],
          LIFE_TIME_VALUE_PER_USER: () => [],
          CPM: () => [],
          CR: () => [],
          IR: () => [],
          CTR: () => [],
          IMPRESSIONS: () => [],
          CLICKS: () => [],
        }[id]()
      : [];
  }
}

export class UserMetricModel {
  constructor(
    public type = MetricType.USER,
    public definition = new UserMetricDefinitionModel(),
    public days: Array<number> = []
  ) {}

  get id(): number {
    return this.definition.id;
  }

  static of(model: UserMetricModel): UserMetricModel {
    return new UserMetricModel(
      model.type,
      UserMetricDefinitionModel.of(model.definition),
      model.days
    );
  }
}

export class UserMetricDefinitionModel {
  constructor(
    public name = "",
    public id = Math.floor(Math.random() * 100000),
    public basicMetrics: Array<BasicMetricModel> = [new BasicMetricModel()],
    public calculationSettings = new UserMetricCalculationModel(),
    public resultSettings = new UserMetricResultModel()
  ) {}

  static of(model: UserMetricDefinitionModel): UserMetricDefinitionModel {
    return new UserMetricDefinitionModel(
      model.name,
      model.id,
      BasicMetricModel.ofArray(model.basicMetrics),
      UserMetricCalculationModel.of(model.calculationSettings),
      UserMetricResultModel.of(model.resultSettings)
    );
  }
}

export class UserMetricCalculationModel {
  constructor(public mode = CalculationModeType.FULL) {}

  static of(model: UserMetricCalculationModel): UserMetricCalculationModel {
    return new UserMetricCalculationModel(model.mode);
  }
}

export class UserMetricResultModel {
  constructor(
    public formatter = FormatterType.ZERO_DIGITS_NUMBER_FORMATTER,
    public aggregatorStrategy = AggregatorStrategyType.AVG,
    public operands: Array<BasicMetricType> = [],
    public operation: OperationType = OperationType.WITHOUT_PROCESSING
  ) {}

  static of(model: UserMetricResultModel): UserMetricResultModel {
    return new UserMetricResultModel(
      model.formatter,
      model.aggregatorStrategy,
      model.operands,
      model.operation
    );
  }
}

export class BasicMetricModel {
  constructor(
    public filter = new BasicMetricFilterModel(),
    public isDone = false,
    public cohort = false,
    public cumulative = false
  ) {}

  get generalFilters(): Array<FilterPreviewId> {
    return [
      ...(![
        BasicMetricType.INSTALLS_COUNT_ADJUST,
        BasicMetricType.INSTALLS_COUNT_NETWORK,
        BasicMetricType.SPEND,
      ].includes(this.filter.type as BasicMetricType)
        ? [FilterPreviewId.COHORT]
        : []),
      ...(this.cohort &&
      ![
        BasicMetricType.DAILY_ACTIVE_USERS_COUNT,
        BasicMetricType.INSTALLS_COUNT_ADJUST,
        BasicMetricType.INSTALLS_COUNT_NETWORK,
        BasicMetricType.SPEND,
      ].includes(this.filter.type as BasicMetricType)
        ? [FilterPreviewId.CUMULATIVE]
        : []),
    ];
  }

  static of(model: BasicMetricModel): BasicMetricModel {
    return new BasicMetricModel(
      MetricFilterProvider.cloneMetricFilter(model.filter),
      true,
      model.cohort,
      model.cumulative
    );
  }

  static ofArray(items: Array<BasicMetricModel>): Array<BasicMetricModel> {
    return items.map((item) => BasicMetricModel.of(item));
  }
}

export class BasicMetricFilterModel {
  constructor(public type: BasicMetricType | string = "") {}

  get supportedFilters(): Array<FilterPreviewId> {
    return [];
  }

  static of(model: BasicMetricFilterModel): BasicMetricFilterModel {
    return new BasicMetricFilterModel(model.type);
  }
}

export class BasicMetricFilterProvider {
  static createFilter(metricType: BasicMetricType): any {
    return {
      REVENUE: () => new RevenueMetricFilterModel(),
      REVENUE_EVENTS_COUNT: () => new RevenueEventsCountMetricFilterModel(),
      REVENUE_ACTIVE_USERS_COUNT: () =>
        new RevenueActiveUsersCountMetricFilterModel(),
      TIME_SPENT: () => new TimeSpentMetricFilterModel(),
      INSTALLS_COUNT_ADJUST: () => new InstallsCountAdjustMetricFilterModel(),
      INSTALLS_COUNT_NETWORK: () => new InstallsCountNetworkMetricFilterModel(),
      SPEND: () => new SpendMetricFilterModel(),
      DAILY_ACTIVE_USERS_COUNT: () =>
        new DailyActiveUsersCountMetricFilterModel(),
    }[metricType]();
  }
}

export class MetricFilterProvider {
  static cloneMetricFilter(metricFilter: BasicMetricFilterModel) {
    return {
      REVENUE: () =>
        RevenueMetricFilterModel.of(metricFilter as RevenueMetricFilterModel),
      REVENUE_EVENTS_COUNT: () =>
        RevenueEventsCountMetricFilterModel.of(
          metricFilter as RevenueEventsCountMetricFilterModel
        ),
      REVENUE_ACTIVE_USERS_COUNT: () =>
        RevenueActiveUsersCountMetricFilterModel.of(
          metricFilter as RevenueActiveUsersCountMetricFilterModel
        ),
      TIME_SPENT: () =>
        TimeSpentMetricFilterModel.of(
          metricFilter as TimeSpentMetricFilterModel
        ),
      INSTALLS_COUNT_ADJUST: () =>
        InstallsCountAdjustMetricFilterModel.of(
          metricFilter as InstallsCountAdjustMetricFilterModel
        ),
      INSTALLS_COUNT_NETWORK: () =>
        InstallsCountNetworkMetricFilterModel.of(
          metricFilter as InstallsCountNetworkMetricFilterModel
        ),
      SPEND: () =>
        SpendMetricFilterModel.of(metricFilter as SpendMetricFilterModel),
      DAILY_ACTIVE_USERS_COUNT: () =>
        DailyActiveUsersCountMetricFilterModel.of(
          metricFilter as DailyActiveUsersCountMetricFilterModel
        ),
    }[metricFilter.type as BasicMetricType]();
  }
}

export class RevenueMetricFilterModel extends BasicMetricFilterModel {
  constructor(
    public revenueTypes: Array<string> = [],
    public eventTypes: Array<string> = [DefaultValues.EVENT],
    public revenueMethod: AdRevenueMethod = AdRevenueMethod.PRICED,
    public bannerProvider: ProvidedBannerType = ProvidedBannerType.ADJUST
  ) {
    super(BasicMetricType.REVENUE);
  }

  get isIapsOnly(): boolean {
    return (
      !!this.revenueTypes.length &&
      this.revenueTypes.every((item) => ["IAPS", "SUBSCRIPTION"].includes(item))
    );
  }

  get isAdEventsOnly(): boolean {
    return (
      !!this.revenueTypes.length &&
      this.revenueTypes.every((item) =>
        ["BANNER", "INTERSTITIAL", "REWARDED"].includes(item)
      )
    );
  }

  get supportedFilters(): Array<FilterPreviewId> {
    return [
      FilterPreviewId.AD_REVENUE_METHOD,
      FilterPreviewId.REVENUE,
      ...(this.isIapsOnly ? [] : [FilterPreviewId.EVENT]),
      FilterPreviewId.PROVIDED_BANNER,
    ];
  }

  static of(model: RevenueMetricFilterModel): RevenueMetricFilterModel {
    return new RevenueMetricFilterModel(
      model.revenueTypes,
      model.eventTypes,
      model.revenueMethod,
      model.bannerProvider
    );
  }
}

export class RevenueEventsCountMetricFilterModel extends BasicMetricFilterModel {
  constructor(
    public revenueTypes: Array<string> = [],
    public eventTypes: Array<string> = [DefaultValues.EVENT],
    public revenueMethod: AdRevenueMethod = AdRevenueMethod.PRICED,
    public bannerProvider: ProvidedBannerType = ProvidedBannerType.ADJUST
  ) {
    super(BasicMetricType.REVENUE_EVENTS_COUNT);
  }

  get isIapsOnly(): boolean {
    return (
      !!this.revenueTypes.length &&
      this.revenueTypes.every((item) => ["IAPS", "SUBSCRIPTION"].includes(item))
    );
  }

  get isAdEventsOnly(): boolean {
    return (
      !!this.revenueTypes.length &&
      this.revenueTypes.every((item) =>
        ["BANNER", "INTERSTITIAL", "REWARDED"].includes(item)
      )
    );
  }

  get supportedFilters(): Array<FilterPreviewId> {
    return [FilterPreviewId.REVENUE, FilterPreviewId.PROVIDED_BANNER];
  }

  static of(
    model: RevenueEventsCountMetricFilterModel
  ): RevenueEventsCountMetricFilterModel {
    return new RevenueEventsCountMetricFilterModel(
      model.revenueTypes,
      model.eventTypes,
      model.revenueMethod,
      model.bannerProvider
    );
  }
}

export class RevenueActiveUsersCountMetricFilterModel extends BasicMetricFilterModel {
  constructor(
    public revenueTypes: Array<string> = [],
    public eventTypes: Array<string> = [DefaultValues.EVENT],
    public revenueMethod: AdRevenueMethod = AdRevenueMethod.PRICED,
    public bannerProvider: ProvidedBannerType = ProvidedBannerType.ADJUST
  ) {
    super(BasicMetricType.REVENUE_ACTIVE_USERS_COUNT);
  }

  get isIapsOnly(): boolean {
    return (
      !!this.revenueTypes.length &&
      this.revenueTypes.every((item) => ["IAPS", "SUBSCRIPTION"].includes(item))
    );
  }

  get isAdEventsOnly(): boolean {
    return (
      !!this.revenueTypes.length &&
      this.revenueTypes.every((item) =>
        ["BANNER", "INTERSTITIAL", "REWARDED"].includes(item)
      )
    );
  }

  get supportedFilters(): Array<FilterPreviewId> {
    return [FilterPreviewId.REVENUE, FilterPreviewId.PROVIDED_BANNER];
  }

  static of(
    model: RevenueActiveUsersCountMetricFilterModel
  ): RevenueActiveUsersCountMetricFilterModel {
    return new RevenueActiveUsersCountMetricFilterModel(
      model.revenueTypes,
      model.eventTypes,
      model.revenueMethod,
      model.bannerProvider
    );
  }
}

export class TimeSpentMetricFilterModel extends BasicMetricFilterModel {
  constructor(
    public timeSpentType: TimeSpentReportType = TimeSpentReportType.PLAY_TIME
  ) {
    super(BasicMetricType.TIME_SPENT);
  }

  get supportedFilters(): Array<FilterPreviewId> {
    return [FilterPreviewId.TIME_SPENT_TYPE];
  }

  static of(model: TimeSpentMetricFilterModel): TimeSpentMetricFilterModel {
    return new TimeSpentMetricFilterModel(model.timeSpentType);
  }
}

export class InstallsCountAdjustMetricFilterModel extends BasicMetricFilterModel {
  constructor() {
    super(BasicMetricType.INSTALLS_COUNT_ADJUST);
  }

  get generalFilters(): Array<FilterPreviewId> {
    return [];
  }
}

export class InstallsCountNetworkMetricFilterModel extends BasicMetricFilterModel {
  constructor() {
    super(BasicMetricType.INSTALLS_COUNT_NETWORK);
  }

  get generalFilters(): Array<FilterPreviewId> {
    return [];
  }
}

export class DailyActiveUsersCountMetricFilterModel extends BasicMetricFilterModel {
  constructor() {
    super(BasicMetricType.DAILY_ACTIVE_USERS_COUNT);
  }

  get generalFilters(): Array<FilterPreviewId> {
    return [FilterPreviewId.COHORT];
  }
}

export class SpendMetricFilterModel extends BasicMetricFilterModel {
  constructor() {
    super(BasicMetricType.SPEND);
  }

  get generalFilters(): Array<FilterPreviewId> {
    return [];
  }
}

const HEADER_DATA = new Map<ReportMetricType, string>([
  [ReportMetricType.INSTALLS_COUNT_ADJUST, "installsCountAdjust"],
  [ReportMetricType.INSTALLS_COUNT_NETWORK, "installsCountNetwork"],
  [ReportMetricType.SPEND, "spend"],
  [ReportMetricType.CPI_ADJUST, "cpiAdjust"],
  [ReportMetricType.CPI_NETWORK, "cpiNetwork"],
  [ReportMetricType.CPS, "cps"],
  [ReportMetricType.SUBSCRIPTION_COUNT, "subscriptionCount"],
  [ReportMetricType.SUBSCRIPTION_USERS_COUNT, "subscriptionUsersCount"],
  [ReportMetricType.IN_APP_PURCHASE_COUNT, "inAppPurchaseCount"],
  [ReportMetricType.IN_APP_PAYING_USERS_COUNT, "inAppPayingUsersCount"],
  [ReportMetricType.AVERAGE_SUBSCRIPTION, "averageSubscription"],
  [ReportMetricType.AVERAGE_PURCHASE, "averagePurchase"],
  [
    ReportMetricType.CONVERSION_TO_SUBSCRIPTION_USER,
    "conversionToSubscriptionUser",
  ],
  [
    ReportMetricType.CONVERSION_TO_IN_APP_PAYING_USER,
    "conversionToInAppPayingUser",
  ],
  [ReportMetricType.ROAS_SUBSCRIPTION, "roasSubscription"],
  [ReportMetricType.ROAS_IN_APP, "roasInApp"],
  [ReportMetricType.ROAS_SUBSCRIPTION_IN_APP, "roasSubscriptionInApp"],
  [ReportMetricType.REVENUE_SUBSCRIPTION, "revenueSubscription"],
  [ReportMetricType.REVENUE_IN_APP, "revenueInApp"],
  [ReportMetricType.REVENUE_SUBSCRIPTION_IN_APP, "revenueSubscriptionInApp"],
  [ReportMetricType.ARPPU, "arppu"],
  [ReportMetricType.CAC, "cac"],
  [ReportMetricType.RETURN_RATE, "returnRate"],
  [ReportMetricType.ARPDAU_DAILY, "arpdauDaily"],
  [ReportMetricType.ARPDAU_COHORT, "arpdauCohort"],
  [ReportMetricType.PLAY_TIME_CUMULATIVE, "playTimeCumulative"],
  [ReportMetricType.TIME_SPENT_CUMULATIVE, "timeSpentCumulative"],
  [ReportMetricType.PLAY_TIME, "playTime"],
  [ReportMetricType.TIME_SPENT, "timeSpent"],
  [ReportMetricType.REVENUE_COHORT, "revenueCohort"],
  [ReportMetricType.REVENUE_DAILY, "revenueDaily"],
  [ReportMetricType.REVENUE_AD, "revenueAd"],
  [ReportMetricType.ROAS, "roas"],
  [ReportMetricType.ROAS_AD, "roasAd"],
  [ReportMetricType.ARPU, "arpu"],
  [ReportMetricType.ARPU_CUMULATIVE, "arpuCumulative"],
  [
    ReportMetricType.CONVERSION_TO_SUBSCRIPTION_IN_APP_PAYING_USER,
    "conversionToSubscriptionInAppPayingUser",
  ],
  [ReportMetricType.DAILY_ACTIVE_USERS_COUNT, "dailyActiveUsersCount"],
  [ReportMetricType.LIFE_TIME_VALUE, "ltv"],
  [ReportMetricType.LIFE_TIME_VALUE_PER_USER, "ltvPerUser"],
  [ReportMetricType.CPM, "cpm"],
  [ReportMetricType.CR, "cr"],
  [ReportMetricType.IR, "ir"],
  [ReportMetricType.CTR, "ctr"],
  [ReportMetricType.IMPRESSIONS, "impressions"],
  [ReportMetricType.CLICKS, "clicks"],
]);

const getColumnInfoByReportMetricType = (
  type: ReportMetricType
): Record<string, any> => {
  return (
    {
      INSTALLS_COUNT_ADJUST: { hasGradient: false, fractionDigits: 0 },
      INSTALLS_COUNT_NETWORK: { hasGradient: false, fractionDigits: 0 },
      SPEND: { hasGradient: false, fractionDigits: 0 },
      CPI_ADJUST: { reverseGradient: true, fractionDigits: 2 },
      CPI_NETWORK: { reverseGradient: true, fractionDigits: 2 },
      CPS: { fractionDigits: 2 },
      SUBSCRIPTION_COUNT: { fractionDigits: 0 },
      SUBSCRIPTION_USERS_COUNT: { fractionDigits: 0 },
      IN_APP_PURCHASE_COUNT: { fractionDigits: 0 },
      IN_APP_PAYING_USERS_COUNT: { fractionDigits: 0 },
      AVERAGE_SUBSCRIPTION: { fractionDigits: 2 },
      AVERAGE_PURCHASE: { fractionDigits: 2 },
      CONVERSION_TO_SUBSCRIPTION_IN_APP_PAYING_USER: {
        postfix: "%",
        fractionDigits: 2,
      },
      CONVERSION_TO_SUBSCRIPTION_USER: { postfix: "%", fractionDigits: 2 },
      CONVERSION_TO_IN_APP_PAYING_USER: { postfix: "%", fractionDigits: 2 },
      ROAS: { postfix: "%", fractionDigits: 2 },
      ROAS_AD: { postfix: "%", fractionDigits: 2 },
      ROAS_SUBSCRIPTION: { postfix: "%", fractionDigits: 2 },
      ROAS_IN_APP: { postfix: "%", fractionDigits: 2 },
      ROAS_SUBSCRIPTION_IN_APP: { postfix: "%", fractionDigits: 2 },
      REVENUE_COHORT: {},
      REVENUE_DAILY: {},
      REVENUE_AD: {},
      REVENUE_SUBSCRIPTION: {},
      REVENUE_IN_APP: {},
      REVENUE_SUBSCRIPTION_IN_APP: {},
      ARPPU: { fractionDigits: 4 },
      CAC: { fractionDigits: 2 },
      RETURN_RATE: { fractionDigits: 2 },
      ARPDAU_DAILY: { fractionDigits: 4 },
      ARPDAU_COHORT: { fractionDigits: 4 },
      PLAY_TIME_CUMULATIVE: { fractionDigits: 2 },
      TIME_SPENT_CUMULATIVE: { fractionDigits: 2 },
      PLAY_TIME: { fractionDigits: 2 },
      TIME_SPENT: { fractionDigits: 2 },
      ARPU: { fractionDigits: 5 },
      ARPU_CUMULATIVE: { fractionDigits: 5 },
      DAILY_ACTIVE_USERS_COUNT: { fractionDigits: 0 },
      LIFE_TIME_VALUE: {},
      LIFE_TIME_VALUE_PER_USER: { fractionDigits: 5 },
      CPM: { fractionDigits: 2 },
      CR: { postfix: "%", fractionDigits: 2 },
      IR: { postfix: "%", fractionDigits: 2 },
      CTR: { postfix: "%", fractionDigits: 2 },
      IMPRESSIONS: { fractionDigits: 0 },
      CLICKS: { fractionDigits: 0 },
    } as Record<ReportMetricType, any>
  )[type as ReportMetricType];
};

export class MetricsConstructorGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      gradient: true,
    };
  }

  getByKey(): boolean {
    return super.getByKey("gradient");
  }
}

export class MetricsConstructorFilter
  extends BaseReportFilter
  implements MetricsConstructorReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    public metrics: Array<UserMetricModel | PredefinedMetricModel> = [
      new PredefinedMetricModel(
        MetricType.PREDEFINED,
        ReportMetricType.INSTALLS_COUNT_ADJUST
      ),
      new PredefinedMetricModel(MetricType.PREDEFINED, ReportMetricType.SPEND),
      new PredefinedMetricModel(
        MetricType.PREDEFINED,
        ReportMetricType.CPI_ADJUST
      ),
    ]
  ) {
    super(ReportType.METRICS_CONSTRUCTOR, app, filter, date, groupByFilter);
  }

  get invalid(): boolean {
    return (
      !(this.app && this.date.valid) ||
      !(this.metrics.length && this.metrics?.every(({ type }) => type))
    );
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.NET_REVENUE,
        value: true,
      },
    ];
  }

  get hasInstallsMetric(): boolean {
    return this.metrics.some(
      ({ id }: PredefinedMetricModel | UserMetricModel) =>
        id === ReportMetricType.INSTALLS_COUNT_ADJUST
    );
  }

  static of(
    app: Application,
    filter?: Record<string, any>
  ): MetricsConstructorFilter {
    return filter
      ? new MetricsConstructorFilter(
          app,
          filter.filter?.length
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.metrics?.length
            ? filter.metrics.map(
                (metric: PredefinedMetricModel | UserMetricModel) => {
                  if (metric.type === MetricType.PREDEFINED) {
                    return PredefinedMetricModel.of(
                      metric as PredefinedMetricModel
                    );
                  }

                  return UserMetricModel.of(metric as UserMetricModel);
                }
              )
            : []
        )
      : new MetricsConstructorFilter(app);
  }

  toRequestQuery(): Record<string, any> {
    const result = super.toRequestQuery();

    result["metrics"] = this.metrics;

    return result;
  }
}
export class MetricsConstructorHeaders {
  static init(
    lang: LangService,
    report: MetricsConstructorFilter,
    reportDataType: ReportDataType,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    const metrics: Array<PredefinedMetricModel | UserMetricModel> = cloneDeep(
      report.metrics
    );
    const metricsWithOneDayOrWithout = metrics.reduce(
      (
        res: Array<PredefinedMetricModel | UserMetricModel>,
        metric: PredefinedMetricModel | UserMetricModel
      ) => {
        if (metric.days.length <= 1) {
          return res.concat([metric]);
        }

        return res;
      },
      []
    );
    const metricsWithMoreThanOneDay = metrics.reduce(
      (
        res: Array<PredefinedMetricModel | UserMetricModel>,
        metric: PredefinedMetricModel | UserMetricModel
      ) => {
        if (metric.days.length > 1) {
          return res.concat([metric]);
        }

        return res;
      },
      []
    );

    return [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy &&
      reportDataType !== ReportDataType.TOTAL
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      ...(metrics?.length
        ? [
            ...metricsWithOneDayOrWithout.reduce(
              (
                res: Array<ReportHeader>,
                metric: PredefinedMetricModel | UserMetricModel
              ) => {
                if (metric.type === MetricType.USER) {
                  res.push(
                    Object.assign({
                      text: (metric as UserMetricModel).definition.name,
                      align: "end",
                      width: 90,
                      value: `${ReportResultItem.PREFIX}_${
                        metric.id as number
                      }`,
                      hasGradient: true,
                      fractionDigits: FRACTION_DIGITS_BY_FORMATTING_TYPE.get(
                        (metric as UserMetricModel).definition.resultSettings
                          .formatter
                      ),
                      postfix:
                        (metric as UserMetricModel).definition.resultSettings
                          .operation === OperationType.PERCENT
                          ? "%"
                          : undefined,
                    }) as ReportHeader
                  );
                } else {
                  if (
                    [ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
                      reportDataType
                    ) &&
                    [ReportMetricType.DAILY_ACTIVE_USERS_COUNT].includes(
                      metric.id as ReportMetricType
                    )
                  ) {
                    return res;
                  }

                  res.push(
                    Object.assign(
                      {
                        text: lang(
                          `views.metrics.tableHeader.${(
                            metric.id as ReportMetricType
                          )?.toLowerCase()}`
                        ),
                        align: "end",
                        width: 90,
                        value: `${ReportResultItem.PREFIX}${HEADER_DATA.get(
                          metric.id as ReportMetricType
                        )}`,
                        hasGradient: true,
                      },
                      getColumnInfoByReportMetricType(
                        metric.id as ReportMetricType
                      )
                    ) as ReportHeader
                  );
                }

                return res;
              },
              []
            ),
            ...this.generateMetricsHeaders(
              metricsWithMoreThanOneDay,
              lang,
              groupBy
            ),
          ]
        : []),
    ];
  }

  static generateMetricsHeaders(
    metricsWithMoreThanOneDay: Array<PredefinedMetricModel | UserMetricModel>,
    lang: LangService,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    const result: Array<ReportHeader> = [];
    const uniqueDays = uniq(
      metricsWithMoreThanOneDay.map(({ days }) => days).flat()
    ).sort((dayA, dayB) => dayA - dayB);
    const groupedByDay = uniqueDays.map((day) => {
      return [
        day,
        metricsWithMoreThanOneDay.filter(({ days }) => days.includes(day)),
      ];
    });

    if (groupBy === GroupByType.METRICS) {
      metricsWithMoreThanOneDay.forEach((metric) => {
        const sortedDays = [...metric.days].sort((dayA, dayB) => dayA - dayB);

        result.push(
          ...sortedDays.map((day) => {
            if (metric.type === MetricType.USER) {
              return {
                text:
                  (metric as UserMetricModel).definition.name + `, day ${day}`,
                align: "end",
                width: 90,
                value: `${ReportResultItem.PREFIX}_${
                  metric.id as number
                }_${day}`,
                hasGradient: true,
                fractionDigits: FRACTION_DIGITS_BY_FORMATTING_TYPE.get(
                  (metric as UserMetricModel).definition.resultSettings
                    .formatter
                ),
                postfix:
                  (metric as UserMetricModel).definition.resultSettings
                    .operation === OperationType.PERCENT
                    ? "%"
                    : undefined,
                groupBy: {
                  border: {
                    left: day === sortedDays[0],
                    right:
                      metric.id ===
                        metricsWithMoreThanOneDay[
                          metricsWithMoreThanOneDay.length - 1
                        ].id && day === sortedDays[sortedDays.length - 1],
                  },
                },
                hasBackground: metricsWithMoreThanOneDay.some(
                  (item, index) => index % 2 === 0 && item.id === metric.id
                ),
              } as ReportHeader;
            }

            return {
              text: lang(
                `views.metrics.tableHeader.${(
                  metric.id as ReportMetricType
                )?.toLowerCase()}DayN`,
                day
              ),
              align: "end",
              width: 90,
              value: `${ReportResultItem.PREFIX}${HEADER_DATA.get(
                metric.id as ReportMetricType
              )}_${day}`,
              hasGradient: true,
              ...getColumnInfoByReportMetricType(metric.id as ReportMetricType),
              groupBy: {
                border: {
                  left: day === sortedDays[0],
                  right:
                    metric.id ===
                      metricsWithMoreThanOneDay[
                        metricsWithMoreThanOneDay.length - 1
                      ].id && day === sortedDays[sortedDays.length - 1],
                },
                hasBackground: metricsWithMoreThanOneDay.some(
                  (item, index) => index % 2 === 0 && item.id === metric.id
                ),
              },
            } as ReportHeader;
          })
        );
      });
    } else {
      groupedByDay.forEach(([day, metrics]: any) => {
        metrics.forEach((metric: PredefinedMetricModel | UserMetricModel) => {
          if (metric.type === MetricType.USER) {
            result.push({
              text:
                (metric as UserMetricModel).definition.name + `, day ${day}`,
              align: "end",
              width: 90,
              value: `${ReportResultItem.PREFIX}_${metric.id as number}_${day}`,
              hasGradient: true,
              fractionDigits: FRACTION_DIGITS_BY_FORMATTING_TYPE.get(
                (metric as UserMetricModel).definition.resultSettings.formatter
              ),
              postfix:
                (metric as UserMetricModel).definition.resultSettings
                  .operation === OperationType.PERCENT
                  ? "%"
                  : undefined,
              groupBy: {
                border: {
                  left: (metric.id as number) === metrics[0].id,
                  right:
                    (metric.id as number) === metrics[metrics.length - 1].id &&
                    day === uniqueDays[uniqueDays.length - 1],
                },
                hasBackground: uniqueDays
                  .filter((_, index) => index % 2 === 0)
                  .includes(day),
              },
            } as ReportHeader);
          } else {
            result.push({
              text: lang(
                `views.metrics.tableHeader.${(
                  metric.id as ReportMetricType
                )?.toLowerCase()}DayN`,
                day
              ),
              align: "end",
              width: 90,
              value: `${ReportResultItem.PREFIX}${HEADER_DATA.get(
                metric.id as ReportMetricType
              )}_${day}`,
              hasGradient: true,
              ...getColumnInfoByReportMetricType(metric.id as ReportMetricType),
              groupBy: {
                border: {
                  left: (metric.id as ReportMetricType) === metrics[0].id,
                  right:
                    (metric.id as ReportMetricType) ===
                      metrics[metrics.length - 1].id &&
                    day === uniqueDays[uniqueDays.length - 1],
                },
                hasBackground: uniqueDays
                  .filter((_, index) => index % 2 === 0)
                  .includes(day),
              },
            } as ReportHeader);
          }
        });
      });
    }

    return result;
  }
}
export class MetricsConstructorResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();

    const { metrics, groupByFilter } = filter as MetricsConstructorFilter;

    if (row.getByHeader("installDate")) {
      this.date = row.getByHeader("installDate");
      this.setFormattedDate(
        this.date,
        filter.date.from,
        filter.date.to,
        groupByFilter.aggregationPeriod
      );
    }

    this.setGroupByValue(groupByFilter, row);

    const metricsReduced = metrics?.reduce(
      (res: Record<string, any>, metric: any) => {
        if (metric.days && metric.days.length > 1) {
          for (let i = 0; i < metric.days.length; i++) {
            if (metric.type === MetricType.USER) {
              const metricId = `_${metric.id}`;

              Object.assign(res, {
                [`${metricId}_${metric.days[i]}`]:
                  (metric as UserMetricModel).definition.resultSettings
                    .formatter ===
                  FormatterType.CONDITIONAL_ROUND_MORE_THAN_100_NUMBER_FORMATTER
                    ? this.parseValueForRevenue(
                        row.getByHeader(`${metricId}_${metric.days[i]}`)
                      )
                    : this.parseFloat(
                        row.getByHeader(`${metricId}_${metric.days[i]}`),
                        FRACTION_DIGITS_BY_FORMATTING_TYPE.get(
                          (metric as UserMetricModel).definition.resultSettings
                            .formatter
                        )
                      ),
              });
            } else {
              Object.assign(res, {
                [`${HEADER_DATA.get(metric.id as ReportMetricType)}_${
                  metric.days[i]
                }`]: [
                  ReportMetricType.REVENUE_COHORT,
                  ReportMetricType.REVENUE_AD,
                  ReportMetricType.REVENUE_SUBSCRIPTION,
                  ReportMetricType.REVENUE_IN_APP,
                  ReportMetricType.REVENUE_SUBSCRIPTION_IN_APP,
                  ReportMetricType.LIFE_TIME_VALUE,
                ].includes(metric.id as ReportMetricType)
                  ? this.parseValueForRevenue(
                      row.getByHeader(
                        `${HEADER_DATA.get(metric.id as ReportMetricType)}_${
                          metric.days[i]
                        }`
                      )
                    )
                  : this.parseFloat(
                      row.getByHeader(
                        `${HEADER_DATA.get(metric.id as ReportMetricType)}_${
                          metric.days[i]
                        }`
                      ),
                      getColumnInfoByReportMetricType(
                        metric.id as ReportMetricType
                      ).fractionDigits
                    ),
              });
            }
          }

          return res;
        }

        if (metric.type === MetricType.USER) {
          const metricId = `_${metric.id}`;

          return Object.assign(res, {
            [metricId]:
              (metric as UserMetricModel).definition.resultSettings
                .formatter ===
              FormatterType.CONDITIONAL_ROUND_MORE_THAN_100_NUMBER_FORMATTER
                ? this.parseValueForRevenue(row.getByHeader(metricId))
                : this.parseFloat(
                    row.getByHeader(metricId),
                    FRACTION_DIGITS_BY_FORMATTING_TYPE.get(
                      (metric as UserMetricModel).definition.resultSettings
                        .formatter
                    )
                  ),
          });
        }

        return Object.assign(res, {
          [`${HEADER_DATA.get(metric.id as ReportMetricType)}`]: [
            ReportMetricType.REVENUE_COHORT,
            ReportMetricType.REVENUE_AD,
            ReportMetricType.REVENUE_SUBSCRIPTION,
            ReportMetricType.REVENUE_IN_APP,
            ReportMetricType.REVENUE_SUBSCRIPTION_IN_APP,
            ReportMetricType.LIFE_TIME_VALUE,
          ].includes(metric.id as ReportMetricType)
            ? this.parseValueForRevenue(
                row.getByHeader(
                  `${HEADER_DATA.get(metric.id as ReportMetricType)}`
                )
              )
            : this.parseFloat(
                row.getByHeader(
                  `${HEADER_DATA.get(metric.id as ReportMetricType)}`
                ),
                getColumnInfoByReportMetricType(metric.id as ReportMetricType)
                  .fractionDigits
              ),
        });
      },
      {}
    );

    Object.assign(this.data, metricsReduced);
  }

  protected parseValueForRevenue(value: string) {
    return Number.parseFloat(value) >= 100
      ? super.parseFloat(value)
      : super.parseFloat(value, 2);
  }
}
