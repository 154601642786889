import { uniq } from "lodash";

import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { ReportHeader } from "./ReportHeader";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  FilterPreview,
  FilterPreviewId,
  FilterModel,
  DefaultValues,
  Application,
} from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";
import { DashboardChartType } from "@/dashboard/models/DashboardChartModel";
import { REPORT_PREDICT_BY_REPORT_TYPE } from "./ReportVisualization";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

export abstract class BaseGradientFlags {
  flags: Record<string, boolean> = {};
  unlock = false;

  getByKey(key: string | number): boolean {
    return this.flags[key];
  }

  static getFlagName(flagName: string): string {
    return flagName.replace(ReportResultItem.PREFIX, "");
  }
}

export interface ExcludeValueInterface {
  headerValue: string;
  value: string;
  parent?: { headerValue: string; value: string };
  rowIndex?: number;
}

export class ExcludeValue {
  constructor(public values: Array<ExcludeValueInterface> = []) {}

  add(value: ExcludeValueInterface) {
    this.values.push(value);
  }

  remove(value: ExcludeValueInterface) {
    this.values = this.values.filter(
      (item) =>
        item.headerValue !== value.headerValue ||
        item.value !== value.value ||
        item.rowIndex !== value.rowIndex
    );
  }

  removeAll() {
    this.values = [];
  }

  itemContained(value: ExcludeValueInterface): boolean {
    return this.values.some(
      (item) =>
        item.headerValue === value.headerValue &&
        item.value === value.value &&
        item.rowIndex === value.rowIndex
    );
  }

  rowContained(rowIndex: number): boolean {
    return this.values.some((item) => item.rowIndex === rowIndex);
  }

  parentItemContained(parentHeaderValueKey: string, parentValue: any): boolean {
    return this.values.some(
      (item) =>
        item.headerValue === parentHeaderValueKey && item.value === parentValue
    );
  }
}

export interface ReportChart {
  name: string;
  type: DashboardChartType;
  id?: number;
  expanded?: boolean;
  templateId?: number;
  templateName?: string;
  reportType?: ReportType;
}

export abstract class ReportFilter {
  constructor(
    public readonly reportId: ReportType,
    public filter: Array<FilterModel>,
    public date: DatesFilterModel,
    public groupByFilter: GroupByFilter,
    public isMultiApps: boolean
  ) {}

  abstract get getApp(): string;
  abstract get invalid(): boolean;

  get charts(): Array<ChartName> {
    return [];
  }

  get groupedCharts(): Array<ChartName> {
    return [];
  }

  get hasCharts(): boolean {
    return !!this.charts.length;
  }

  get hasGroupedCharts(): boolean {
    return !!this.groupedCharts.length;
  }

  get previews(): Array<FilterPreview> {
    return [
      ...(this.date
        ? Array.isArray(this.date.preview)
          ? this.date.preview
          : [this.date.preview]
        : []),
      ...this.groupByFilter.preview,
      ...this.filter.reduce(
        (result: Array<FilterPreview>, filter: FilterModel) => {
          if (Array.isArray(filter.preview)) {
            result.push(...filter.preview);
          } else if (filter.preview) {
            result.push(filter.preview);
          }

          return result;
        },
        []
      ),
    ];
  }

  get hasPredict(): boolean {
    return REPORT_PREDICT_BY_REPORT_TYPE[this.reportId];
  }

  getUsedDictionaryValues(): Record<string, Array<string>> {
    const usedDictionaryValues: Record<string, Array<string>> = {};

    this.filter.forEach((filter: FilterModel) => {
      if (filter.id === FilterId.TRACKER) {
        return;
      }

      filter.getUsedDictionaryValues().forEach((values, dictionary) => {
        usedDictionaryValues[dictionary] = uniq(
          (usedDictionaryValues[dictionary] || []).concat(values)
        );
      });
    });

    return usedDictionaryValues;
  }

  getUsedTrackerDictionaryValues(): Record<string, Array<string>> {
    const usedTrackerDictionaryValues: Record<string, Array<string>> = {};
    const trackerFilter = this.filter.find(
      ({ id }: FilterModel) => id === FilterId.TRACKER
    );

    if (trackerFilter) {
      trackerFilter.getUsedDictionaryValues().forEach((values, dictionary) => {
        usedTrackerDictionaryValues[dictionary] = uniq(
          (usedTrackerDictionaryValues[dictionary] || []).concat(values)
        );
      });
    }

    return usedTrackerDictionaryValues;
  }

  abstract canAutoGenerateReport(): boolean;
  abstract toRequestQuery(): Record<string, any>;
}

export interface GroupByWithEventParamFilter {
  statsEventFilterName?: string;
  eventParamKeyForGroupBy?: string;
  groupByFilter: GroupByFilter;

  hasGroupByEventParameter: boolean;
  removeGroupByEventParameter(): void;
}

export abstract class MultiAppsReportFilter extends ReportFilter {
  protected constructor(
    reportId: ReportType,
    public availableApps: Array<Application>,
    public platforms: Array<string> = [],
    public apps: Array<string> = [],
    public includedApps = true,
    filter: Array<FilterModel> = [],
    date?: DatesFilterModel | any,
    groupByFilter = new GroupByFilter(AggregationPeriod.DAY, [], false)
  ) {
    super(reportId, filter, date, groupByFilter, true);
  }

  get getApp(): string {
    if (!this.apps.length) {
      if (this.platforms.length) {
        return this.availableApps
          .filter(({ platformType }) => this.platforms.includes(platformType))
          .map(({ id }) => id)
          .join(",");
      }

      return this.availableApps.map(({ id }) => id).join(",");
    }

    if (this.includedApps) {
      return this.apps.join(",");
    } else {
      return this.availableApps
        .reduce(
          (result: Array<string>, { id }: Application) => [
            ...result,
            ...(this.apps.includes(id) ? [] : [id]),
          ],
          []
        )
        .join(",");
    }
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      ...(this.platforms.length
        ? [
            {
              id: FilterPreviewId.PLATFORMS,
              value: this.platforms,
            },
          ]
        : []),
      ...(this.apps.length
        ? [
            {
              id: FilterPreviewId.APPLICATIONS,
              value: this.apps,
              excluded: !this.includedApps,
            },
          ]
        : []),
    ];
  }

  get invalid(): boolean {
    if (this.date) {
      return !this.date.valid || this.availableApps.length === 0;
    }

    return this.availableApps.length === 0;
  }

  clearAndSetApp(apps?: Array<string>) {
    this.apps = apps || [];
    this.filter = [];
  }

  canAutoGenerateReport(): boolean {
    return this.availableApps.length !== 0;
  }

  toRequestQuery(): Record<string, any> {
    const result: Record<string, any> = {};
    if (this.platforms.length !== 0) {
      result["platforms"] = this.platforms;
    }

    if (this.apps.length !== 0) {
      result["apps"] = this.apps;
      result["includedApps"] = this.includedApps;
    }

    if (this.filter.length !== 0) {
      result["filter"] = this.filter.map((it) => it.toRecord());
    }

    if (this.date) {
      result["date"] = this.date.toRecord();
    }

    if (this.groupByFilter.isNotEmpty) {
      result["groupByFilter"] = this.groupByFilter;
    }

    return result;
  }
}

export abstract class BaseReportFilter extends ReportFilter {
  protected constructor(
    reportId: ReportType,
    public app: Application,
    filter: Array<FilterModel> = [],
    date: DatesFilterModel = DefaultValues.initDatesFilterModel(
      FilterId.EVENTS_DATE
    ),
    groupByFilter: GroupByFilter = new GroupByFilter(AggregationPeriod.DAY)
  ) {
    super(reportId, filter, date, groupByFilter, false);
  }

  clearAndSetApp(app: Application): void {
    this.app = app;
    this.filter = [];
  }

  abstract get invalid(): boolean;

  canAutoGenerateReport() {
    return !!this.app;
  }

  get getApp(): string {
    return this.app.id;
  }

  toRequestQuery(): Record<string, any> {
    const result: Record<string, any> = {
      date: this.date.toRecord(),
    };

    if (this.filter.length !== 0) {
      result["filter"] = this.filter.map((it) => it.toRecord());
    }

    if (this.groupByFilter.isNotEmpty) {
      result["groupByFilter"] = this.groupByFilter;
    }

    return result;
  }
}

export abstract class ReportResultItem {
  public static readonly PREFIX: string = "data.";

  date = "";
  formattedDate = "";
  data: Record<string, any> = {};
  dateTo = "";

  getByKey(key: string | number) {
    return this.data[
      typeof key === "string" ? key.replace(ReportResultItem.PREFIX, "") : key
    ];
  }

  protected setFormattedDate(
    date: string,
    from: string,
    to: string,
    aggregationPeriod?: AggregationPeriod
  ) {
    if (aggregationPeriod) {
      switch (aggregationPeriod) {
        case AggregationPeriod.DAY:
          this.formattedDate = DateUtil.formatDate(date);
          this.dateTo = date;
          break;
        case AggregationPeriod.WEEK:
          this.formattedDate = DateUtil.formatRate(date, [from, to], 7, "d");
          this.dateTo =
            to < DateUtil.addDays(date, 7) ? to : DateUtil.addDays(date, 7);
          break;
        case AggregationPeriod.MONTH:
          this.formattedDate = DateUtil.formatRate(date, [from, to], 1, "M");
          this.dateTo =
            to < DateUtil.getLastDayOfMonth(date)
              ? to
              : DateUtil.getLastDayOfMonth(date);
          break;
      }
    }
  }

  protected setGroupByValue(
    groupByFilter: GroupByFilter,
    row: ReportItemRowObject
  ) {
    const filter = GroupByFilter.of(groupByFilter);

    if (filter?.isNotEmptyGroupBy) {
      filter.groupBy.forEach((it) => {
        const key = it.toString().toLowerCase();
        if (row.getByHeader(key)) {
          this.data[key] = row.getByHeader(key);
        }
      });
    }
  }

  protected parseFloat(value?: string | null, precision = 0) {
    if (value === undefined || value === null) {
      return undefined;
    }

    const multiplier = Math.pow(10, precision);
    return Math.round(Number.parseFloat(value) * multiplier) / multiplier;
  }

  protected parseInt(value?: string | null) {
    if (value === undefined || value === null) {
      return undefined;
    }
    return Number.parseInt(value);
  }

  getChartFilter(headers: Array<ReportHeader>) {
    return headers.reduce((result, header) => {
      if (!header.isGrouped) {
        return result;
      }

      const prop = header.value.startsWith(ReportResultItem.PREFIX)
        ? header.value.substring(ReportResultItem.PREFIX.length)
        : header.value;

      if (prop === "date") {
        result.set(prop, this.formattedDate);
      } else {
        result.set(prop, this.data[prop]);
      }

      return result;
    }, new Map());
  }
}

export abstract class PredictedResultItem extends ReportResultItem {
  toRecord() {
    return {
      date: this.date,
      ...this.data,
    };
  }
}
