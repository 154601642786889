export default {
  accountingPortal: {
    banks: {
      title: "Banks",
      newBankTitle: "New Bank",
      bankDetailsTitle: "Bank details",
      name: "Name",
      code: "Code",
      actions: "Actions",
      swift: "Swift",
      ibans: "IBANS",
      iban: "IBAN",
      currency: "Currency",
      addIban: "Add IBAN",
      deleteDialog: {
        title: "Delete bank",
        body: "Do you really want to delete this bank?",
      },
      notification: {
        createdSuccess: `The Bank "{0}" was created successfully`,
        updatedSuccess: `The Bank "{0}" was updated successfully`,
        deletedSuccess: `The Bank was deleted successfully`,
      },
    },
    counterparties: {
      title: "Counterparties",
      newCounterpartyTitle: "New Counterparty",
      counterpartyDetailsTitle: "Counterparty Details",
      viewCounterpartyTitle: "Counterparty name: {0}",
      goToList: "Go to counterparties list",
      name: "Name",
      currencyCode: "Currency code",
      bank: "Bank",
      currentBank: "Current Bank",
      currentNet: "Current Net",
      networksCount: "Networks count",
      websiteUrl: "Website url",
      note: "Note",
      fillBanks: "Fill banks",
      hideNote: "Hide",
      showMoreNote: "Show more",
      showCurrentNetworks: "Show current networks",
      deleteDialog: {
        title: "Delete counterparty",
        body: "Do you really want to delete this counterparty?",
      },
      notification: {
        createdSuccess: `The Counterparty "{0}" was created successfully`,
        updatedSuccess: `The Counterparty "{0}" was updated successfully`,
        deletedSuccess: `The Counterparty was deleted successfully`,
      },
      banks: {
        title: "Banks",
        editByPeriods: "Edit banks by periods",
        subtitle: "Set up any banks",
        name: "Name",
        dateFrom: "Date from",
        dateTo: "Date to",
      },
      networks: {
        title: "Networks",
        editTitle: "Edit Network",
        newTitle: "New Network",
        subtitle: "Set up any networks",
        name: "Name",
        dateFrom: "Date from",
        dateTo: "Date to",
        notification: {
          date_period_overlapping:
            "This network cannot be linked to more than one counterparty at one time",
          default_error_code: "Validation error",
        },
      },
      netRevenue: {
        title: "Net Revenue",
        subtitle: "Set up net revenue",
        newTitle: "New Net Revenue",
        editTitle: "Edit Net Revenue",
        dateFrom: "Date from",
        dateTo: "Date to",
        net: "Net",
        editNetByPeriods: "Edit net by periods",
        dateOneDayGreater:
          "The date must be 1 day greater than the date of the previous period",
        minDateTo: "Must be greater than {0}",
      },
      thresholds: {
        title: "Thresholds",
        subtitle: "Set up any thresholds",
        editTitle: "Edit Threshold",
        newTitle: "New Threshold",
        type: "Type",
        absoluteValue: "Absolute value",
      },
      taxation: {
        title: "Taxation",
        eu: "EU",
        non_eu: "Non EU",
        cyprus: "Cyprus",
      },
      addPeriod: "Add period",
    },
    counterpartyBankRelations: {
      title: "Conterparty-Bank Relations",
      counterpartyName: "Counterparty name",
      bankName: "Bank name",
      dateFrom: "Date from",
      dateTo: "Date to",
      period: "Period",
      deleteDialog: {
        title: "Delete counterparty-bank relation",
        body: "Do you really want to delete this relation?",
      },
      notification: {
        createdSuccess: `The Conterparty-Bank Relation was created successfully`,
        updatedSuccess: `The Conterparty-Bank Relation was updated successfully`,
        deletedSuccess: `The Conterparty-Bank Relation was deleted successfully`,
      },
    },
    invoices: {
      title: "Invoices",
      calculateTitle: "Calculate invoice",
      editTitle: "Edit payment details",
      invoicesResult: "Invoices Result",
      accrualPeriod: "Accrual period",
      counterpartyName: "Counterparty name",
      empty: "Fill filters to see invoices",
      number: "No.",
      accrualDate: "Accrual date",
      expectedAmount: "Expected amount",
      expectedPaymentDate: "Expected payment date",
      expectedDateStatus: "Expected date status",
      paymentDate: "Payment date",
      paymentAmount: "Payment amount",
      status: "Status",
      open: "Open",
      paid: "Paid",
      validated: "Validated",
      overpay: "Overpay",
      underpay: "Underpay",
      ok: "OK",
      overdue: "Overdue",
      showOverdue: "Show overdue",
      filters: "Filters",
      finishInvoice: "Finish invoice",
      invoicesPaidCount: "{0}/{1} invoices paid",
      paymentStatuses: "Payment statuses",
      expDateStatuses: "Exp. date statuses",
      invoiceDetails: "Invoice details",
      changeHistory: "Change history",
      fullName: "Full name",
      date: "Date",
      edited: "Edited",
      created: "Created",
      invoiceNumber: "Invoice number",
      amount: "Amount",
      paymentStatusEmptyData: "No payments received yet",
      expectedDateStatusEmptyData: "No open invoices",
      changes: "Changes",
      finishDetailsTooltip:
        "You have non-finished invoices for previous period(s)<br />for counterparty {0}:",
      finishDetailsTooltipListItem: "#{0} - accrual period: {1}",
      validateInvoiceTooltip: "Validate invoice",
      notification: {
        createdSuccess: "The Invoice was created successfully",
        updatedSuccess: "The Invoice was updated successfully",
        finishedSuccess: "The Invoice was finished successfully",
        validatedSuccess: "The Invoice was validated successfully",
      },
    },
    address: {
      title: "Address",
      street: "Street",
      postalCode: "Postal code",
      city: "City",
      country: "Country",
    },
    errorCode: {
      invoice_generation_error: "Failed to generate invoice pdf",
      counterparty_currency_not_found_in_bank:
        "Failed to find bank's iban for currency of the counterparty",
      invoice_amount_zero_delta_error:
        "Failed to generate pdf for invoice where delta between expected and actual amount is 0",
      amount_empty_error:
        "Failed to generate document where invoice expected amount or amount is null",
      counterpaty_bank_is_not_found:
        "Failed to find current bank for invoice's counterparty due to miss of counterparty-bank joins",
      counterpaty_bank_is_not_found_for_accrual_period:
        "Failed to find current bank for invoice's counterpart due to miss of counterparty-bank join for invoice's accrual period",
      counterpaty_net_is_not_found:
        "Failed to find net for invoice's counterparty due to miss of counterparty-net joins",
      counterpaty_net_is_not_found_for_accrual_period:
        "Failed to find net for invoice's counterparty due to miss of counterparty-net join for invoice's accrual period",
      pdf_processing_provider_not_found:
        "Failed to find pdf data processing provider",
    },
  },
};
