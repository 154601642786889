import { ReportType } from "@/reports/models";

export enum DictionaryType {
  LANGUAGES = "languages",
  COUNTRIES = "countries",
  GROUPED_COUNTRIES = "groupedCountries",
  DEVICE_TYPES = "deviceTypes",
  DEVICE_MODELS = "deviceModels",
  APP_VERSIONS = "appVersions",
  GROUPED_APP_VERSIONS = "groupedAppVersions",
  OS_VERSIONS = "osVersions",
  SEGMENTS = "segments",
  SOURCES = "sources",
  SUB_SOURCES = "subSources",
  CAMPAIGNS = "campaigns",
  AD_SETS = "adSets",
  CREATIVES = "creatives",
  PUBLISHERS = "publishers",
  EVENTS = "events",
  REVENUES = "revenues",
  ATTRIBUTION_STATUS = "attributionStatus",
  EVENT_NAMES = "eventNames",
  EVENT_PARAM_KEYS = "eventParamKeys",
  WATERFALLS_ID = "waterfallsId",
  ROOT_AD_NETWORK_NAMES = "rootAdNetworkNames",
  ALL_AD_NETWORK_NAMES = "allAdNetworkNames",
  ACTIVITY_KIND = "activityKind",
  AB_TEST_DAY_NUMBER = "abTestDayNumber",
  EXTERNAL_TEST_PROPOSALS = "externalAbTests",
  EVENTS_EXTENDED = "eventsExtended",
  PRICED_NETWORK_NAMES = "pricedNetworkNames",
}

export const ARPDAU_DICTIONARIES = [
  DictionaryType.SEGMENTS,
  DictionaryType.DEVICE_TYPES,
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.PRICED_NETWORK_NAMES,
];

export const ARPDAU_SIMPLE_DICTIONARIES = [
  DictionaryType.DEVICE_TYPES,
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.ROOT_AD_NETWORK_NAMES,
];

export const BASE_COHORT_DICTIONARIES = [
  DictionaryType.SEGMENTS,
  DictionaryType.ATTRIBUTION_STATUS,
  DictionaryType.DEVICE_TYPES,
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
];

export const APP_VERSIONS_COHORT_DICTIONARIES = [
  ...BASE_COHORT_DICTIONARIES,
  DictionaryType.APP_VERSIONS,
  DictionaryType.GROUPED_APP_VERSIONS,
];

export const ARPU_DICTIONARIES = [
  ...BASE_COHORT_DICTIONARIES,
  DictionaryType.REVENUES,
  DictionaryType.EVENTS,
  DictionaryType.PRICED_NETWORK_NAMES,
];

export const COHORT_PER_MIN_DICTIONARIES = [
  ...APP_VERSIONS_COHORT_DICTIONARIES,
  DictionaryType.REVENUES,
];

export const CALENDAR_PER_MIN_DICTIONARIES = [
  ...APP_VERSIONS_COHORT_DICTIONARIES,
  DictionaryType.OS_VERSIONS,
];

export const EVENT_NAMES_COHORT_DICTIONARIES = [
  ...APP_VERSIONS_COHORT_DICTIONARIES,
  DictionaryType.EVENT_NAMES,
];

export const REVENUE_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.DEVICE_TYPES,
  DictionaryType.WATERFALLS_ID,
  DictionaryType.ROOT_AD_NETWORK_NAMES,
  DictionaryType.GROUPED_COUNTRIES,
];

export const WATERFALL_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.DEVICE_TYPES,
  DictionaryType.WATERFALLS_ID,
  DictionaryType.ROOT_AD_NETWORK_NAMES,
  DictionaryType.GROUPED_COUNTRIES,
];

export const EVENTS_COST_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.EVENT_NAMES,
];

export const SPEND_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
];

export const SPEND_MINI_DICTIONARIES = [
  ...SPEND_DICTIONARIES,
  DictionaryType.SOURCES,
];

export const REVENUE_EVENTS_DICTIONARIES = [
  DictionaryType.EVENT_NAMES,
  DictionaryType.APP_VERSIONS,
  DictionaryType.GROUPED_APP_VERSIONS,
  DictionaryType.REVENUES,
  DictionaryType.EVENTS_EXTENDED,
];

export const SUBSCRIPTION_EVENTS_DICTIONARIES = [
  DictionaryType.EVENT_NAMES,
  DictionaryType.APP_VERSIONS,
  DictionaryType.GROUPED_APP_VERSIONS,
];

export const COHORT_ANALYSIS_DICTIONARIES = [
  DictionaryType.SEGMENTS,
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.ATTRIBUTION_STATUS,
  DictionaryType.APP_VERSIONS,
  DictionaryType.GROUPED_APP_VERSIONS,
  DictionaryType.REVENUES,
];

export const PROFIT_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
];

export const CALENDAR_CPM_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.PRICED_NETWORK_NAMES,
];

export const METRICS_DICTIONARIES = [
  ...BASE_COHORT_DICTIONARIES,
  DictionaryType.EVENTS,
];

export const PAYOUTS_DICTIONARIES = [DictionaryType.ALL_AD_NETWORK_NAMES];

export const CALENDAR_CTR_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.SEGMENTS,
  DictionaryType.ATTRIBUTION_STATUS,
  DictionaryType.DEVICE_TYPES,
  DictionaryType.PRICED_NETWORK_NAMES,
];

export const CASH_COUNTRY_DICTIONARIES = [
  ...BASE_COHORT_DICTIONARIES,
  DictionaryType.EVENTS,
];

export const DIFF_INSTALLS_STORES_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.DEVICE_TYPES,
];

export const CTR_COHORT_DICTIONARIES = [
  ...BASE_COHORT_DICTIONARIES,
  DictionaryType.PRICED_NETWORK_NAMES,
];

export const AD_ROAS_COUNTRY_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.REVENUES,
];

export const AD_ROAS_NETWORK_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.REVENUES,
];

export const CASH_GAMING_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
];

export const COHORT_CALENDAR_CPM_DICTIONARIES = [
  ...BASE_COHORT_DICTIONARIES,
  DictionaryType.REVENUES,
  DictionaryType.EVENTS,
  DictionaryType.PRICED_NETWORK_NAMES,
];

export const SKAD_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.EVENTS,
];

export const PLACEMENT_DICTIONARIES = [
  DictionaryType.DEVICE_TYPES,
  DictionaryType.WATERFALLS_ID,
];

export const METRICS_SPEND_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.ATTRIBUTION_STATUS,
];

export const FILL_RATE_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.DEVICE_TYPES,
  DictionaryType.APP_VERSIONS,
  DictionaryType.GROUPED_APP_VERSIONS,
  DictionaryType.OS_VERSIONS,
];

export const PAYING_USERS_CONVERSION_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
];

export const PRICED_REVENUE_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.DEVICE_TYPES,
  DictionaryType.PRICED_NETWORK_NAMES,
  DictionaryType.SEGMENTS,
];

export const MONETIZATION_MONITORING_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.ROOT_AD_NETWORK_NAMES,
];

export const FIREBASE_SHOW_TO_IMPRESSION_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.ROOT_AD_NETWORK_NAMES,
  DictionaryType.DEVICE_TYPES,
];

export const FIREBASE_VS_NETWORKS_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.DEVICE_TYPES,
  DictionaryType.ROOT_AD_NETWORK_NAMES,
];

export const FIREBASE_FILL_RATE_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.DEVICE_TYPES,
  DictionaryType.APP_VERSIONS,
  DictionaryType.GROUPED_APP_VERSIONS,
  DictionaryType.OS_VERSIONS,
];

export const TRAFFIC_QUALITY_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.DEVICE_TYPES,
  DictionaryType.SEGMENTS,
  DictionaryType.ATTRIBUTION_STATUS,
];

export const UA_MAIN_METRICS_OVERVIEW_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.REVENUES,
];

export const SUBSCRIPTIONS_OVERVIEW_DICTIONARIES = [
  DictionaryType.SEGMENTS,
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.ATTRIBUTION_STATUS,
  DictionaryType.APP_VERSIONS,
  DictionaryType.GROUPED_APP_VERSIONS,
];

export const METRICS_CONSTRUCTOR_DICTIONARIES = [
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.REVENUES,
  DictionaryType.EVENTS,
];

export const SUBSCRIPTIONS_OVERVIEW_IOS_DICTIONARIES = [
  DictionaryType.SEGMENTS,
  DictionaryType.COUNTRIES,
  DictionaryType.GROUPED_COUNTRIES,
  DictionaryType.ATTRIBUTION_STATUS,
  DictionaryType.APP_VERSIONS,
  DictionaryType.GROUPED_APP_VERSIONS,
];

export const DICTIONARIES_BY_REPORT_TYPE: Record<
  ReportType,
  Array<DictionaryType>
> = {
  MEASURES_LITE: EVENT_NAMES_COHORT_DICTIONARIES,
  ARPU: ARPU_DICTIONARIES,
  TIME_SPENT: APP_VERSIONS_COHORT_DICTIONARIES,
  RETURN_RATE: APP_VERSIONS_COHORT_DICTIONARIES,
  COHORT_PER_MIN: APP_VERSIONS_COHORT_DICTIONARIES,
  CALENDAR_PER_MIN: CALENDAR_PER_MIN_DICTIONARIES,
  ARPDAU: ARPDAU_DICTIONARIES,
  ARPDAU_SIMPLE: ARPDAU_SIMPLE_DICTIONARIES,
  REVENUE: REVENUE_DICTIONARIES,
  EVENTS_SUMMARY: EVENT_NAMES_COHORT_DICTIONARIES,
  SPEND_HYPER: SPEND_DICTIONARIES,
  SPEND_MINI: SPEND_MINI_DICTIONARIES,
  SPEND: SPEND_DICTIONARIES,
  COHORT_ANALYSIS: COHORT_ANALYSIS_DICTIONARIES,
  PROFIT: PROFIT_DICTIONARIES,
  CALENDAR_CPM: CALENDAR_CPM_DICTIONARIES,
  METRICS: METRICS_DICTIONARIES,
  WATERFALL: WATERFALL_DICTIONARIES,
  EVENTS_COST: EVENTS_COST_DICTIONARIES,
  CALENDAR_CTR: CALENDAR_CTR_DICTIONARIES,
  CASH_COUNTRY: CASH_COUNTRY_DICTIONARIES,
  COHORT_CTR: CTR_COHORT_DICTIONARIES,
  PAYOUTS: PAYOUTS_DICTIONARIES,
  DIFF_INSTALLS_STORES: DIFF_INSTALLS_STORES_DICTIONARIES,
  CASH_GAMING: CASH_GAMING_DICTIONARIES,
  COHORT_CPM: COHORT_CALENDAR_CPM_DICTIONARIES,
  SKAD: SKAD_DICTIONARIES,
  AD_ROAS_COUNTRY: AD_ROAS_COUNTRY_DICTIONARIES,
  AD_ROAS_NETWORK: AD_ROAS_NETWORK_DICTIONARIES,
  METRICS_SPEND: METRICS_SPEND_DICTIONARIES,
  FILL_RATE: FILL_RATE_DICTIONARIES,
  PAYING_USERS_CONVERSION: PAYING_USERS_CONVERSION_DICTIONARIES,
  PRICED_REVENUE: PRICED_REVENUE_DICTIONARIES,
  MONETIZATION_MONITORING: MONETIZATION_MONITORING_DICTIONARIES,
  FIREBASE_SHOW_TO_IMPRESSION: FIREBASE_SHOW_TO_IMPRESSION_DICTIONARIES,
  FIREBASE_VS_NETWORKS: FIREBASE_VS_NETWORKS_DICTIONARIES,
  FIREBASE_FILL_RATE: FIREBASE_FILL_RATE_DICTIONARIES,
  TRAFFIC_QUALITY: TRAFFIC_QUALITY_DICTIONARIES,
  UA_MAIN_METRICS_OVERVIEW: UA_MAIN_METRICS_OVERVIEW_DICTIONARIES,
  SUBSCRIPTIONS_OVERVIEW: SUBSCRIPTIONS_OVERVIEW_DICTIONARIES,
  METRICS_CONSTRUCTOR: METRICS_CONSTRUCTOR_DICTIONARIES,
  SUBSCRIPTIONS_OVERVIEW_IOS: SUBSCRIPTIONS_OVERVIEW_IOS_DICTIONARIES,
};
