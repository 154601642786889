import {
  Application,
  DatesFilterModel,
  FilterId,
  FilterModel,
  FilterPreviewId,
  MultipleValueFilterModel,
  recordToFilterModel,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import {
  BaseGradientFlags,
  BaseReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { EventsDayLimitReportFilterExtension } from "./ReportFilterExtension";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import { ReportDataType } from "./ReportVisualization";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class EventsCostFilter
  extends BaseReportFilter
  implements EventsDayLimitReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    public groupByFilter = new GroupByFilter(AggregationPeriod.DAY, [], false),
    public eventsDayLimit = 7
  ) {
    super(ReportType.EVENTS_COST, app, filter, date, groupByFilter);
  }

  get invalid(): boolean {
    return !this.filter.find(({ id }) => id === FilterId.SIMPLE_MULTIPLE_EVENT);
  }

  get previews() {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.EVENTS_DAY_LIMIT,
        value: this.eventsDayLimit,
      },
    ];
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): EventsCostFilter {
    return new EventsCostFilter(
      app,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
      new GroupByFilter(
        AggregationPeriod.DAY,
        requestQuery?.groupByFilter?.groupBy,
        false
      ),
      requestQuery?.eventsDayLimit ? +requestQuery.eventsDayLimit : undefined
    );
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      eventsDayLimit: this.eventsDayLimit,
    };
  }
}

export class EventsCostGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      gradient: true,
    };
  }

  getByKey(): boolean {
    return super.getByKey("gradient");
  }
}

export class EventsCostHeaders {
  static init(
    lang: LangService,
    report: EventsCostFilter,
    reportDataType: ReportDataType,
    events: Array<{ name: string; value: string }> = []
  ): Array<ReportHeader> {
    return [
      ...(report.groupByFilter.isNotEmptyGroupBy
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      ...(reportDataType === ReportDataType.DATA
        ? [ReportHeaderUtil.createDate(lang)]
        : []),
      {
        text: lang("components.baseTable.spend"),
        align: "end",
        value: `${ReportResultItem.PREFIX}spend`,
        fractionDigits: 0,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.adjustInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}adjustInstalls`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.cpi"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cpi`,
        hasGradient: true,
        reverseGradient: true,
      },
      ...events.reduce(
        (result, event) => [
          ...result,
          {
            text: lang(`components.baseTable.adjustCount_`, event.name),
            align: "end",
            value: `${ReportResultItem.PREFIX}adjustCount_${event.value}`,
            hasGradient: true,
          } as ReportHeader,
          {
            text: lang(`components.baseTable.adjustPercent_`, event.name),
            align: "end",
            value: `${ReportResultItem.PREFIX}adjustPercent_${event.value}`,
            hasGradient: true,
            fractionDigits: 0,
          } as ReportHeader,
          {
            text: lang(`components.baseTable.cpa_`, event.name),
            align: "end",
            value: `${ReportResultItem.PREFIX}cpa_${event.value}`,
            hasGradient: true,
            fractionDigits: 2,
            reverseGradient: true,
          } as ReportHeader,
        ],
        [] as Array<ReportHeader>
      ),
    ];
  }
}

export class EventsCostResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();

    this.setGroupByValue(filter.groupByFilter, row);

    if (row.getByHeader("date")) {
      this.date = row.getByHeader("date");
      this.setFormattedDate(
        this.date,
        filter.date.from,
        filter.date.to,
        filter.groupByFilter.aggregationPeriod
      );
    }

    this.data["spend"] = row.getByHeader("spend");
    this.data["adjustInstalls"] = row.getByHeader("adjustInstalls") ?? 0;
    this.data["cpi"] = row.getByHeader("cpi");

    (
      filter.filter.find(
        ({ id }: FilterModel) => id === FilterId.SIMPLE_MULTIPLE_EVENT
      ) as MultipleValueFilterModel
    )?.values.forEach((eventName) => {
      this.data[`cpa_${eventName}`] = row.getByHeader(`cpa_${eventName}`);
      this.data[`adjustPercent_${eventName}`] = row.getByHeader(
        `adjustPercent_${eventName}`
      );
      this.data[`adjustCount_${eventName}`] = row.getByHeader(
        `adjustCount_${eventName}`
      );
    });
  }
}
