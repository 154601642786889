export default {
  chart: {
    title: {
      dau_dynamics: "DAU dynamics",
      revenue_spendings_daily_dynamics: "Revenue, Spendings daily dynamics",
      cumulated_revenue_spendings_profit:
        "Cumulated Revenue, Spendings, Profit",
      cumulated_arpu_structure: "Cumulated ARPU structure",
      cumulated_arpu: "Cumulated ARPU",
      roas_n_day: "ROAS N Day",
      retention_rate: "Retention Rate",
      cumulated_playtime_per_active_user: "Cumulated Playtime per Active user",
      campaigns_relationship: "Campaigns Relationship",
    },
    label: {
      iapRevenue: "IAP Revenue",
      rewarded: "Rewarded",
      interstitial: "Interstitial",
      banner: "Banner",
      thDay: "{0}th day",
      stDay: "{0}st day",
      ndDay: "{0}nd day",
      rdDay: "{0}rd day",
      arpdau: "ARPDAU",
      arpdauIap: "ARPDAU IAP",
      eventsBanner: "Events Banner",
      perMinBanner: "Permin Banner",
      totalOccurrences: "Total Occurrences",
      avgEventsPerSession: "Avg Events per Session",
      occurrencesDailyAvg: "Avg Events per Device",
      uniqueDevicesDailyAvg: "Unique Devices",
      spend: "Spend",
      revenue: "Revenue",
      profit: "Profit",
      dau: "DAU",
      bannerCPM: "Banner CPM",
      interstitialCPM: "Interstitial CPM",
      rewardedCPM: "Rewarded CPM",
      installs: "Installs",
      arpuUsd: "ARPU, $",
      total: "Total",
      activeUsers: "Active Users",
      installed: "Installed",
      reattributed: "Reattributed",
      spendUsd: "Spend, $",
      inAppRevenueUsd: "InApp Revenue, $",
      subscriptionRevenueUsd: "Subscription Revenue, $",
      adRevenueUsd: "Ad Revenue, $",
      profitUsd: "Profit, $",
      adArpuPercent: "Cum Ad ARPU, %",
      inAppArpuPercent: "Cum InApp ARPU, %",
      subscriptionArpuPercent: "Subscription ARPU, %",
      cumArpuUsd: "Cum ARPU, $",
      roi: "ROI",
      retentionRate: "Retention Rate",
      playtime: "Cum Playtime per Active user",
      usersCount: "Users count",
      valueUsd: "Value, $",
      daysAfterInstall: "Days after install",
      roas: "ROAS",
      playtimeMinutes: "Playtime, minutes",
      eCpiUsd: "eCpi, $:",
      paybackThreshold: "Payback \nthreshold, %:",
      controlGroup: "Control group",
      targetGroup: "Target group",
      confidenceInterval: "Confidence interval",
      day: "Day",
      retentionRatePercent: "Retention Rate, %",
      timeSpentMinutes: "Time Spent, minutes",
      dau_e: "DAUe",
      arpdau_e: "ARPDAUe",
      dau_b: "DAUb",
      banner_e: "ARPDAUb",
      dau_i: "DAUi",
      interstitial_e: "ARPDAUi",
      dau_r: "DAUr",
      rewarded_e: "ARPDAUr",
      bannerCtr: "Banner CTR",
      interstitialCtr: "Interstitial CTR",
      rewardedCtr: "Rewarded CTR",
      rawData: "Raw Data",
      store: "Store",
      bannerFillRate: "Banner Fill Rate",
      bannerPerDau: "Banner per DAU",
      bannerRequestsEventsPerDau: "Banner Requests Per DAU",
      interFillRate: "Interstitial Fill Rate",
      interPerDau: "Interstitial per DAU",
      interRequestsEventsPerDau: "Inter Requests Per DAU",
      rewardFillRate: "Rewarded Fill Rate",
      rewardPerDau: "Rewarded per DAU",
      rewardRequestsEventsPerDau: "Reward Requests Per DAU",
      events: "Events",
      diffRevenueDay: "Diff Revenue Day",
      subscription: "Subscription",
      toggleAll: "Toggle all",
      adRoasCountry: "Ad ROAS Country",
      adRoasNetwork: "Ad ROAS Network",
      arpu: "ARPU",
      cohortCpm: "Cohort CPM",
      cohortCtr: "Cohort CTR",
      engagement: "Engagement",
      measuresLite: "Measures Lite",
      returnRate: "Return Rate",
      timeSpent: "Time Spent",
      value: "Value",
      arppu: "ARPPU",
      payingUsers: "Paying Users",
    },
    tooltip: {
      adArpuPercent:
        "Days after install: [bold]{day}[/]\nCum Ad ARPU: [bold]{adArpuPercent}% ({adArpu}$)[/]",
      inAppArpuPercent:
        "Days after install: [bold]{day}[/]\nCum InApp ARPU: [bold]{inAppArpuPercent}% ({inAppArpu}$)[/]",
      subscriptionArpuPercent:
        "Days after install: [bold]{day}[/]\nSubscription ARPU: [bold]{subscriptionArpuPercent}% ({subscriptionArpu}$)[/]",
      cumArpu:
        "Total installs: [bold]{installs}[/]\nCum Revenue: [bold]{cumRevenue}$[/]\nCum ARPU: [bold]{cumArpu}$[/]",
      roi: "ROI: [bold]{roi}%[/]",
      roas: "ROAS: [bold]{roi}%[/]",
      retentionRate:
        "Installs: [bold]{installs}[/]\nRetained users: [bold]{retainedUsers}[/]\nRetention Rate: [bold]{retentionRate}%[/]",
      playtime: "Cum Playtime per Active user: [bold]{playtime}[/]",
      arpdau: "ARPDAU",
      controlGroup: "Control group: [bold]{cg}{0}[/]",
      targetGroup: "Target group: [bold]{tg}{0}[/]",
      totalOccurrences: "Total Occurrences: [bold]{totalOccurrences}[/]",
      avgEventsPerSession:
        "Avg Events per Session: [bold]{avgEventsPerSession}[/]",
      occurrencesDailyAvg:
        "Avg Events per Device: [bold]{occurrencesDailyAvg}[/]",
      uniqueDevicesDailyAvg: "Unique Devices: [bold]{uniqueDevicesDailyAvg}[/]",
    },
  },
};
