import { DataTableHeader } from "vuetify";
import {
  Application,
  DatesFilterModel,
  DefaultValues,
  FilterId,
  FilterModel,
  FilterPreview,
  FilterPreviewId,
  recordToFilterModel,
  TrackerFilterModel,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import DateUtil from "@/shared/utils/DateUtil";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import {
  BaseGradientFlags,
  BaseReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportType } from "./ReportType";
import { ReportDataType } from "./ReportVisualization";
import {
  NetRevenueReportFilterExtension,
  TargetDayReportFilterExtension,
} from "./ReportFilterExtension";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class CashGamingGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      gradient: true,
    };
  }

  getByKey(): boolean {
    return super.getByKey("gradient");
  }
}
export class CashGamingFilter
  extends BaseReportFilter
  implements TargetDayReportFilterExtension, NetRevenueReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date: DatesFilterModel = DefaultValues.initDatesFilterModel(
      FilterId.EVENTS_DATE,
      30
    ),
    groupByFilter = new GroupByFilter(AggregationPeriod.DAY, [], false),
    public targetDay: number = 7,
    public maxTargetDay: boolean = false,
    public netRevenue = true
  ) {
    super(ReportType.CASH_GAMING, app, filter, date, groupByFilter);

    this.maxTargetDay =
      typeof maxTargetDay === "string" ? maxTargetDay == "true" : maxTargetDay;
  }

  get previews(): Array<FilterPreview> {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.TARGET_DAY_MODE,
        value: this.maxTargetDay ? "Max available" : "Concrete day",
      },
      ...(!this.maxTargetDay
        ? [
            {
              id: FilterPreviewId.TARGET_DAY,
              value: this.targetDay,
            },
          ]
        : []),
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  get invalid(): boolean {
    return !(
      this.app &&
      this.date.valid &&
      (this.maxTargetDay || (this.targetDay > 0 && this.targetDay <= 365))
    );
  }

  static of(
    app: Application,
    requestQuery?: Record<string, any>
  ): CashGamingFilter {
    return requestQuery
      ? new CashGamingFilter(
          app,
          requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
          DatesFilterModel.ofRecord(FilterId.EVENTS_DATE, requestQuery?.date),
          new GroupByFilter(
            AggregationPeriod.DAY,
            requestQuery?.groupByFilter?.groupBy,
            false
          ),
          requestQuery.targetDay !== undefined
            ? Number(requestQuery.targetDay)
            : undefined,
          requestQuery.maxTargetDay
          // TODO: NET_REVENUE need to uncomment later
          // typeof requestQuery.netRevenue === "string"
          //   ? requestQuery.netRevenue === "true"
          //   : requestQuery.netRevenue,
        )
      : new CashGamingFilter(app);
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      maxTargetDay: this.maxTargetDay,
      ...(!this.maxTargetDay
        ? {
            targetDay: this.targetDay,
          }
        : {}),
      // TODO: NET_REVENUE need to uncomment later
      // netRevenue: this.netRevenue,
    };
  }
}
export class CashGamingHeaders {
  static readonly PRECISION = 2;

  static init(
    lang: LangService,
    report: CashGamingFilter,
    reportDataType: ReportDataType
  ): Array<ReportHeader> {
    const isSource =
      (
        report.filter.find(
          ({ id }) => id === FilterId.TRACKER
        ) as TrackerFilterModel
      )?.source ||
      !!report.groupByFilter.groupBy.some((filterId: FilterId) =>
        [
          FilterId.SOURCE,
          FilterId.CAMPAIGN,
          FilterId.AD_SET,
          FilterId.CREATIVE,
          FilterId.PUBLISHER,
        ].includes(filterId)
      );

    return [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy &&
      reportDataType !== ReportDataType.TOTAL
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter)
        : []),
      {
        text: lang("components.baseTable.adjustInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}adjustInstalls`,
        hasGradient: true,
      },
      ...(!isSource
        ? [
            {
              text: lang("components.baseTable.storeInstalls"),
              align: "end",
              value: `${ReportResultItem.PREFIX}storeInstalls`,
              hasGradient: true,
            } as DataTableHeader,
          ]
        : []),
      {
        text: lang("components.baseTable.paidInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}paidInstalls`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.netwInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}netwInstalls`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.spend"),
        align: "end",
        value: `${ReportResultItem.PREFIX}spend`,
        hasGradient: true,
        fractionDigits: 0,
      },
      {
        text: lang("components.baseTable.cpiAdjust"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cpiAdjust`,
        hasGradient: true,
        reverseGradient: true,
        fractionDigits: CashGamingHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.cpiNetw"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cpiNetworks`,
        hasGradient: true,
        reverseGradient: true,
        fractionDigits: CashGamingHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.ecpiAdjust"),
        align: "end",
        value: `${ReportResultItem.PREFIX}ecpiAdjust`,
        hasGradient: true,
        reverseGradient: true,
        fractionDigits: CashGamingHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.iapRevenue"),
        align: "end",
        value: `${ReportResultItem.PREFIX}inappsRevenue`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.organicAdjust"),
        align: "end",
        value: `${ReportResultItem.PREFIX}organicAdjust`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.organicAdjustPercent"),
        align: "end",
        value: `${ReportResultItem.PREFIX}organicAdjustPercent`,
        hasGradient: true,
        fractionDigits: CashGamingHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.purchases"),
        align: "end",
        value: `${ReportResultItem.PREFIX}purchases`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.payingUsers"),
        align: "end",
        value: `${ReportResultItem.PREFIX}payingUsers`,
        hasGradient: true,
      },
      {
        text: lang("components.baseTable.cpa"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cpa`,
        hasGradient: true,
        reverseGradient: true,
        fractionDigits: CashGamingHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.cppu"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cppu`,
        hasGradient: true,
        reverseGradient: true,
        fractionDigits: CashGamingHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.payingUsersPercent"),
        align: "end",
        value: `${ReportResultItem.PREFIX}payingUsersPercent`,
        hasGradient: true,
        fractionDigits: CashGamingHeaders.PRECISION,
      },
      {
        text: lang("components.baseTable.roasPercent"),
        align: "end",
        value: `${ReportResultItem.PREFIX}roas`,
        hasGradient: true,
        fractionDigits: CashGamingHeaders.PRECISION,
      },
      // TODO: waiting for the implementation on the backend
      // {
      //   text: lang("components.baseTable.roasDayNumber"),
      //   align: "end",
      //   value: `${ReportResultItem.PREFIX}roasDayNumber`,
      //   hasGradient: true,
      // },
    ];
  }
}

export class CashGamingResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter, isData = false) {
    super();

    if (isData) {
      this.date = row.getByHeader("aggDate");
      this.formattedDate = DateUtil.formatDate(this.date);
    }

    this.setGroupByValue(filter.groupByFilter, row);
    this.data["adjustInstalls"] = row.getByHeader("adjustInstalls") ?? 0;
    this.data["storeInstalls"] = row.getByHeader("storeInstalls");
    this.data["paidInstalls"] = row.getByHeader("paidInstalls");
    this.data["netwInstalls"] = row.getByHeader("netwInstalls");
    this.data["spend"] = Math.round(
      Number.parseFloat(row.getByHeader("spend") ?? 0)
    );
    this.data["cpiAdjust"] = super.parseFloat(
      row.getByHeader("cpiAdjust"),
      CashGamingHeaders.PRECISION
    );
    this.data["cpiNetworks"] = super.parseFloat(
      row.getByHeader("cpiNetworks"),
      CashGamingHeaders.PRECISION
    );
    this.data["ecpiAdjust"] = super.parseFloat(
      row.getByHeader("ecpiAdjust"),
      CashGamingHeaders.PRECISION
    );
    this.data["inappsRevenue"] = row.getByHeader("inappsRevenue");
    this.data["organicAdjust"] = row.getByHeader("organicAdjust");
    this.data["organicAdjustPercent"] = super.parseFloat(
      row.getByHeader("organicAdjustPercent"),
      CashGamingHeaders.PRECISION
    );
    this.data["purchases"] = row.getByHeader("purchases");
    this.data["payingUsers"] = row.getByHeader("payingUsers");
    this.data["cpa"] = super.parseFloat(
      row.getByHeader("cpa"),
      CashGamingHeaders.PRECISION
    );
    this.data["cppu"] = super.parseFloat(
      row.getByHeader("cppu"),
      CashGamingHeaders.PRECISION
    );
    this.data["payingUsersPercent"] = super.parseFloat(
      row.getByHeader("payingUsersPercent"),
      CashGamingHeaders.PRECISION
    );
    this.data["roas"] = super.parseFloat(
      row.getByHeader("roas"),
      CashGamingHeaders.PRECISION
    );
    // TODO: waiting for the implementation on the backend
    // this.data["roasDayNumber"] = row.getByHeader("roasDayNumber");
  }
}
