import { ChartName } from "@/chart/models/ChartModel";
import { ReportType, ReportResultItem } from "@/reports/models";

export interface ReportMetaType {
  totalRows: number;
  headers: Array<string>;
  filledRows: number;
}

export class ReportDataStore {
  constructor(
    public items: Array<ReportResultItem> = [],
    public loading = false,
    public chunks: Array<Array<number | string>> = [],
    public meta: ReportMetaType | null = null,
    public serverErrorMsg = ""
  ) {}

  setItems(items: Array<ReportResultItem>) {
    this.items = items;
    this.loading = false;
    this.chunks = [];
    this.meta = null;
    this.serverErrorMsg = "";
  }
}

export type ReportStoreType = Record<ReportDataType, ReportDataStore>;

export enum ReportDataType {
  TOTAL = "TOTAL",
  SUB_TOTAL = "SUB_TOTAL",
  DATA = "DATA",
  DAU_DYNAMICS = "DAU_DYNAMICS",
  REVENUE_SPENDINGS_DAILY_DYNAMICS = "REVENUE_SPENDINGS_DAILY_DYNAMICS",
  CUMULATED_REVENUE_SPENDINGS_PROFIT = "CUMULATED_REVENUE_SPENDINGS_PROFIT",
  CUMULATED_ARPU_STRUCTURE = "CUMULATED_ARPU_STRUCTURE",
  CUMULATED_ARPU = "CUMULATED_ARPU",
  CUMULATED_ARPU_COHORT = "CUMULATED_ARPU_COHORT",
  ROAS_N_DAY = "ROAS_N_DAY",
  ROAS_N_DAY_COHORT = "ROAS_N_DAY_COHORT",
  RETENTION_RATE = "RETENTION_RATE",
  RETENTION_RATE_COHORT = "RETENTION_RATE_COHORT",
  CUMULATED_PLAYTIME_PER_ACTIVE_USER = "CUMULATED_PLAYTIME_PER_ACTIVE_USER",
  CUMULATED_PLAYTIME_PER_ACTIVE_USER_COHORT = "CUMULATED_PLAYTIME_PER_ACTIVE_USER_COHORT",
  SSB = "SSB",
  SSB_CHART = "SSB_CHART",
  MONTH_TOTAL = "MONTH_TOTAL",
  PREDICTED_CASH_COUNTRY = "PREDICTED_CASH_COUNTRY",
  REVENUE_DIFF_PERCENT_DATE_COUNTRY = "REVENUE_DIFF_PERCENT_DATE_COUNTRY",
  REVENUE_DIFF_PERCENT_DATE_NETWORK = "REVENUE_DIFF_PERCENT_DATE_NETWORK",
  ARPDAU_DIFF_DATE_COUNTRY = "ARPDAU_DIFF_DATE_COUNTRY",
  ARPDAU_DIFF_DATE_NETWORK = "ARPDAU_DIFF_DATE_NETWORK",
  MONITORING = "MONITORING",
}

export enum ReportDataSource {
  ADJUST_RAW_EVENTS = "ADJUST_RAW_EVENTS",
  ADJUST_ATTRIBUTION = "ADJUST_ATTRIBUTION",
  NETWORKS_REVENUE = "NETWORKS_REVENUE",
  NETWORKS_SPEND = "NETWORKS_SPEND",
  FIREBASE_RAW_EVENTS = "FIREBASE_RAW_EVENTS",
  STORES_REVENUE = "STORES_REVENUE",
  STORES_INSTALLS = "STORES_INSTALLS",
  ADJUST_SKAD_EVENTS = "ADJUST_SKAD_EVENTS",
  NETWORKS_SKAD = "NETWORKS_SKAD",
  FIREBASE_EVENTS = "FIREBASE_EVENTS",
}

const DEFAULT_DATA_TYPES = [
  ReportDataType.TOTAL,
  ReportDataType.SUB_TOTAL,
  ReportDataType.DATA,
];

export const REPORT_DATA_BY_REPORT_TYPE: Record<
  ReportType,
  Array<ReportDataType>
> = {
  COHORT_ANALYSIS: [
    ReportDataType.TOTAL,
    ReportDataType.SUB_TOTAL,
    ReportDataType.DAU_DYNAMICS,
    ReportDataType.REVENUE_SPENDINGS_DAILY_DYNAMICS,
    ReportDataType.CUMULATED_REVENUE_SPENDINGS_PROFIT,
    ReportDataType.CUMULATED_ARPU_STRUCTURE,
    ReportDataType.CUMULATED_ARPU,
    ReportDataType.CUMULATED_ARPU_COHORT,
    ReportDataType.ROAS_N_DAY,
    ReportDataType.ROAS_N_DAY_COHORT,
    ReportDataType.RETENTION_RATE,
    ReportDataType.RETENTION_RATE_COHORT,
    ReportDataType.CUMULATED_PLAYTIME_PER_ACTIVE_USER,
    ReportDataType.CUMULATED_PLAYTIME_PER_ACTIVE_USER_COHORT,
  ],
  REVENUE: [ReportDataType.TOTAL, ReportDataType.DATA],
  ARPDAU: [ReportDataType.TOTAL, ReportDataType.DATA],
  ARPDAU_SIMPLE: [ReportDataType.TOTAL, ReportDataType.DATA],
  ARPU: DEFAULT_DATA_TYPES,
  RETURN_RATE: DEFAULT_DATA_TYPES,
  EVENTS_SUMMARY: [ReportDataType.DATA, ReportDataType.TOTAL],
  MEASURES_LITE: DEFAULT_DATA_TYPES,
  TIME_SPENT: DEFAULT_DATA_TYPES,
  COHORT_PER_MIN: DEFAULT_DATA_TYPES,
  CALENDAR_PER_MIN: DEFAULT_DATA_TYPES,
  SPEND: DEFAULT_DATA_TYPES,
  SPEND_MINI: [ReportDataType.TOTAL, ReportDataType.DATA],
  SPEND_HYPER: DEFAULT_DATA_TYPES,
  PROFIT: [ReportDataType.TOTAL, ReportDataType.DATA],
  CALENDAR_CPM: [ReportDataType.DATA],
  METRICS: DEFAULT_DATA_TYPES,
  WATERFALL: [
    ReportDataType.TOTAL,
    ReportDataType.DATA,
    ReportDataType.SSB,
    ReportDataType.SSB_CHART,
  ],
  EVENTS_COST: DEFAULT_DATA_TYPES,
  PAYOUTS: [
    ReportDataType.TOTAL,
    ReportDataType.DATA,
    ReportDataType.MONTH_TOTAL,
  ],
  DIFF_INSTALLS_STORES: [ReportDataType.DATA, ReportDataType.TOTAL],
  CALENDAR_CTR: DEFAULT_DATA_TYPES,
  CASH_COUNTRY: DEFAULT_DATA_TYPES,
  COHORT_CTR: DEFAULT_DATA_TYPES,
  AD_ROAS_COUNTRY: DEFAULT_DATA_TYPES,
  AD_ROAS_NETWORK: DEFAULT_DATA_TYPES,
  CASH_GAMING: DEFAULT_DATA_TYPES,
  COHORT_CPM: DEFAULT_DATA_TYPES,
  SKAD: DEFAULT_DATA_TYPES,
  METRICS_SPEND: DEFAULT_DATA_TYPES,
  FILL_RATE: [ReportDataType.DATA],
  PAYING_USERS_CONVERSION: [ReportDataType.DATA, ReportDataType.TOTAL],
  PRICED_REVENUE: [ReportDataType.DATA, ReportDataType.TOTAL],
  MONETIZATION_MONITORING: [
    ReportDataType.REVENUE_DIFF_PERCENT_DATE_COUNTRY,
    ReportDataType.REVENUE_DIFF_PERCENT_DATE_NETWORK,
    ReportDataType.ARPDAU_DIFF_DATE_COUNTRY,
    ReportDataType.ARPDAU_DIFF_DATE_NETWORK,
    ReportDataType.MONITORING,
  ],
  FIREBASE_SHOW_TO_IMPRESSION: [ReportDataType.DATA],
  FIREBASE_VS_NETWORKS: [ReportDataType.DATA],
  FIREBASE_FILL_RATE: [ReportDataType.DATA],
  TRAFFIC_QUALITY: DEFAULT_DATA_TYPES,
  UA_MAIN_METRICS_OVERVIEW: DEFAULT_DATA_TYPES,
  SUBSCRIPTIONS_OVERVIEW: [ReportDataType.TOTAL, ReportDataType.SUB_TOTAL],
  METRICS_CONSTRUCTOR: DEFAULT_DATA_TYPES,
  SUBSCRIPTIONS_OVERVIEW_IOS: [ReportDataType.TOTAL, ReportDataType.SUB_TOTAL],
};

export const REPORT_PREDICT_BY_REPORT_TYPE: Record<ReportType, boolean> = {
  COHORT_ANALYSIS: false,
  REVENUE: false,
  ARPDAU: false,
  ARPDAU_SIMPLE: false,
  ARPU: false,
  RETURN_RATE: false,
  EVENTS_SUMMARY: false,
  MEASURES_LITE: false,
  TIME_SPENT: false,
  COHORT_PER_MIN: false,
  CALENDAR_PER_MIN: false,
  SPEND: false,
  SPEND_MINI: false,
  SPEND_HYPER: false,
  PROFIT: false,
  CALENDAR_CPM: false,
  METRICS: true,
  WATERFALL: false,
  EVENTS_COST: false,
  PAYOUTS: false,
  CALENDAR_CTR: false,
  CASH_COUNTRY: true,
  DIFF_INSTALLS_STORES: false,
  COHORT_CTR: false,
  AD_ROAS_COUNTRY: false,
  AD_ROAS_NETWORK: false,
  CASH_GAMING: false,
  COHORT_CPM: false,
  SKAD: true,
  METRICS_SPEND: false,
  FILL_RATE: false,
  PAYING_USERS_CONVERSION: false,
  PRICED_REVENUE: false,
  MONETIZATION_MONITORING: false,
  FIREBASE_SHOW_TO_IMPRESSION: false,
  FIREBASE_VS_NETWORKS: false,
  FIREBASE_FILL_RATE: false,
  TRAFFIC_QUALITY: false,
  UA_MAIN_METRICS_OVERVIEW: false,
  SUBSCRIPTIONS_OVERVIEW: false,
  METRICS_CONSTRUCTOR: false,
  SUBSCRIPTIONS_OVERVIEW_IOS: false,
};

export enum REPORT_VISUALIZATION_TYPE {
  TABLE = "TABLE",
  CHART = "CHART",
}

export class ReportVisualizationModel {
  constructor(
    public vizualizations: Array<ReportVisualizationItemModel>,
    public reportDataType: ReportDataType,
    public hasTabs = false,
    public hasTotal = false,
    public name?: string,
    public collapsed = false
  ) {}

  get tables(): Array<ReportVisualizationItemModel> {
    return this.vizualizations.filter(
      ({ type }) => type === REPORT_VISUALIZATION_TYPE.TABLE
    );
  }

  get charts(): Array<ReportVisualizationItemModel> {
    return this.vizualizations.filter(
      ({ type }) => type === REPORT_VISUALIZATION_TYPE.CHART
    );
  }
}

export class ReportVisualizationItemModel {
  constructor(public type = REPORT_VISUALIZATION_TYPE.TABLE) {}
}

export class ReportVisualizationChartModel extends ReportVisualizationItemModel {
  constructor(public chartName: ChartName, public hasTitle = true) {
    super(REPORT_VISUALIZATION_TYPE.CHART);
  }
}

export enum GradientType {
  NONE = "NONE",
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
}
