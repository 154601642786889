import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosResponse } from "axios";

import axios from "@/shared/plugins/axios";
import {
  Application,
  Dictionary,
  PlatformApplicationsResponse,
  DefaultValues,
  MULTI_APP,
} from "@/shared/models";

@Module
export default class ApplicationStore extends VuexModule {
  apps: Array<Application> = [];
  platforms: Array<Dictionary> = [];
  applicationId: string | null = null;
  loadingApps = false;
  routeName!: string;
  appName = "";
  isEdgeNodeStateReseting = false;

  get applicationsById(): Map<string, Application> {
    return new Map(this.apps.map((it) => [it.id, it]));
  }

  get getDefaultApplication() {
    const customDefaultAppId =
      this.context.rootState.accountSettingsStore.accountSettings
        ?.defaultApplication;
    const customApp = customDefaultAppId
      ? this.apps.find(({ value }) => value === customDefaultAppId)
      : null;

    if (customApp) {
      return customApp;
    }

    const defaultApp = this.apps.find(
      ({ value }) => value === DefaultValues.APP
    );

    if (defaultApp) {
      return defaultApp;
    }

    const defaultPlatform = this.apps.find(
      (it) => it.platformType === DefaultValues.PLATFORM
    );

    return defaultPlatform ? defaultPlatform : this.apps[0];
  }

  get applicationIds(): string {
    return this.apps.map((app: Application) => app.id).join(",");
  }

  get isMultiAppMode(): boolean {
    return this.applicationId === MULTI_APP;
  }

  @Mutation
  setApplicationId(applicationId: string) {
    this.applicationId = applicationId;
    this.appName =
      this.apps.find(({ id }) => id === this.applicationId)?.name ?? "";
  }

  @Mutation
  setRouteName(routeName: string) {
    this.routeName = routeName;
  }

  @Mutation
  setPlatformsAndApps(payload: PlatformApplicationsResponse) {
    this.platforms = Dictionary.ofArray(payload.platforms);
    this.apps = Application.ofArray(payload.apps);

    if (this.applicationId && !this.appName) {
      this.appName =
        this.apps.find(({ id }) => id === this.applicationId)?.name ?? "";
    }
  }

  @Mutation
  setLoadingApps(payload: boolean) {
    this.loadingApps = payload;
  }

  @Mutation
  setEdgeNodeStateReseting(payload: boolean) {
    this.isEdgeNodeStateReseting = payload;
  }

  @Action({ commit: "setPlatformsAndApps" })
  async loadPlatformsAndApps() {
    this.context.commit("setLoadingApps", true);

    return axios
      .get("/api/v2/dictionaries/platformsAndApps")
      .then(({ data }: AxiosResponse<PlatformApplicationsResponse>) => data)
      .finally(() => {
        this.context.commit("setLoadingApps", false);
      });
  }

  @Action
  async resetEdgeNodeState() {
    this.context.commit("setEdgeNodeStateReseting", true);

    return axios
      .post(`/api/ab-tests/app/${this.applicationId}/edge-nodes`)
      .finally(() => {
        this.context.commit("setEdgeNodeStateReseting", false);
      });
  }
}
