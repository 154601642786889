import { LangService } from "@/shared/types/LangType";
import {
  ChartName,
  ChartOptions,
  XYSeries,
  XYSeriesType,
  XAxisType,
  XYChartOptions,
  SlicedChartOptions,
  AbTestChartOptions,
  AbTestSeriesType,
} from "@/chart/models/ChartModel";
import XYChartRenderer from "@/chart/models/XYChartRenderer";
import SlicedChartRenderer from "@/chart/models/SlicedChartRenderer";
import { AggregationPeriod, ReportResultItem } from "@/reports/models";
import {
  FunnelChartData,
  FunnelChartPercentMode,
} from "@/funnels/models/FunnelChartModel";
import AbTestChartRenderer from "@/chart/models/AbTestChartRenderer";
import PieChartRenderer from "@/chart/models/PieChartRenderer";
import {
  calculateFirstStepPercent,
  calculateRelativePercent,
  calculateTotalPercent,
} from "@/funnels/utils/FunnelUtil";
import AlertResponseModel from "@/alerts-system/models/AlertResponseModel";
import { uniq } from "lodash";
import { FilterId } from "@/shared/models";
import {
  DashboardChart,
  DashboardChartType,
} from "@/dashboard/models/DashboardChartModel";
import { AbTestPeriodValidationStatus } from "@/ab-tests/models/AbTestConfigurationModel";

export const getChartRenderer = (
  name: ChartName,
  element: HTMLElement,
  data: Array<Record<string, any>>,
  isDarkMode: boolean,
  lang: LangService,
  alerts: Array<AlertResponseModel>,
  horizontal?: boolean,
  aggregationPeriod?: AggregationPeriod,
  dateTo?: string,
  isPositiveAndNegativeBg?: boolean,
  periodValidationStatus?: AbTestPeriodValidationStatus
) => {
  const options: ChartOptions = getChartOptions(name, lang, data);

  switch (name) {
    case ChartName.REPEATED_CONVERSION_FUNNEL_CHART:
    case ChartName.FUNNEL_CHART:
      return new SlicedChartRenderer(
        element,
        data,
        options as SlicedChartOptions,
        isDarkMode,
        horizontal
      );
    case ChartName.AB_TEST_ARPU_CHART:
    case ChartName.AB_TEST_ARPU_PREV_VALIDATION_CHART:
    case ChartName.AB_TEST_RETENTION_RATE_CHART:
    case ChartName.AB_TEST_RETENTION_RATE_PREV_VALIDATION_CHART:
    case ChartName.AB_TEST_TIME_SPENT_CHART:
    case ChartName.AB_TEST_TIME_SPENT_PREV_VALIDATION_CHART:
      return new AbTestChartRenderer(
        element,
        data,
        options as AbTestChartOptions,
        isDarkMode,
        periodValidationStatus
      );
    case ChartName.SOV_CHART:
    case ChartName.SOW_CHART:
      return new PieChartRenderer(
        element,
        data,
        options as SlicedChartOptions,
        isDarkMode
      );
    default:
      return new XYChartRenderer(
        element,
        data,
        options as XYChartOptions,
        isDarkMode,
        alerts,
        aggregationPeriod,
        dateTo,
        isPositiveAndNegativeBg
      );
  }
};

export const getChartOptions = (
  chartName: ChartName,
  lang: LangService,
  data: Array<Record<string, any>>
): ChartOptions => {
  const REVENUE_REPORT_SERIES: Array<XYSeries> = [
    {
      name: lang("chart.label.iapRevenue"),
      y: "inApps",
      x: "date",
      type: XYSeriesType.LINE,
    },
    {
      name: lang("chart.label.rewarded"),
      y: "rewarded",
      x: "date",
      type: XYSeriesType.LINE,
    },
    {
      name: lang("chart.label.subscription"),
      y: "subscription",
      x: "date",
      type: XYSeriesType.LINE,
    },
    {
      name: lang("chart.label.interstitial"),
      y: "interstitial",
      x: "date",
      type: XYSeriesType.LINE,
    },
    {
      name: lang("chart.label.banner"),
      y: "banner",
      x: "date",
      type: XYSeriesType.LINE,
    },
  ];
  const generateCohortSeries = (
    days: Array<number>
  ): Array<Record<string, any>> =>
    days.reduce((series: Array<Record<string, any>>, day: number) => {
      if (data[day] !== undefined) {
        const dayString = day.toString();
        let name;

        if (
          dayString.endsWith("11") ||
          dayString.endsWith("12") ||
          dayString.endsWith("13")
        ) {
          name = lang(`chart.label.thDay`, dayString);
        } else if (dayString.endsWith("1")) {
          name = lang(`chart.label.stDay`, dayString);
        } else if (dayString.endsWith("2")) {
          name = lang(`chart.label.ndDay`, dayString);
        } else if (dayString.endsWith("3")) {
          name = lang(`chart.label.rdDay`, dayString);
        } else {
          name = lang(`chart.label.thDay`, dayString);
        }

        series.push({
          name,
          y: dayString,
          x: "date",
          type: XYSeriesType.LINE,
        });
      }

      return series;
    }, []);
  const COHORT_REPORT_OPTIONS = {
    xAxis: {
      type: XAxisType.DATE,
    },
    yAxes: [
      {
        series: generateCohortSeries([1, 7, 14, 30, 60, 90, 180, 360]),
      },
    ],
    hasAdditionalBottomChart: true,
    legend: true,
  };
  const AD_ROAS_CHART_OPTIONS = {
    xAxis: {
      type: XAxisType.DATE,
    },
    yAxes: [
      {
        series: generateCohortSeries([
          0, 1, 3, 7, 14, 30, 90, 120, 150, 180, 210, 240, 270, 300, 330, 364,
        ]),
      },
    ],
    hasAdditionalBottomChart: true,
    legend: true,
  };
  const generateGroupedDateSeries = (postfix = "") => {
    return {
      xAxis: {
        type: XAxisType.DATE,
      },
      yAxes: [
        {
          series: [
            ...uniq(
              data
                .map((item) =>
                  Object.keys(item).filter((key) => key !== "date")
                )
                .flat()
            ).map((item) => ({
              name: item,
              y: item,
              x: "date",
              tooltipText: `{name}: [bold]{valueY}${postfix}[/]`,
              type: XYSeriesType.LINE,
            })),
            {
              name: lang("chart.label.toggleAll"),
              type: XYSeriesType.LINE,
            },
          ],
        },
      ],
      legend: true,
      scrollbar: true,
      hasToggle: true,
    };
  };
  const generateCohortTotalSeries = (yAxisTitle: string) => ({
    xAxis: {
      type: XAxisType.CATEGORY,
      category: "day",
      title: lang("chart.label.day"),
    },
    yAxes: [
      {
        series: [
          {
            name: "value",
            y: "value",
            x: "day",
            tooltipText: `[bold]{valueY}[/]`,
            type: XYSeriesType.LINE,
          },
        ],
        title: lang(`chart.label.${yAxisTitle}`),
      },
    ],
  });
  const generateCohortSubTotalSeries = (yAxisTitle: string) => ({
    xAxis: {
      type: XAxisType.CATEGORY,
      category: "day",
      title: lang("chart.label.day"),
    },
    yAxes: [
      {
        series: [
          ...Object.keys(data[0]).reduce(
            (res: Array<XYSeries>, key: string) => [
              ...res,
              ...(key === "day"
                ? []
                : [
                    {
                      name: key,
                      y: key,
                      x: "day",
                      tooltipText: `{name}: [bold]{valueY}[/]`,
                      type: XYSeriesType.LINE,
                    },
                  ]),
            ],
            []
          ),
          {
            name: lang("chart.label.toggleAll"),
            type: XYSeriesType.LINE,
          },
        ],
        title: lang(`chart.label.${yAxisTitle}`),
      },
    ],
    legend: true,
    hasToggle: true,
  });

  return (
    {
      [ChartName.REVENUE_DIFF_PERCENT_DATE_COUNTRY_BASED_DIFF_REVENUE_DAY]:
        () => generateGroupedDateSeries("%"),
      [ChartName.REVENUE_DIFF_PERCENT_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_DAY]:
        () => generateGroupedDateSeries("%"),
      [ChartName.REVENUE_DIFF_PERCENT_DATE_NETWORK_BASED_DIFF_REVENUE_DAY]:
        () => generateGroupedDateSeries("%"),
      [ChartName.REVENUE_DIFF_PERCENT_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_DAY]:
        () => generateGroupedDateSeries("%"),
      [ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_REVENUE_PREV_DAY]: () =>
        generateGroupedDateSeries("%"),
      [ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_PREV_DAY]:
        () => generateGroupedDateSeries("%"),
      [ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_REVENUE_PREV_YEAR]: () =>
        generateGroupedDateSeries("%"),
      [ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_PREV_YEAR]:
        () => generateGroupedDateSeries("%"),
      [ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_REVENUE_PREV_DAY]: () =>
        generateGroupedDateSeries("%"),
      [ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_PREV_DAY]:
        () => generateGroupedDateSeries("%"),
      [ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_REVENUE_PREV_YEAR]: () =>
        generateGroupedDateSeries("%"),
      [ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_PREV_YEAR]:
        () => generateGroupedDateSeries("%"),
      [ChartName.DAU_DYNAMICS]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            title: lang("chart.label.usersCount"),
            series: [
              {
                name: lang("chart.label.activeUsers"),
                y: "activeUsers",
                x: "date",
                stacked: true,
                type: XYSeriesType.COLUMN,
              },
              {
                name: lang("chart.label.installed"),
                y: "installed",
                x: "date",
                stacked: true,
                type: XYSeriesType.COLUMN,
              },
              {
                name: lang("chart.label.reattributed"),
                y: "reattributed",
                x: "date",
                stacked: true,
                type: XYSeriesType.COLUMN,
              },
            ],
          },
        ],
        legend: true,
        scrollbar: true,
      }),
      [ChartName.REVENUE_SPENDINGS_DAILY_DYNAMICS]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            title: lang("chart.label.valueUsd"),
            series: [
              {
                name: lang("chart.label.spendUsd"),
                y: "spend",
                x: "date",
                stacked: true,
                type: XYSeriesType.COLUMN,
              },
              {
                name: lang("chart.label.inAppRevenueUsd"),
                y: "inAppRevenue",
                x: "date",
                stacked: true,
                type: XYSeriesType.COLUMN,
              },
              {
                name: lang("chart.label.subscriptionRevenueUsd"),
                y: "subscriptionRevenue",
                x: "date",
                stacked: true,
                type: XYSeriesType.COLUMN,
              },
              {
                name: lang("chart.label.adRevenueUsd"),
                y: "adRevenue",
                x: "date",
                stacked: true,
                type: XYSeriesType.COLUMN,
              },
            ],
          },
        ],
        legend: true,
        scrollbar: true,
      }),
      [ChartName.CUMULATED_REVENUE_SPENDINGS_PROFIT]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            title: lang("chart.label.valueUsd"),
            series: [
              {
                name: lang("chart.label.spendUsd"),
                y: "spend",
                x: "date",
                stacked: true,
                type: XYSeriesType.COLUMN,
              },
              {
                name: lang("chart.label.inAppRevenueUsd"),
                y: "inAppRevenue",
                x: "date",
                stacked: true,
                type: XYSeriesType.COLUMN,
              },
              {
                name: lang("chart.label.subscriptionRevenueUsd"),
                y: "subscriptionRevenue",
                x: "date",
                stacked: true,
                type: XYSeriesType.COLUMN,
              },
              {
                name: lang("chart.label.adRevenueUsd"),
                y: "adRevenue",
                x: "date",
                stacked: true,
                type: XYSeriesType.COLUMN,
              },
              {
                name: lang("chart.label.profitUsd"),
                y: "profit",
                x: "date",
                type: XYSeriesType.LINE,
                color: 0x00aa00,
                strokeWidth: 2.5,
                range: {
                  color: 0xff0000,
                  endValue: 0,
                },
              },
            ],
          },
        ],
        legend: true,
        scrollbar: true,
      }),
      [ChartName.CUMULATED_ARPU_STRUCTURE]: () => ({
        xAxis: {
          title: lang("chart.label.daysAfterInstall"),
          type: XAxisType.CATEGORY,
          category: "day",
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.adArpuPercent"),
                y: "adArpuPercent",
                x: "day",
                stacked: true,
                type: XYSeriesType.LINE,
                tooltipText: lang("chart.tooltip.adArpuPercent"),
              },
              {
                name: lang("chart.label.inAppArpuPercent"),
                y: "inAppArpuPercent",
                x: "day",
                stacked: true,
                type: XYSeriesType.LINE,
                tooltipText: lang("chart.tooltip.inAppArpuPercent"),
              },
              {
                name: lang("chart.label.subscriptionArpuPercent"),
                y: "subscriptionArpuPercent",
                x: "day",
                stacked: true,
                type: XYSeriesType.LINE,
                tooltipText: lang("chart.tooltip.subscriptionArpuPercent"),
              },
            ],
            numberFormat: "#'%'",
            min: 0,
          },
        ],
        legend: true,
        scrollbar: true,
      }),
      [ChartName.CUMULATED_ARPU]: () => ({
        xAxis: {
          title: lang("chart.label.daysAfterInstall"),
          type: XAxisType.CATEGORY,
          category: "day",
        },
        yAxes: [
          {
            title: lang("chart.label.valueUsd"),
            series: [
              {
                name: lang("chart.label.cumArpuUsd"),
                y: "cumArpu",
                x: "day",
                type: XYSeriesType.COLUMN,
                tooltipText: lang("chart.tooltip.cumArpu"),
                hasUnfilledData: true,
              },
            ],
            rangeAxes: [
              {
                value: "eCpi",
                color: 0x000000,
                label: lang("chart.label.eCpiUsd"),
              },
            ],
            min: 0,
          },
        ],
        legend: true,
        scrollbar: true,
      }),
      [ChartName.ROAS_N_DAY]: () => ({
        xAxis: {
          title: lang("chart.label.daysAfterInstall"),
          type: XAxisType.CATEGORY,
          category: "day",
        },
        yAxes: [
          {
            title: lang("chart.label.roas"),
            series: [
              {
                name: lang("chart.label.roas"),
                y: "roi",
                x: "day",
                type: XYSeriesType.COLUMN,
                tooltipText: lang("chart.tooltip.roas"),
                hasUnfilledData: true,
              },
            ],
            rangeAxes: [
              {
                value: "paybackThreshold",
                color: 0x000000,
                label: lang("chart.label.paybackThreshold"),
              },
            ],
            min: 0,
          },
        ],
        legend: true,
        scrollbar: true,
      }),
      [ChartName.RETENTION_RATE]: () => ({
        xAxis: {
          title: lang("chart.label.daysAfterInstall"),
          type: XAxisType.CATEGORY,
          category: "day",
        },
        yAxes: [
          {
            title: lang("chart.label.retentionRate"),
            series: [
              {
                name: lang("chart.label.retentionRate"),
                y: "retentionRate",
                x: "day",
                type: XYSeriesType.COLUMN,
                tooltipText: lang("chart.tooltip.retentionRate"),
                hasUnfilledData: true,
              },
            ],
            numberFormat: "#'%'",
            min: 0,
          },
        ],
        legend: true,
        scrollbar: true,
      }),
      [ChartName.CUMULATED_PLAYTIME_PER_ACTIVE_USER]: () => ({
        xAxis: {
          title: lang("chart.label.daysAfterInstall"),
          type: XAxisType.CATEGORY,
          category: "day",
        },
        yAxes: [
          {
            title: lang("chart.label.playtimeMinutes"),
            series: [
              {
                name: lang("chart.label.playtime"),
                y: "playtime",
                x: "day",
                type: XYSeriesType.COLUMN,
                tooltipText: lang("chart.tooltip.playtime"),
                hasUnfilledData: true,
              },
            ],
            min: 0,
          },
        ],
        legend: true,
        scrollbar: true,
      }),
      [ChartName.REVENUE_REPORT_LINEAR_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: REVENUE_REPORT_SERIES,
          },
        ],
        legend: true,
      }),
      [ChartName.REVENUE_REPORT_IAP_REVENUE_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.REVENUE_REPORT_REWARDED_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.REVENUE_REPORT_INTERSTITIAL_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.REVENUE_REPORT_BANNER_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.REVENUE_REPORT_STACKED_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              ...REVENUE_REPORT_SERIES.map((series: XYSeries) => ({
                ...series,
                stacked: true,
              })),
              {
                name: lang("chart.label.total"),
                y: "total",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.ARPDAU_REPORT_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.arpdau"),
                y: "arpdau",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.arpdauIap"),
                y: "arpdauIap",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.subscription"),
                y: "subscription",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.banner"),
                y: "banner",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.interstitial"),
                y: "interstitial",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.rewarded"),
                y: "rewarded",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.ARPDAU_ARPDAU_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPDAU_ARPDAU_IAP_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPDAU_SUBSCRIPTION_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPDAU_BANNER_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPDAU_INTERSTITIAL_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPDAU_REWARDED_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPDAU_ALL_USERS_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.arpdau"),
                y: "arpdau",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.arpdauIap"),
                y: "arpdauIap",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.subscription"),
                y: "subscription",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.banner"),
                y: "banner",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.interstitial"),
                y: "interstitial",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.rewarded"),
                y: "rewarded",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.ARPDAU_SIMPLE_ALL_USERS_ARPDAU_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.ARPDAU_SIMPLE_ALL_USERS_ARPDAU_IAP_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.ARPDAU_SIMPLE_ALL_USERS_BANNER_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.ARPDAU_SIMPLE_ALL_USERS_INTERSTITIAL_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.ARPDAU_SIMPLE_ALL_USERS_REWARDED_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.ARPDAU_ACTIVE_USERS_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.arpdau_e"),
                y: "arpdau_e",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.banner_e"),
                y: "banner_e",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.interstitial_e"),
                y: "interstitial_e",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.rewarded_e"),
                y: "rewarded_e",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.ARPDAU_SIMPLE_ACTIVE_USERS_ARPDAU_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.ARPDAU_SIMPLE_ACTIVE_USERS_BANNER_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.ARPDAU_SIMPLE_ACTIVE_USERS_INTERSTITIAL_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.ARPDAU_SIMPLE_ACTIVE_USERS_REWARDED_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.CALENDAR_PERMIN_REPORT_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.eventsBanner"),
                y: "eventsBanner",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: lang("chart.label.perMinBanner"),
                y: "perMinBanner",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.CALENDAR_PERMIN_REPORT_EVENTS_BANNER_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.CALENDAR_PERMIN_REPORT_PERMIN_BANNER_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.EVENTS_SUMMARY_REPORT_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.totalOccurrences"),
                y: "totalOccurrences",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: lang("chart.label.avgEventsPerSession"),
                y: "avgEventsPerSession",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: lang("chart.label.uniqueDevicesDailyAvg"),
                y: "uniqueDevicesDailyAvg",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: lang("chart.label.occurrencesDailyAvg"),
                y: "occurrencesDailyAvg",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.EVENTS_SUMMARY_TOTAL_OCCURRENCES_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.EVENTS_SUMMARY_AVG_EVENTS_PER_SESSION_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.EVENTS_SUMMARY_AVG_EVENTS_PER_DEVICE_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.EVENTS_SUMMARY_UNIQUE_DEVICES_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TOTAL_OCCURRENCES_CHART]: () => ({
        xAxis: {
          title: lang("chart.label.events"),
          type: XAxisType.CATEGORY,
          category: "eventName",
          hiddenLabels: true,
        },
        yAxes: [
          {
            title: lang("chart.label.totalOccurrences"),
            series: [
              {
                name: lang("chart.label.totalOccurrences"),
                y: "totalOccurrences",
                x: "eventName",
                type: XYSeriesType.COLUMN,
                tooltipText: lang("chart.tooltip.totalOccurrences"),
              },
            ],
            min: 0,
          },
        ],
        legend: false,
        scrollbar: true,
        sortDataBy: "totalOccurrences",
      }),
      [ChartName.AVG_EVENTS_PER_SESSION_CHART]: () => ({
        xAxis: {
          title: lang("chart.label.events"),
          type: XAxisType.CATEGORY,
          category: "eventName",
          hiddenLabels: true,
        },
        yAxes: [
          {
            title: lang("chart.label.avgEventsPerSession"),
            series: [
              {
                name: lang("chart.label.avgEventsPerSession"),
                y: "avgEventsPerSession",
                x: "eventName",
                type: XYSeriesType.COLUMN,
                tooltipText: lang("chart.tooltip.avgEventsPerSession"),
              },
            ],
            min: 0,
          },
        ],
        legend: false,
        scrollbar: true,
        sortDataBy: "avgEventsPerSession",
      }),
      [ChartName.AVG_EVENTS_PER_DEVICE_CHART]: () => ({
        xAxis: {
          title: lang("chart.label.events"),
          type: XAxisType.CATEGORY,
          category: "eventName",
          hiddenLabels: true,
        },
        yAxes: [
          {
            title: lang("chart.label.occurrencesDailyAvg"),
            series: [
              {
                name: lang("chart.label.occurrencesDailyAvg"),
                y: "occurrencesDailyAvg",
                x: "eventName",
                type: XYSeriesType.COLUMN,
                tooltipText: lang("chart.tooltip.occurrencesDailyAvg"),
              },
            ],
            min: 0,
          },
        ],
        legend: false,
        scrollbar: true,
        sortDataBy: "occurrencesDailyAvg",
      }),
      [ChartName.UNIQUE_DEVICES_CHART]: () => ({
        xAxis: {
          title: lang("chart.label.events"),
          type: XAxisType.CATEGORY,
          category: "eventName",
          hiddenLabels: true,
        },
        yAxes: [
          {
            title: lang("chart.label.uniqueDevicesDailyAvg"),
            series: [
              {
                name: lang("chart.label.uniqueDevicesDailyAvg"),
                y: "uniqueDevicesDailyAvg",
                x: "eventName",
                type: XYSeriesType.COLUMN,
                tooltipText: lang("chart.tooltip.uniqueDevicesDailyAvg"),
              },
            ],
            min: 0,
          },
        ],
        legend: false,
        scrollbar: true,
        sortDataBy: "uniqueDevicesDailyAvg",
      }),
      [ChartName.PROFIT_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.spend"),
                y: "spend",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.revenue"),
                y: "revenue",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.profit"),
                y: "profit",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: lang("chart.label.dau"),
                y: "dau",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.CPM_REPORT_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.bannerCPM"),
                y: "bannerCPM",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.interstitialCPM"),
                y: "interstitialCPM",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.rewardedCPM"),
                y: "rewardedCPM",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.CPM_REPORT_BANNER_CHART]: () => generateGroupedDateSeries(),
      [ChartName.CPM_REPORT_INTERSTITIAL_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.CPM_REPORT_REWARDED_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPU_REPORT_CHART]: () => COHORT_REPORT_OPTIONS,
      [ChartName.ARPU_REPORT_DAY_1_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPU_REPORT_DAY_7_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPU_REPORT_DAY_14_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPU_REPORT_TOTAL_CHART]: () =>
        generateCohortTotalSeries("arpu"),
      [ChartName.ARPU_REPORT_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("arpu"),
      [ChartName.RETURN_RATE_REPORT_CHART]: () => COHORT_REPORT_OPTIONS,
      [ChartName.RETURN_RATE_REPORT_DAY_1_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.RETURN_RATE_REPORT_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.RETURN_RATE_REPORT_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.RETURN_RATE_REPORT_TOTAL_CHART]: () =>
        generateCohortTotalSeries("returnRate"),
      [ChartName.RETURN_RATE_REPORT_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("returnRate"),
      [ChartName.MEASURES_LITE_REPORT_CHART]: () => COHORT_REPORT_OPTIONS,
      [ChartName.MEASURES_LITE_REPORT_DAY_1_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.MEASURES_LITE_REPORT_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.MEASURES_LITE_REPORT_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.MEASURES_LITE_REPORT_TOTAL_CHART]: () =>
        generateCohortTotalSeries("measuresLite"),
      [ChartName.MEASURES_LITE_REPORT_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("measuresLite"),
      [ChartName.TIME_SPENT_REPORT_CHART]: () => COHORT_REPORT_OPTIONS,
      [ChartName.TIME_SPENT_REPORT_DAY_1_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TIME_SPENT_REPORT_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TIME_SPENT_REPORT_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TIME_SPENT_REPORT_TOTAL_CHART]: () =>
        generateCohortTotalSeries("timeSpent"),
      [ChartName.TIME_SPENT_REPORT_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("timeSpent"),
      [ChartName.COHORT_PERMIN_REPORT_CHART]: () => COHORT_REPORT_OPTIONS,
      [ChartName.COHORT_PERMIN_REPORT_DAY_1_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.COHORT_PERMIN_REPORT_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.COHORT_PERMIN_REPORT_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.RETURN_RATE_METRICS_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.rdDay", 3),
                y: "returnRate_3",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 7),
                y: "returnRate_7",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 14),
                y: "returnRate_14",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.stDay", 21),
                y: "returnRate_21",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 30),
                y: "returnRate_30",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        hasAdditionalBottomChart: true,
        legend: true,
      }),
      [ChartName.RETURN_RATE_METRICS_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("returnRate"),
      [ChartName.METRICS_TOTAL_CHART]: () => ({
        xAxis: {
          type: XAxisType.CATEGORY,
          category: "day",
          title: lang("chart.label.day"),
        },
        yAxes: [
          {
            series: [
              {
                name: "returnRate",
                y: "returnRate",
                x: "day",
                tooltipText: `{name}: [bold]{valueY}[/]`,
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: "timeSpent",
                y: "timeSpent",
                x: "day",
                tooltipText: `{name}: [bold]{valueY}[/]`,
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: "arpu",
                y: "arpu",
                x: "day",
                tooltipText: `{name}: [bold]{valueY}[/]`,
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.RETURN_RATE_METRICS_DAY_3_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.RETURN_RATE_METRICS_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.RETURN_RATE_METRICS_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.RETURN_RATE_METRICS_DAY_21_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.RETURN_RATE_METRICS_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TIME_SPENT_METRICS_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.rdDay", 3),
                y: "timeSpent_3",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 7),
                y: "timeSpent_7",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 14),
                y: "timeSpent_14",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.stDay", 21),
                y: "timeSpent_21",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 30),
                y: "timeSpent_30",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        hasAdditionalBottomChart: true,
        legend: true,
      }),
      [ChartName.TIME_SPENT_METRICS_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("timeSpent"),
      [ChartName.TIME_SPENT_METRICS_DAY_3_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TIME_SPENT_METRICS_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TIME_SPENT_METRICS_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TIME_SPENT_METRICS_DAY_21_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TIME_SPENT_METRICS_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.ARPU_METRICS_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.rdDay", 3),
                y: "arpu_3",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 7),
                y: "arpu_7",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 14),
                y: "arpu_14",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.stDay", 21),
                y: "arpu_21",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 30),
                y: "arpu_30",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        hasAdditionalBottomChart: true,
        legend: true,
      }),
      [ChartName.ARPU_METRICS_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("arpu"),
      [ChartName.ARPU_METRICS_DAY_3_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPU_METRICS_DAY_7_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPU_METRICS_DAY_14_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPU_METRICS_DAY_21_CHART]: () => generateGroupedDateSeries(),
      [ChartName.ARPU_METRICS_DAY_30_CHART]: () => generateGroupedDateSeries(),
      [ChartName.FUNNEL_CHART]: () => ({
        series: {
          value: "value",
          category: "name",
          legendLabelText: "{name}",
          legendValueText: "{percent}%",
          labelText: "{name}: [bold]{percent}%[/]",
          tooltipText: "{name}: [bold]{percent}% ({value})[/]",
        },
        legend: true,
      }),
      [ChartName.FUNNEL_COLUMN_CHART]: () => ({
        xAxis: {
          type: XAxisType.CATEGORY,
          category: "name",
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.installs"),
                y: "value",
                x: "name",
                type: XYSeriesType.COLUMN,
                labelText: "[bold]{percent}%[/] ({value})",
                tooltipText: "{name}: [bold]{percent}%[/] ({value})",
              },
            ],
            min: 0,
          },
        ],
        legend: false,
      }),
      [ChartName.REPEATED_CONVERSION_FUNNEL_CHART]: () => ({
        series: {
          value: "value",
          category: "name",
          legendLabelText: "{name}",
          legendValueText: "{value}",
          labelText: "{name}: [bold]{value}[/]",
          tooltipText: "{name}: [bold]{value}[/]",
        },
        legend: true,
      }),
      [ChartName.REPEATED_CONVERSION_FUNNEL_COLUMN_CHART]: () => ({
        xAxis: {
          type: XAxisType.CATEGORY,
          category: "name",
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.installs"),
                y: "value",
                x: "name",
                type: XYSeriesType.COLUMN,
                labelText: "[bold]{value}[/]",
                tooltipText: "{name}: [bold]{value}[/]",
              },
            ],
            min: 0,
          },
        ],
        legend: false,
      }),
      [ChartName.COLUMN_CHART]: () => ({
        xAxis: {
          type: XAxisType.CATEGORY,
          category: "name",
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.installs"),
                y: "value",
                x: "name",
                type: XYSeriesType.COLUMN,
                tooltipText: "{name}: [bold]{percent}%[/] ({value})",
              },
            ],
            min: 0,
          },
        ],
        legend: false,
      }),
      [ChartName.AB_TEST_ARPU_CHART]: () => ({
        xAxis: {
          title: lang("chart.label.day"),
          category: "day",
        },
        yAxis: {
          title: lang("chart.label.arpuUsd"),
        },
        series: [
          {
            type: AbTestSeriesType.CONFIDENCE_INTERVAL,
            name: lang("chart.label.confidenceInterval"),
            x: "day",
            y: "upper",
            yOpen: "lower",
          },
          {
            type: AbTestSeriesType.CONTROL_GROUP,
            name: lang("chart.label.controlGroup"),
            x: "day",
            y: "cg",
            tooltipText: lang("chart.tooltip.controlGroup", "$"),
          },
          {
            type: AbTestSeriesType.TARGET_GROUP,
            name: lang("chart.label.targetGroup"),
            x: "day",
            y: "tg",
            tooltipText: lang("chart.tooltip.targetGroup", "$"),
            label: "relEffect",
          },
        ],
        legend: true,
      }),
      [ChartName.AB_TEST_RETENTION_RATE_CHART]: () => ({
        xAxis: {
          title: lang("chart.label.day"),
          category: "day",
        },
        yAxis: {
          title: lang("chart.label.retentionRatePercent"),
        },
        series: [
          {
            type: AbTestSeriesType.CONFIDENCE_INTERVAL,
            name: lang("chart.label.confidenceInterval"),
            x: "day",
            y: "upper",
            yOpen: "lower",
          },
          {
            type: AbTestSeriesType.CONTROL_GROUP,
            name: lang("chart.label.controlGroup"),
            x: "day",
            y: "cg",
            tooltipText: lang("chart.tooltip.controlGroup", "%"),
          },
          {
            type: AbTestSeriesType.TARGET_GROUP,
            name: lang("chart.label.targetGroup"),
            x: "day",
            y: "tg",
            tooltipText: lang("chart.tooltip.targetGroup", "%"),
            label: "relEffect",
          },
        ],
        legend: true,
      }),
      [ChartName.AB_TEST_TIME_SPENT_CHART]: () => ({
        xAxis: {
          title: lang("chart.label.day"),
          category: "day",
        },
        yAxis: {
          title: lang("chart.label.timeSpentMinutes"),
        },
        series: [
          {
            type: AbTestSeriesType.CONFIDENCE_INTERVAL,
            name: lang("chart.label.confidenceInterval"),
            x: "day",
            y: "upper",
            yOpen: "lower",
          },
          {
            type: AbTestSeriesType.CONTROL_GROUP,
            name: lang("chart.label.controlGroup"),
            x: "day",
            y: "cg",
            tooltipText: lang("chart.tooltip.controlGroup", " min"),
          },
          {
            type: AbTestSeriesType.TARGET_GROUP,
            name: lang("chart.label.targetGroup"),
            x: "day",
            y: "tg",
            tooltipText: lang("chart.tooltip.targetGroup", " min"),
            label: "relEffect",
          },
        ],
        legend: true,
      }),
      [ChartName.AB_TEST_ARPU_PREV_VALIDATION_CHART]: () => ({
        xAxis: {
          title: lang("chart.label.day"),
          category: "day",
          validationRange: true,
        },
        yAxis: {
          title: lang("chart.label.arpuUsd"),
        },
        series: [
          {
            type: AbTestSeriesType.CONTROL_GROUP,
            name: lang("chart.label.controlGroup"),
            x: "day",
            y: "cg",
            tooltipText: lang("chart.tooltip.controlGroup", "$"),
          },
          {
            type: AbTestSeriesType.TARGET_GROUP,
            name: lang("chart.label.targetGroup"),
            x: "day",
            y: "tg",
            tooltipText: lang("chart.tooltip.targetGroup", "$"),
          },
        ],
        legend: true,
      }),
      [ChartName.AB_TEST_RETENTION_RATE_PREV_VALIDATION_CHART]: () => ({
        xAxis: {
          title: lang("chart.label.day"),
          category: "day",
          validationRange: true,
        },
        yAxis: {
          title: lang("chart.label.retentionRatePercent"),
        },
        series: [
          {
            type: AbTestSeriesType.CONTROL_GROUP,
            name: lang("chart.label.controlGroup"),
            x: "day",
            y: "cg",
            tooltipText: lang("chart.tooltip.controlGroup", "%"),
          },
          {
            type: AbTestSeriesType.TARGET_GROUP,
            name: lang("chart.label.targetGroup"),
            x: "day",
            y: "tg",
            tooltipText: lang("chart.tooltip.targetGroup", "%"),
          },
        ],
        legend: true,
      }),
      [ChartName.AB_TEST_TIME_SPENT_PREV_VALIDATION_CHART]: () => ({
        xAxis: {
          title: lang("chart.label.day"),
          category: "day",
          validationRange: true,
        },
        yAxis: {
          title: lang("chart.label.timeSpentMinutes"),
        },
        series: [
          {
            type: AbTestSeriesType.CONTROL_GROUP,
            name: lang("chart.label.controlGroup"),
            x: "day",
            y: "cg",
            tooltipText: lang("chart.tooltip.controlGroup", " min"),
          },
          {
            type: AbTestSeriesType.TARGET_GROUP,
            name: lang("chart.label.targetGroup"),
            x: "day",
            y: "tg",
            tooltipText: lang("chart.tooltip.targetGroup", " min"),
          },
        ],
        legend: true,
      }),
      [ChartName.CALENDAR_CTR_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.bannerCtr"),
                y: "bannerCtr",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.interstitialCtr"),
                y: "interstitialCtr",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.rewardedCtr"),
                y: "rewardedCtr",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.CALENDAR_CTR_BANNER_CHART]: () => generateGroupedDateSeries(),
      [ChartName.CALENDAR_CTR_INTERSTITIAL_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.CALENDAR_CTR_REWARDED_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.DIFF_INSTALLS_STORES_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.rawData"),
                y: "rawData",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.store"),
                y: "storeData",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.COHORT_CTR_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: generateCohortSeries([3, 7, 14, 30]),
          },
        ],
        hasAdditionalBottomChart: true,
        legend: true,
      }),
      [ChartName.COHORT_CTR_TOTAL_CHART]: () =>
        generateCohortTotalSeries("cohortCtr"),
      [ChartName.COHORT_CTR_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("cohortCtr"),
      [ChartName.COHORT_CTR_DAY_3_CHART]: () => generateGroupedDateSeries(),
      [ChartName.COHORT_CTR_DAY_7_CHART]: () => generateGroupedDateSeries(),
      [ChartName.COHORT_CTR_DAY_14_CHART]: () => generateGroupedDateSeries(),
      [ChartName.COHORT_CTR_DAY_30_CHART]: () => generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_CHART]: () => AD_ROAS_CHART_OPTIONS,
      [ChartName.AD_ROAS_COUNTRY_TOTAL_CHART]: () =>
        generateCohortTotalSeries("adRoasCountry"),
      [ChartName.AD_ROAS_COUNTRY_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("adRoasCountry"),
      [ChartName.AD_ROAS_COUNTRY_DAY_0_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_1_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_3_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_90_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_120_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_150_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_180_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_210_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_240_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_270_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_300_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_330_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_COUNTRY_DAY_364_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_CHART]: () => AD_ROAS_CHART_OPTIONS,
      [ChartName.AD_ROAS_NETWORK_TOTAL_CHART]: () =>
        generateCohortTotalSeries("adRoasNetwork"),
      [ChartName.AD_ROAS_NETWORK_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("adRoasNetwork"),
      [ChartName.AD_ROAS_NETWORK_DAY_0_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_1_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_3_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_90_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_120_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_150_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_180_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_210_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_240_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_270_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_300_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_330_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.AD_ROAS_NETWORK_DAY_364_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.SOV_CHART]: () => ({
        series: {
          value: "impressions",
          category: "name",
          legendLabelText: "{name}:",
          legendValueText: "{percent}%",
          labelText: "{name}: [bold]{percent}%[/]",
          tooltipText: "{name}: [bold]{percent}% ({value})[/]",
        },
        legend: true,
      }),
      [ChartName.SOW_CHART]: () => ({
        series: {
          value: "revenue",
          category: "name",
          legendLabelText: "{name}:",
          legendValueText: "{percent}%",
          labelText: "{name}: [bold]{percent}%[/]",
          tooltipText: "{name}: [bold]{percent}% ({value})[/]",
        },
        legend: true,
      }),
      [ChartName.COHORT_CPM_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: generateCohortSeries([3, 7, 14, 30]),
          },
        ],
        hasAdditionalBottomChart: true,
        legend: true,
      }),
      [ChartName.COHORT_CPM_TOTAL_CHART]: () =>
        generateCohortTotalSeries("cohortCpm"),
      [ChartName.COHORT_CPM_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("cohortCpm"),
      [ChartName.COHORT_CPM_DAY_3_CHART]: () => generateGroupedDateSeries(),
      [ChartName.COHORT_CPM_DAY_7_CHART]: () => generateGroupedDateSeries(),
      [ChartName.COHORT_CPM_DAY_14_CHART]: () => generateGroupedDateSeries(),
      [ChartName.COHORT_CPM_DAY_30_CHART]: () => generateGroupedDateSeries(),
      [ChartName.METRICS_SPEND_TOTAL_CHART]: () => ({
        xAxis: {
          type: XAxisType.CATEGORY,
          category: "day",
          title: lang("chart.label.day"),
        },
        yAxes: [
          {
            series: [
              {
                name: "returnRate",
                y: "returnRate",
                x: "day",
                tooltipText: `{name}: [bold]{valueY}[/]`,
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: "timeSpent",
                y: "timeSpent",
                x: "day",
                tooltipText: `{name}: [bold]{valueY}[/]`,
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: "engagement",
                y: "engagement",
                x: "day",
                tooltipText: `{name}: [bold]{valueY}[/]`,
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: "value",
                y: "value",
                x: "day",
                tooltipText: `{name}: [bold]{valueY}[/]`,
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.METRICS_SPEND_RETURN_RATE_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.rdDay", 3),
                y: "returnRate_3",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 7),
                y: "returnRate_7",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 30),
                y: "returnRate_30",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        hasAdditionalBottomChart: true,
        additionalChartValue: "adjustInstalls",
        legend: true,
      }),
      [ChartName.METRICS_SPEND_RETURN_RATE_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("returnRate"),
      [ChartName.METRICS_SPEND_RETURN_RATE_DAY_3_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.METRICS_SPEND_RETURN_RATE_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.METRICS_SPEND_RETURN_RATE_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.METRICS_SPEND_TIME_SPENT_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.rdDay", 3),
                y: "timeSpent_3",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 7),
                y: "timeSpent_7",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 30),
                y: "timeSpent_30",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        hasAdditionalBottomChart: true,
        additionalChartValue: "adjustInstalls",
        legend: true,
      }),
      [ChartName.METRICS_SPEND_TIME_SPENT_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("timeSpent"),
      [ChartName.METRICS_SPEND_TIME_SPENT_DAY_3_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.METRICS_SPEND_TIME_SPENT_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.METRICS_SPEND_TIME_SPENT_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.METRICS_SPEND_ENGAGEMENT_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.rdDay", 3),
                y: "engagement_3",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 7),
                y: "engagement_7",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 30),
                y: "engagement_30",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        hasAdditionalBottomChart: true,
        additionalChartValue: "adjustInstalls",
        legend: true,
      }),
      [ChartName.METRICS_SPEND_ENGAGEMENT_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("engagement"),
      [ChartName.METRICS_SPEND_ENGAGEMENT_DAY_3_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.METRICS_SPEND_ENGAGEMENT_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.METRICS_SPEND_ENGAGEMENT_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.METRICS_SPEND_VALUE_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.rdDay", 3),
                y: "value_3",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 7),
                y: "value_7",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 30),
                y: "value_30",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        hasAdditionalBottomChart: true,
        additionalChartValue: "adjustInstalls",
        legend: true,
      }),
      [ChartName.METRICS_SPEND_VALUE_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("value"),
      [ChartName.METRICS_SPEND_VALUE_DAY_3_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.METRICS_SPEND_VALUE_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.METRICS_SPEND_VALUE_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.FILL_RATE_BANNER_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.bannerFillRate"),
                y: "bannerFillRate",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.FILL_RATE_BANNER_PER_DAU_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.bannerRequestsEventsPerDau"),
                y: "bannerRequestsEventsPerDau",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.FILL_RATE_INTER_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.interFillRate"),
                y: "interFillRate",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.FILL_RATE_INTER_PER_DAU_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.interRequestsEventsPerDau"),
                y: "interRequestsEventsPerDau",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.FILL_RATE_REWARD_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.rewardFillRate"),
                y: "rewardFillRate",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.FILL_RATE_REWARD_PER_DAU_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.rewardRequestsEventsPerDau"),
                y: "rewardRequestsEventsPerDau",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.FILL_RATE_GROUPED_BANNER_CHART]: () =>
        generateGroupedDateSeries("%"),
      [ChartName.FILL_RATE_GROUPED_BANNER_PER_DAU_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.FILL_RATE_GROUPED_INTER_CHART]: () =>
        generateGroupedDateSeries("%"),
      [ChartName.FILL_RATE_GROUPED_INTER_PER_DAU_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.FILL_RATE_GROUPED_REWARD_CHART]: () =>
        generateGroupedDateSeries("%"),
      [ChartName.FILL_RATE_GROUPED_REWARD_PER_DAU_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.PRICED_REVENUE_LINEAR_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: REVENUE_REPORT_SERIES,
          },
        ],
        legend: true,
      }),
      [ChartName.PRICED_REVENUE_IAP_REVENUE_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.PRICED_REVENUE_SUBSCRIPTION_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.PRICED_REVENUE_REWARDED_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.PRICED_REVENUE_INTERSTITIAL_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.PRICED_REVENUE_BANNER_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.PRICED_REVENUE_STACKED_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              ...REVENUE_REPORT_SERIES.map((series: XYSeries) => ({
                ...series,
                stacked: true,
              })),
              {
                name: lang("chart.label.total"),
                y: "total",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.TRAFFIC_QUALITY_TOTAL_CHART]: () => ({
        xAxis: {
          type: XAxisType.CATEGORY,
          category: "day",
          title: lang("chart.label.day"),
        },
        yAxes: [
          {
            series: [
              {
                name: "returnRate",
                y: "returnRate",
                x: "day",
                tooltipText: `{name}: [bold]{valueY}[/]`,
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: "timeSpent",
                y: "timeSpent",
                x: "day",
                tooltipText: `{name}: [bold]{valueY}[/]`,
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: "arpu",
                y: "arpu",
                x: "day",
                tooltipText: `{name}: [bold]{valueY}[/]`,
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: "arppu",
                y: "arppu",
                x: "day",
                tooltipText: `{name}: [bold]{valueY}[/]`,
                type: XYSeriesType.LINE,
              },
            ],
          },
          {
            series: [
              {
                name: "payingUsers",
                y: "payingUsers",
                x: "day",
                tooltipText: `{name}: [bold]{valueY}[/]`,
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        legend: true,
      }),
      [ChartName.TRAFFIC_QUALITY_RETURN_RATE_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.thDay", 7),
                y: "returnRate_7",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 14),
                y: "returnRate_14",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 30),
                y: "returnRate_30",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        hasAdditionalBottomChart: true,
        legend: true,
      }),
      [ChartName.TRAFFIC_QUALITY_RETURN_RATE_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("returnRate"),
      [ChartName.TRAFFIC_QUALITY_RETURN_RATE_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_RETURN_RATE_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_RETURN_RATE_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_TIME_SPENT_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.thDay", 7),
                y: "timeSpent_7",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 14),
                y: "timeSpent_14",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 30),
                y: "timeSpent_30",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        hasAdditionalBottomChart: true,
        legend: true,
      }),
      [ChartName.TRAFFIC_QUALITY_TIME_SPENT_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("timeSpent"),
      [ChartName.TRAFFIC_QUALITY_TIME_SPENT_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_TIME_SPENT_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_TIME_SPENT_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_ARPU_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.thDay", 7),
                y: "arpu_7",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 14),
                y: "arpu_14",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 30),
                y: "arpu_30",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        hasAdditionalBottomChart: true,
        legend: true,
      }),
      [ChartName.TRAFFIC_QUALITY_ARPU_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("arpu"),
      [ChartName.TRAFFIC_QUALITY_ARPU_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_ARPU_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_ARPU_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_ARPPU_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.thDay", 7),
                y: "arppu_7",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 14),
                y: "arppu_14",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 30),
                y: "arppu_30",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        hasAdditionalBottomChart: true,
        legend: true,
      }),
      [ChartName.TRAFFIC_QUALITY_ARPPU_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("arppu"),
      [ChartName.TRAFFIC_QUALITY_ARPPU_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_ARPPU_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_ARPPU_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_PAYING_USERS_CHART]: () => ({
        xAxis: {
          type: XAxisType.DATE,
        },
        yAxes: [
          {
            series: [
              {
                name: lang("chart.label.thDay", 7),
                y: "payingUsers_7",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 14),
                y: "payingUsers_14",
                x: "date",
                type: XYSeriesType.LINE,
              },
              {
                name: lang("chart.label.thDay", 30),
                y: "payingUsers_30",
                x: "date",
                type: XYSeriesType.LINE,
              },
            ],
          },
        ],
        hasAdditionalBottomChart: true,
        legend: true,
      }),
      [ChartName.TRAFFIC_QUALITY_PAYING_USERS_SUBTOTAL_CHART]: () =>
        generateCohortSubTotalSeries("payingUsers"),
      [ChartName.TRAFFIC_QUALITY_PAYING_USERS_DAY_7_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_PAYING_USERS_DAY_14_CHART]: () =>
        generateGroupedDateSeries(),
      [ChartName.TRAFFIC_QUALITY_PAYING_USERS_DAY_30_CHART]: () =>
        generateGroupedDateSeries(),
    } as Record<ChartName, () => XYChartOptions | ChartOptions>
  )[chartName]();
};

const processChartData = (
  result: Array<Record<string, any>>,
  filterId: FilterId | null,
  chartName?: ChartName,
  dayLimit?: number,
  maxSeriesCount = 9
): Array<Record<string, any>> => {
  if (!chartName) {
    return result;
  }

  if (chartName === ChartName.METRICS_TOTAL_CHART) {
    return [3, 7, 14, 21, 30].map((day) => ({
      day,
      ...result.reduce(
        (res, item) => ({
          ...res,
          returnRate: item[`returnRate_${day}`],
          timeSpent: item[`timeSpent_${day}`],
          arpu: item[`arpu_${day}`],
        }),
        {}
      ),
    }));
  }

  if (chartName === ChartName.METRICS_SPEND_TOTAL_CHART) {
    return [3, 7, 30].map((day) => ({
      day,
      ...result.reduce(
        (res, item) => ({
          ...res,
          returnRate: item[`returnRate_${day}`],
          timeSpent: item[`timeSpent_${day}`],
          engagement: item[`engagement_${day}`],
          value: item[`value_${day}`],
        }),
        {}
      ),
    }));
  }

  if (chartName === ChartName.TRAFFIC_QUALITY_TOTAL_CHART) {
    return [7, 14, 30].map((day) => ({
      day,
      ...result.reduce(
        (res, item) => ({
          ...res,
          returnRate: item[`returnRate_${day}`],
          timeSpent: item[`timeSpent_${day}`],
          arpu: item[`arpu_${day}`],
          arppu: item[`arppu_${day}`],
          payingUsers: item[`payingUsers_${day}`],
        }),
        {}
      ),
    }));
  }

  if (
    [
      ChartName.RETURN_RATE_METRICS_SUBTOTAL_CHART,
      ChartName.TIME_SPENT_METRICS_SUBTOTAL_CHART,
      ChartName.ARPU_METRICS_SUBTOTAL_CHART,
    ].includes(chartName) &&
    filterId
  ) {
    const keyPrefix = new Map([
      [ChartName.RETURN_RATE_METRICS_SUBTOTAL_CHART, "returnRate"],
      [ChartName.TIME_SPENT_METRICS_SUBTOTAL_CHART, "timeSpent"],
      [ChartName.ARPU_METRICS_SUBTOTAL_CHART, "arpu"],
    ]).get(chartName);

    return [3, 7, 14, 21, 30].map((day) => ({
      day,
      ...result.slice(0, 9).reduce(
        (res, item) => ({
          ...res,
          [item[filterId.toLowerCase()]]: item[`${keyPrefix}_${day}`],
        }),
        {}
      ),
    }));
  }

  if (
    [
      ChartName.METRICS_SPEND_RETURN_RATE_SUBTOTAL_CHART,
      ChartName.METRICS_SPEND_TIME_SPENT_SUBTOTAL_CHART,
      ChartName.METRICS_SPEND_ENGAGEMENT_SUBTOTAL_CHART,
      ChartName.METRICS_SPEND_VALUE_SUBTOTAL_CHART,
    ].includes(chartName) &&
    filterId
  ) {
    const keyPrefix = new Map([
      [ChartName.METRICS_SPEND_RETURN_RATE_SUBTOTAL_CHART, "returnRate"],
      [ChartName.METRICS_SPEND_TIME_SPENT_SUBTOTAL_CHART, "timeSpent"],
      [ChartName.METRICS_SPEND_ENGAGEMENT_SUBTOTAL_CHART, "engagement"],
      [ChartName.METRICS_SPEND_VALUE_SUBTOTAL_CHART, "value"],
    ]).get(chartName);

    return [3, 7, 30].map((day) => ({
      day,
      ...result.slice(0, 9).reduce(
        (res, item) => ({
          ...res,
          [item[filterId.toLowerCase()]]: item[`${keyPrefix}_${day}`],
        }),
        {}
      ),
    }));
  }

  if (
    [
      ChartName.TRAFFIC_QUALITY_RETURN_RATE_SUBTOTAL_CHART,
      ChartName.TRAFFIC_QUALITY_TIME_SPENT_SUBTOTAL_CHART,
      ChartName.TRAFFIC_QUALITY_ARPU_SUBTOTAL_CHART,
      ChartName.TRAFFIC_QUALITY_ARPPU_SUBTOTAL_CHART,
      ChartName.TRAFFIC_QUALITY_PAYING_USERS_SUBTOTAL_CHART,
    ].includes(chartName) &&
    filterId
  ) {
    const keyPrefix = new Map([
      [ChartName.TRAFFIC_QUALITY_RETURN_RATE_SUBTOTAL_CHART, "returnRate"],
      [ChartName.TRAFFIC_QUALITY_TIME_SPENT_SUBTOTAL_CHART, "timeSpent"],
      [ChartName.TRAFFIC_QUALITY_ARPU_SUBTOTAL_CHART, "arpu"],
      [ChartName.TRAFFIC_QUALITY_ARPPU_SUBTOTAL_CHART, "arppu"],
      [ChartName.TRAFFIC_QUALITY_PAYING_USERS_SUBTOTAL_CHART, "payingUsers"],
    ]).get(chartName);

    return [7, 14, 30].map((day) => ({
      day,
      ...result.slice(0, 9).reduce(
        (res, item) => ({
          ...res,
          [item[filterId.toLowerCase()]]: item[`${keyPrefix}_${day}`],
        }),
        {}
      ),
    }));
  }

  if (
    dayLimit &&
    [
      ChartName.AD_ROAS_COUNTRY_TOTAL_CHART,
      ChartName.AD_ROAS_NETWORK_TOTAL_CHART,
      ChartName.ARPU_REPORT_TOTAL_CHART,
      ChartName.COHORT_CPM_TOTAL_CHART,
      ChartName.COHORT_CTR_TOTAL_CHART,
      ChartName.MEASURES_LITE_REPORT_TOTAL_CHART,
      ChartName.RETURN_RATE_REPORT_TOTAL_CHART,
      ChartName.TIME_SPENT_REPORT_TOTAL_CHART,
    ].includes(chartName)
  ) {
    return Array.from({ length: dayLimit }).map((_, index) => ({
      day: index,
      ...result.reduce(
        (res, item) => ({
          ...res,
          value: item[index],
        }),
        {}
      ),
    }));
  }

  if (
    dayLimit &&
    filterId &&
    [
      ChartName.AD_ROAS_COUNTRY_SUBTOTAL_CHART,
      ChartName.AD_ROAS_NETWORK_SUBTOTAL_CHART,
      ChartName.ARPU_REPORT_SUBTOTAL_CHART,
      ChartName.COHORT_CPM_SUBTOTAL_CHART,
      ChartName.COHORT_CTR_SUBTOTAL_CHART,
      ChartName.MEASURES_LITE_REPORT_SUBTOTAL_CHART,
      ChartName.RETURN_RATE_REPORT_SUBTOTAL_CHART,
      ChartName.TIME_SPENT_REPORT_SUBTOTAL_CHART,
    ].includes(chartName)
  ) {
    return Array.from({ length: dayLimit }).map((_, index) => ({
      day: index,
      ...result.slice(0, 9).reduce(
        (res, item) => ({
          ...res,
          [item[filterId.toLowerCase()]]: item[index],
        }),
        {}
      ),
    }));
  }

  let dataKey: string;

  if (filterId) {
    dataKey = filterId.toLowerCase();
  } else {
    switch (chartName) {
      case ChartName.REVENUE_DIFF_PERCENT_DATE_COUNTRY_BASED_DIFF_REVENUE_DAY:
      case ChartName.REVENUE_DIFF_PERCENT_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_DAY:
      case ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_REVENUE_PREV_DAY:
      case ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_PREV_DAY:
      case ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_REVENUE_PREV_YEAR:
      case ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_PREV_YEAR:
        dataKey = "country";
        break;
      case ChartName.REVENUE_DIFF_PERCENT_DATE_NETWORK_BASED_DIFF_REVENUE_DAY:
      case ChartName.REVENUE_DIFF_PERCENT_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_DAY:
      case ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_REVENUE_PREV_DAY:
      case ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_PREV_DAY:
      case ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_REVENUE_PREV_YEAR:
      case ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_PREV_YEAR:
        dataKey = "adNetworkName";
        break;
      default:
        return result;
    }
  }

  const METRIC_FOR_GROUPING_CHART_DATA = new Map([
    [
      ChartName.REVENUE_DIFF_PERCENT_DATE_COUNTRY_BASED_DIFF_REVENUE_DAY,
      "diffRevenueDay",
    ],
    [
      ChartName.REVENUE_DIFF_PERCENT_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_DAY,
      "diffImpressionsDay",
    ],
    [
      ChartName.REVENUE_DIFF_PERCENT_DATE_NETWORK_BASED_DIFF_REVENUE_DAY,
      "diffRevenueDay",
    ],
    [
      ChartName.REVENUE_DIFF_PERCENT_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_DAY,
      "diffImpressionsDay",
    ],
    [
      ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_REVENUE_PREV_DAY,
      "diffRevenuePrevDay",
    ],
    [
      ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_REVENUE_PREV_DAY,
      "diffRevenuePrevDay",
    ],
    [
      ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_PREV_DAY,
      "diffImpressionsPrevDay",
    ],
    [
      ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_PREV_DAY,
      "diffImpressionsPrevDay",
    ],
    [
      ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_REVENUE_PREV_YEAR,
      "diffRevenuePrevYear",
    ],
    [
      ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_REVENUE_PREV_YEAR,
      "diffRevenuePrevYear",
    ],
    [
      ChartName.ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_PREV_YEAR,
      "diffImpressionsPrevYear",
    ],
    [
      ChartName.ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_PREV_YEAR,
      "diffImpressionsPrevYear",
    ],
    [ChartName.EVENTS_SUMMARY_TOTAL_OCCURRENCES_CHART, "totalOccurrences"],
    [
      ChartName.EVENTS_SUMMARY_AVG_EVENTS_PER_SESSION_CHART,
      "avgEventsPerSession",
    ],
    [
      ChartName.EVENTS_SUMMARY_AVG_EVENTS_PER_DEVICE_CHART,
      "occurrencesDailyAvg",
    ],
    [ChartName.EVENTS_SUMMARY_UNIQUE_DEVICES_CHART, "uniqueDevicesDailyAvg"],
    [ChartName.ARPDAU_ARPDAU_CHART, "arpdau"],
    [ChartName.ARPDAU_ARPDAU_IAP_CHART, "arpdauIap"],
    [ChartName.ARPDAU_SUBSCRIPTION_CHART, "subscription"],
    [ChartName.ARPDAU_BANNER_CHART, "banner"],
    [ChartName.ARPDAU_INTERSTITIAL_CHART, "interstitial"],
    [ChartName.ARPDAU_REWARDED_CHART, "rewarded"],
    [ChartName.ARPDAU_SIMPLE_ALL_USERS_ARPDAU_CHART, "arpdau"],
    [ChartName.ARPDAU_SIMPLE_ALL_USERS_ARPDAU_IAP_CHART, "arpdauIap"],
    [ChartName.ARPDAU_SIMPLE_ALL_USERS_BANNER_CHART, "banner"],
    [ChartName.ARPDAU_SIMPLE_ALL_USERS_INTERSTITIAL_CHART, "interstitial"],
    [ChartName.ARPDAU_SIMPLE_ALL_USERS_REWARDED_CHART, "rewarded"],
    [ChartName.ARPDAU_SIMPLE_ACTIVE_USERS_ARPDAU_CHART, "arpdau_e"],
    [ChartName.ARPDAU_SIMPLE_ACTIVE_USERS_BANNER_CHART, "banner_e"],
    [ChartName.ARPDAU_SIMPLE_ACTIVE_USERS_INTERSTITIAL_CHART, "interstitial_e"],
    [ChartName.ARPDAU_SIMPLE_ACTIVE_USERS_REWARDED_CHART, "rewarded_e"],
    [ChartName.FILL_RATE_GROUPED_BANNER_CHART, "bannerFillRate"],
    [ChartName.FILL_RATE_GROUPED_BANNER_PER_DAU_CHART, "bannerPerDau"],
    [ChartName.FILL_RATE_GROUPED_INTER_CHART, "interFillRate"],
    [ChartName.FILL_RATE_GROUPED_INTER_PER_DAU_CHART, "interPerDau"],
    [ChartName.FILL_RATE_GROUPED_REWARD_CHART, "rewardFillRate"],
    [ChartName.FILL_RATE_GROUPED_REWARD_PER_DAU_CHART, "rewardPerDau"],
    [ChartName.REVENUE_REPORT_IAP_REVENUE_CHART, "inApps"],
    [ChartName.REVENUE_REPORT_REWARDED_CHART, "rewarded"],
    [ChartName.REVENUE_REPORT_INTERSTITIAL_CHART, "interstitial"],
    [ChartName.REVENUE_REPORT_BANNER_CHART, "banner"],
    [ChartName.PRICED_REVENUE_IAP_REVENUE_CHART, "inApps"],
    [ChartName.PRICED_REVENUE_SUBSCRIPTION_CHART, "subscription"],
    [ChartName.PRICED_REVENUE_REWARDED_CHART, "rewarded"],
    [ChartName.PRICED_REVENUE_INTERSTITIAL_CHART, "interstitial"],
    [ChartName.PRICED_REVENUE_BANNER_CHART, "banner"],
    [ChartName.ARPU_REPORT_DAY_1_CHART, "1"],
    [ChartName.ARPU_REPORT_DAY_7_CHART, "7"],
    [ChartName.ARPU_REPORT_DAY_14_CHART, "14"],
    [ChartName.RETURN_RATE_REPORT_DAY_1_CHART, "1"],
    [ChartName.RETURN_RATE_REPORT_DAY_7_CHART, "7"],
    [ChartName.RETURN_RATE_REPORT_DAY_14_CHART, "14"],
    [ChartName.MEASURES_LITE_REPORT_DAY_1_CHART, "1"],
    [ChartName.MEASURES_LITE_REPORT_DAY_7_CHART, "7"],
    [ChartName.MEASURES_LITE_REPORT_DAY_14_CHART, "14"],
    [ChartName.TIME_SPENT_REPORT_DAY_1_CHART, "1"],
    [ChartName.TIME_SPENT_REPORT_DAY_7_CHART, "7"],
    [ChartName.TIME_SPENT_REPORT_DAY_14_CHART, "14"],
    [ChartName.RETURN_RATE_METRICS_DAY_3_CHART, "returnRate_3"],
    [ChartName.RETURN_RATE_METRICS_DAY_7_CHART, "returnRate_7"],
    [ChartName.RETURN_RATE_METRICS_DAY_14_CHART, "returnRate_14"],
    [ChartName.RETURN_RATE_METRICS_DAY_21_CHART, "returnRate_21"],
    [ChartName.RETURN_RATE_METRICS_DAY_30_CHART, "returnRate_30"],
    [ChartName.TIME_SPENT_METRICS_DAY_3_CHART, "timeSpent_3"],
    [ChartName.TIME_SPENT_METRICS_DAY_7_CHART, "timeSpent_7"],
    [ChartName.TIME_SPENT_METRICS_DAY_14_CHART, "timeSpent_14"],
    [ChartName.TIME_SPENT_METRICS_DAY_21_CHART, "timeSpent_21"],
    [ChartName.TIME_SPENT_METRICS_DAY_30_CHART, "timeSpent_30"],
    [ChartName.ARPU_METRICS_DAY_3_CHART, "arpu_3"],
    [ChartName.ARPU_METRICS_DAY_7_CHART, "arpu_7"],
    [ChartName.ARPU_METRICS_DAY_14_CHART, "arpu_14"],
    [ChartName.ARPU_METRICS_DAY_21_CHART, "arpu_21"],
    [ChartName.ARPU_METRICS_DAY_30_CHART, "arpu_30"],
    [ChartName.METRICS_SPEND_RETURN_RATE_DAY_3_CHART, "returnRate_3"],
    [ChartName.METRICS_SPEND_RETURN_RATE_DAY_7_CHART, "returnRate_7"],
    [ChartName.METRICS_SPEND_RETURN_RATE_DAY_30_CHART, "returnRate_30"],
    [ChartName.METRICS_SPEND_TIME_SPENT_DAY_3_CHART, "timeSpent_3"],
    [ChartName.METRICS_SPEND_TIME_SPENT_DAY_7_CHART, "timeSpent_7"],
    [ChartName.METRICS_SPEND_TIME_SPENT_DAY_30_CHART, "timeSpent_30"],
    [ChartName.METRICS_SPEND_ENGAGEMENT_DAY_3_CHART, "engagement_3"],
    [ChartName.METRICS_SPEND_ENGAGEMENT_DAY_7_CHART, "engagement_7"],
    [ChartName.METRICS_SPEND_ENGAGEMENT_DAY_30_CHART, "engagement_30"],
    [ChartName.METRICS_SPEND_VALUE_DAY_3_CHART, "value_3"],
    [ChartName.METRICS_SPEND_VALUE_DAY_7_CHART, "value_7"],
    [ChartName.METRICS_SPEND_VALUE_DAY_30_CHART, "value_30"],
    [ChartName.COHORT_CPM_DAY_3_CHART, "3"],
    [ChartName.COHORT_CPM_DAY_7_CHART, "7"],
    [ChartName.COHORT_CPM_DAY_14_CHART, "14"],
    [ChartName.COHORT_CPM_DAY_30_CHART, "30"],
    [ChartName.CPM_REPORT_BANNER_CHART, "bannerCPM"],
    [ChartName.CPM_REPORT_INTERSTITIAL_CHART, "interstitialCPM"],
    [ChartName.CPM_REPORT_REWARDED_CHART, "rewardedCPM"],
    [ChartName.COHORT_PERMIN_REPORT_DAY_1_CHART, "1"],
    [ChartName.COHORT_PERMIN_REPORT_DAY_7_CHART, "7"],
    [ChartName.COHORT_PERMIN_REPORT_DAY_14_CHART, "14"],
    [ChartName.CALENDAR_PERMIN_REPORT_EVENTS_BANNER_CHART, "eventsBanner"],
    [ChartName.CALENDAR_PERMIN_REPORT_PERMIN_BANNER_CHART, "perMinBanner"],
    [ChartName.CALENDAR_CTR_BANNER_CHART, "bannerCtr"],
    [ChartName.CALENDAR_CTR_INTERSTITIAL_CHART, "interstitialCtr"],
    [ChartName.CALENDAR_CTR_REWARDED_CHART, "rewardedCtr"],
    [ChartName.COHORT_CTR_DAY_3_CHART, "3"],
    [ChartName.COHORT_CTR_DAY_7_CHART, "7"],
    [ChartName.COHORT_CTR_DAY_14_CHART, "14"],
    [ChartName.COHORT_CTR_DAY_30_CHART, "30"],
    [ChartName.AD_ROAS_COUNTRY_DAY_0_CHART, "0"],
    [ChartName.AD_ROAS_COUNTRY_DAY_1_CHART, "1"],
    [ChartName.AD_ROAS_COUNTRY_DAY_3_CHART, "3"],
    [ChartName.AD_ROAS_COUNTRY_DAY_7_CHART, "7"],
    [ChartName.AD_ROAS_COUNTRY_DAY_14_CHART, "14"],
    [ChartName.AD_ROAS_COUNTRY_DAY_30_CHART, "30"],
    [ChartName.AD_ROAS_COUNTRY_DAY_90_CHART, "90"],
    [ChartName.AD_ROAS_COUNTRY_DAY_120_CHART, "120"],
    [ChartName.AD_ROAS_COUNTRY_DAY_150_CHART, "150"],
    [ChartName.AD_ROAS_COUNTRY_DAY_180_CHART, "180"],
    [ChartName.AD_ROAS_COUNTRY_DAY_210_CHART, "210"],
    [ChartName.AD_ROAS_COUNTRY_DAY_240_CHART, "240"],
    [ChartName.AD_ROAS_COUNTRY_DAY_270_CHART, "270"],
    [ChartName.AD_ROAS_COUNTRY_DAY_300_CHART, "300"],
    [ChartName.AD_ROAS_COUNTRY_DAY_330_CHART, "330"],
    [ChartName.AD_ROAS_COUNTRY_DAY_364_CHART, "364"],
    [ChartName.AD_ROAS_NETWORK_DAY_0_CHART, "0"],
    [ChartName.AD_ROAS_NETWORK_DAY_1_CHART, "1"],
    [ChartName.AD_ROAS_NETWORK_DAY_3_CHART, "3"],
    [ChartName.AD_ROAS_NETWORK_DAY_7_CHART, "7"],
    [ChartName.AD_ROAS_NETWORK_DAY_14_CHART, "14"],
    [ChartName.AD_ROAS_NETWORK_DAY_30_CHART, "30"],
    [ChartName.AD_ROAS_NETWORK_DAY_90_CHART, "90"],
    [ChartName.AD_ROAS_NETWORK_DAY_120_CHART, "120"],
    [ChartName.AD_ROAS_NETWORK_DAY_150_CHART, "150"],
    [ChartName.AD_ROAS_NETWORK_DAY_180_CHART, "180"],
    [ChartName.AD_ROAS_NETWORK_DAY_210_CHART, "210"],
    [ChartName.AD_ROAS_NETWORK_DAY_240_CHART, "240"],
    [ChartName.AD_ROAS_NETWORK_DAY_270_CHART, "270"],
    [ChartName.AD_ROAS_NETWORK_DAY_300_CHART, "300"],
    [ChartName.AD_ROAS_NETWORK_DAY_330_CHART, "330"],
    [ChartName.AD_ROAS_NETWORK_DAY_364_CHART, "364"],
    [ChartName.TRAFFIC_QUALITY_RETURN_RATE_DAY_7_CHART, "returnRate_7"],
    [ChartName.TRAFFIC_QUALITY_RETURN_RATE_DAY_14_CHART, "returnRate_14"],
    [ChartName.TRAFFIC_QUALITY_RETURN_RATE_DAY_30_CHART, "returnRate_30"],
    [ChartName.TRAFFIC_QUALITY_TIME_SPENT_DAY_7_CHART, "timeSpent_7"],
    [ChartName.TRAFFIC_QUALITY_TIME_SPENT_DAY_14_CHART, "timeSpent_14"],
    [ChartName.TRAFFIC_QUALITY_TIME_SPENT_DAY_30_CHART, "timeSpent_30"],
    [ChartName.TRAFFIC_QUALITY_ARPU_DAY_7_CHART, "arpu_7"],
    [ChartName.TRAFFIC_QUALITY_ARPU_DAY_14_CHART, "arpu_14"],
    [ChartName.TRAFFIC_QUALITY_ARPU_DAY_30_CHART, "arpu_30"],
    [ChartName.TRAFFIC_QUALITY_ARPPU_DAY_7_CHART, "arppu_7"],
    [ChartName.TRAFFIC_QUALITY_ARPPU_DAY_14_CHART, "arppu_14"],
    [ChartName.TRAFFIC_QUALITY_ARPPU_DAY_30_CHART, "arppu_30"],
    [ChartName.TRAFFIC_QUALITY_PAYING_USERS_DAY_7_CHART, "payingUsers_7"],
    [ChartName.TRAFFIC_QUALITY_PAYING_USERS_DAY_14_CHART, "payingUsers_14"],
    [ChartName.TRAFFIC_QUALITY_PAYING_USERS_DAY_30_CHART, "payingUsers_30"],
  ]);
  const metric = METRIC_FOR_GROUPING_CHART_DATA.get(chartName);

  if (!metric) {
    return result;
  }

  const dataKeys = Array.from(
    new Set(result.map((item) => item[dataKey]))
  ).sort();

  dataKeys.splice(maxSeriesCount);

  return result.reduce((res: Array<any>, item: any) => {
    const foundIndex = res.findIndex(
      ({ date }: { date: number }) => date === item.date
    );

    if (dataKeys.includes(item[dataKey])) {
      if (foundIndex >= 0) {
        res[foundIndex][item[dataKey]] = item[metric];
      } else {
        res.push({
          date: item.date,
          [item[dataKey]]: item[metric],
        });
      }
    }

    return res;
  }, []);
};

export const getReportChartData = (
  items: Array<ReportResultItem>,
  filter?: Map<string, string>,
  chartName?: ChartName,
  filterId: FilterId | null = null,
  dayLimit?: number,
  maxSeriesCount?: number
) => {
  const localItems = filter?.size
    ? items.filter(
        (reportItem: ReportResultItem) =>
          filter &&
          Array.from(filter).every(([key, value]) =>
            key === "date"
              ? reportItem.formattedDate === value
              : reportItem.data[key] === value
          )
      )
    : items;
  const result = localItems
    .map((reportItem: ReportResultItem) => ({
      ...(reportItem.date ? { date: new Date(reportItem.date).getTime() } : {}),
      ...Object.entries(reportItem.data).reduce(
        (result, [key, value]) => ({
          ...result,
          [key]: value,
        }),
        {}
      ),
    }))
    .sort(({ date: dateA }, { date: dateB }) =>
      dateA && dateB ? dateA - dateB : 0
    );

  return processChartData(
    result,
    filterId,
    chartName,
    dayLimit,
    maxSeriesCount
  );
};

export const getFunnelChartData = (
  chartData: FunnelChartData,
  percentMode: FunnelChartPercentMode = FunnelChartPercentMode.RELATIVE
) => {
  return chartData.data.map((dataItem) => {
    const stepsValue = Array.from(dataItem.stepsValue);
    const values = stepsValue.map(([, value]) => value);
    const totalValue = values.reduce((result, value) => result + value, 0);

    return stepsValue.map(([key, value], index) => {
      const step = chartData.steps.find((step) => step.number === +key);

      return {
        name: step?.name || "",
        value,
        percent: {
          TOTAL: calculateTotalPercent(value, totalValue),
          RELATIVE: calculateRelativePercent(index, value, values),
          FIRST_STEP: calculateFirstStepPercent(value, values),
        }[percentMode],
        description: step?.description,
      };
    });
  });
};

export const getDashboardChartByChartName = (
  name: ChartName
): DashboardChart => {
  switch (name) {
    case ChartName.SOV_CHART:
    case ChartName.SOW_CHART:
      return {
        name,
        type: DashboardChartType.PIE,
      };
    case ChartName.REVENUE_REPORT_STACKED_CHART:
    case ChartName.PRICED_REVENUE_STACKED_CHART:
      return {
        name,
        type: DashboardChartType.STACKED,
      };
    default:
      return {
        name,
        type: DashboardChartType.LINEAR,
      };
  }
};
