import { CohortFilter, CohortHeaders } from "./CohortReport";
import { GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  DefaultValues,
  AdRevenueMethod,
  FilterPreviewId,
  Application,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { GroupByType, ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { PredictedResultItem, ReportFilter, ReportResultItem } from "./Report";
import { TimeSpentReportType } from "@/reports/models/TimeSpentReportType";
import { ReportDataType } from "./ReportVisualization";
import {
  EventReportFilterExtension,
  NetRevenueReportFilterExtension,
  TimeSpentTypeReportFilterExtension,
} from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";
import { ReportItemRowObject } from "./ReportItemRowObject";

const DAYS = [3, 7, 14, 21, 30];

export class MetricsHeaders {
  static init(
    lang: LangService,
    report: MetricsFilter,
    reportDataType: ReportDataType,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    return [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy &&
      reportDataType !== ReportDataType.TOTAL
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      {
        text: lang("components.baseTable.installs"),
        align: "end",
        value: ReportResultItem.PREFIX + "installs",
        width: 90,
      },
      {
        text: lang("components.baseTable.arpu_365"),
        align: "end",
        value: `${ReportResultItem.PREFIX}arpu_365`,
        hasGradient: true,
        fractionDigits: CohortHeaders.PRECISION_FOR_ARPU,
        class: "text-no-wrap",
      },
      ...this.generateMetricsHeaders(
        ["returnRate", "timeSpent", "arpu"],
        lang,
        groupBy
      ),
    ];
  }

  static generateMetricsHeaders(
    names: Array<string>,
    lang: LangService,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    const result: Array<ReportHeader> = [];

    if (groupBy === GroupByType.METRICS) {
      names.forEach((name) => {
        result.push(
          ...DAYS.map(
            (day) =>
              ({
                text: lang(`components.baseTable.metrics.${name}`, day),
                align: "end",
                value: `${ReportResultItem.PREFIX}${name}_${day}`,
                hasGradient: true,
                groupBy: {
                  border: {
                    left: day === DAYS[0],
                    right:
                      name === names[names.length - 1] &&
                      day === DAYS[DAYS.length - 1],
                  },
                  hasBackground: names
                    .filter((_, index) => index % 2 === 0)
                    .includes(name),
                },
                fractionDigits: CohortHeaders.PRECISION,
                class: "text-no-wrap",
              } as ReportHeader)
          )
        );
      });
    } else {
      DAYS.forEach((day) => {
        result.push(
          ...names.map(
            (name) =>
              ({
                text: lang(`components.baseTable.metrics.${name}`, day),
                align: "end",
                value: `${ReportResultItem.PREFIX}${name}_${day}`,
                hasGradient: true,
                groupBy: {
                  border: {
                    left: name === names[0],
                    right:
                      name === names[names.length - 1] &&
                      day === DAYS[DAYS.length - 1],
                  },
                  hasBackground: DAYS.filter(
                    (_, index) => index % 2 === 0
                  ).includes(day),
                },
                fractionDigits: CohortHeaders.PRECISION,
                class: "text-no-wrap",
              } as ReportHeader)
          )
        );
      });
    }

    return result;
  }
}

export class MetricsFilter
  extends CohortFilter
  implements
    TimeSpentTypeReportFilterExtension,
    EventReportFilterExtension,
    NetRevenueReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    public timeSpentType = TimeSpentReportType.TIME_SPENT,
    public event: string = DefaultValues.EVENT,
    public adRevenueMethod = AdRevenueMethod.PRICED,
    public netRevenue = true
  ) {
    super(ReportType.METRICS, app, filter, date, groupByFilter, 31);
  }

  get isPricedMethod(): boolean {
    return this.adRevenueMethod === AdRevenueMethod.PRICED;
  }

  get previews() {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.EVENT_SIMPLE,
        value: this.event,
      },
      {
        id: FilterPreviewId.AD_REVENUE_METHOD,
        value: this.adRevenueMethod,
      },
      {
        id: FilterPreviewId.TIME_SPENT_TYPE,
        value: this.timeSpentType,
      },
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  get charts(): Array<ChartName> {
    const days = [3, 7, 14, 21, 30];

    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ...this.getGroupedCohortChartNames("RETURN_RATE_METRICS", days),
          ...this.getGroupedCohortChartNames("TIME_SPENT_METRICS", days),
          ...this.getGroupedCohortChartNames("ARPU_METRICS", days),
        ]
      : [
          ChartName.RETURN_RATE_METRICS_CHART,
          ChartName.TIME_SPENT_METRICS_CHART,
          ChartName.ARPU_METRICS_CHART,
        ];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.RETURN_RATE_METRICS_CHART,
          ChartName.TIME_SPENT_METRICS_CHART,
          ChartName.ARPU_METRICS_CHART,
        ]
      : [];
  }

  static of(app: Application, filter?: Record<string, any>): MetricsFilter {
    return filter
      ? new MetricsFilter(
          app,
          filter.filter && filter.filter.length > 0
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.timeSpentType,
          filter.event,
          filter.adRevenueMethod
          // TODO: NET_REVENUE need to uncomment later
          // typeof filter.netRevenue === "string"
          //   ? filter.netRevenue === "true"
          //   : filter.netRevenue,
        )
      : new MetricsFilter(app);
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      timeSpentType: this.timeSpentType,
      event: this.event,
      adRevenueMethod: this.adRevenueMethod,
      // TODO: NET_REVENUE need to uncomment later
      // netRevenue: this.netRevenue,
    };
  }
}

export class MetricsResultItem extends PredictedResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();

    const { groupByFilter } = filter;

    if (row.getByHeader("installDate") || row.getByHeader("date")) {
      this.date = row.getByHeader("installDate") || row.getByHeader("date");
      this.setFormattedDate(
        this.date,
        filter.date.from,
        filter.date.to,
        groupByFilter.aggregationPeriod
      );
    }

    this.setGroupByValue(groupByFilter, row);
    this.data["installs"] = row.getByHeader("installs") ?? 0;
    this.data["arpu_365"] = super.parseFloat(
      row.getByHeader("arpu_365"),
      CohortHeaders.PRECISION_FOR_ARPU
    );
    this.data = {
      ...this.data,
      ...this.generateResultItemData("timeSpent", row),
      ...this.generateResultItemData("returnRate", row),
      ...this.generateResultItemData("arpu", row),
    };
  }

  private generateResultItemData(
    name: string,
    row: ReportItemRowObject
  ): Record<string, number> {
    return DAYS.reduce(
      (result, number) => ({
        ...result,
        [`${name}_${number}`]:
          row.getByHeader(`${name}_${number}`) ?? undefined,
      }),
      {}
    );
  }
}
