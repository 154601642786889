import {
  Application,
  DatesFilterModel,
  DictionaryType,
  FilterId,
  FilterModel,
  FilterPreviewId,
  recordToFilterModel,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import DateUtil from "@/shared/utils/DateUtil";
import { MultiAppsReportFilter, ReportResultItem } from "./Report";
import { ReportHeader } from "./ReportHeader";
import { ReportType } from "./ReportType";
import { ReportDataType } from "./ReportVisualization";
import { ReportItemRowObject } from "./ReportItemRowObject";

export class PayoutsFilter extends MultiAppsReportFilter {
  constructor(
    availableApps: Array<Application>,
    platforms?: Array<string>,
    apps?: Array<string>,
    includedApps?: boolean,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    public accrualPeriod?: DatesFilterModel,
    public netRevenue = true
  ) {
    super(
      ReportType.PAYOUTS,
      availableApps,
      platforms,
      apps,
      includedApps,
      filter,
      date
    );
  }

  static of(
    availableApps: Array<Application>,
    requestQuery?: Record<string, any>
  ): PayoutsFilter {
    return new PayoutsFilter(
      availableApps,
      requestQuery?.platforms as Array<string>,
      requestQuery?.apps as Array<string>,
      typeof requestQuery?.includedApps === "string"
        ? requestQuery?.includedApps === "true"
        : requestQuery?.includedApps,
      requestQuery?.filter?.map((it: any) => recordToFilterModel(it)) || [],
      requestQuery?.date
        ? DatesFilterModel.ofRecord(
            FilterId.EVENTS_DATE,
            requestQuery?.date,
            false,
            true
          )
        : undefined,
      requestQuery?.accrualPeriod
        ? DatesFilterModel.ofRecord(
            FilterPreviewId.ACCRUAL_DATE_VALUE as any,
            requestQuery?.accrualPeriod,
            false
          )
        : undefined
      // TODO: NET_REVENUE need to uncomment later
      // typeof requestQuery.netRevenue === "string"
      //   ? requestQuery.netRevenue === "true"
      //   : requestQuery.netRevenue,
    );
  }

  get previews() {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
      ...(this.accrualPeriod && this.accrualPeriod.from && this.accrualPeriod.to
        ? [
            {
              id: FilterPreviewId.ACCRUAL_DATE_VALUE,
              value: `${this.accrualPeriod.from} - ${this.accrualPeriod.to}`,
            },
          ]
        : []),
    ];
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      // TODO: NET_REVENUE need to uncomment later
      // netRevenue: this.netRevenue,
      accrualPeriod: this.accrualPeriod?.toRecord(),
    };
  }

  getUsedDictionaryValues(): Record<string, Array<string>> {
    const usedDictionaryValues = super.getUsedDictionaryValues();

    if (usedDictionaryValues[DictionaryType.ROOT_AD_NETWORK_NAMES]?.length) {
      usedDictionaryValues[DictionaryType.ALL_AD_NETWORK_NAMES] =
        usedDictionaryValues[DictionaryType.ROOT_AD_NETWORK_NAMES];
      delete usedDictionaryValues[DictionaryType.ROOT_AD_NETWORK_NAMES];
    }

    return usedDictionaryValues;
  }
}

export class PayoutsHeaders {
  static init(
    lang: LangService,
    reportDataType: ReportDataType
  ): Array<ReportHeader> {
    const result: Array<ReportHeader> = [
      {
        text: lang("components.date"),
        align: "center",
        value: "date",
        width: 120,
      },
    ];

    if (reportDataType === ReportDataType.MONTH_TOTAL) {
      result.push({
        text: lang("components.baseTable.payout"),
        align: "end",
        value: `${ReportResultItem.PREFIX}revenue`,
      });
    } else {
      result.push(
        {
          text: lang("components.baseTable.source"),
          align: "end",
          value: `${ReportResultItem.PREFIX}adNetworkName`,
        },
        {
          text: lang("components.baseTable.payout"),
          align: "end",
          value: `${ReportResultItem.PREFIX}revenue`,
          fractionDigits: 0,
        },
        {
          text: lang("components.baseTable.month"),
          align: "end",
          value: `${ReportResultItem.PREFIX}pay_date`,
          sort: (a, b) => {
            return (
              DateUtil.getMonthNumberByMonthName(a) -
              DateUtil.getMonthNumberByMonthName(b)
            );
          },
        },
        {
          text: lang("components.baseTable.paymentTerms"),
          align: "end",
          value: `${ReportResultItem.PREFIX}net`,
          prefix: lang("components.baseTable.net"),
        }
      );
    }

    return result;
  }
}

export class PayoutsResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, isMonthly = false) {
    super();

    this.data["revenue"] = row.getByHeader("revenue");

    if (!isMonthly) {
      this.date = row.getByHeader("date");
      this.formattedDate = DateUtil.formatDate(this.date);
      this.data["adNetworkName"] = row.getByHeader("adNetworkName");
      this.data["pay_date"] = row.getByHeader("pay_month")
        ? `${DateUtil.getMonthByMonthNUmber(
            row.getByHeader("pay_month"),
            "MMMM"
          )} ${row.getByHeader("pay_year")}`
        : undefined;
      this.data["net"] = row.getByHeader("net");
    } else {
      this.date = `${DateUtil.getMonthByMonthNUmber(
        row.getByHeader("pay_month"),
        "MMMM"
      )} ${row.getByHeader("pay_year")}`;
      this.formattedDate = `${DateUtil.getMonthByMonthNUmber(
        row.getByHeader("pay_month"),
        "MMMM"
      )} ${row.getByHeader("pay_year")}`;
    }
  }
}
