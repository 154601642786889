import { GroupByType, ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import {
  BaseGradientFlags,
  BaseReportFilter,
  ReportFilter,
  ReportResultItem,
} from "./Report";
import { ReportDataType } from "./ReportVisualization";
import {
  AdRevenueMethodReportFilterExtension,
  ProvidedBannerReportFilterExtension,
  RevenueReportFilterExtension,
  TimeSpentTypeReportFilterExtension,
} from "./ReportFilterExtension";
import { TimeSpentReportType } from "./TimeSpentReportType";
import { ArpdauHeaders } from "./ArpdauReport";
import { CohortHeaders } from "./CohortReport";
import { AggregationPeriod, GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  FilterPreviewId,
  Application,
  DefaultValues,
  AdRevenueMethod,
  DictionaryType,
  ProvidedBannerType,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { ReportItemRowObject } from "./ReportItemRowObject";

const DEFAULT_COHORT_METRICS_DAYS = [3, 7, 14, 30];

export class UaMainMetricsOverviewGradientFlags extends BaseGradientFlags {
  constructor() {
    super();

    this.flags = {
      gradient: true,
    };
  }

  getByKey(): boolean {
    return super.getByKey("gradient");
  }
}

export class UaMainMetricsOverviewHeaders {
  static init(
    lang: LangService,
    report: UaMainMetricsOverviewFilter,
    reportDataType: ReportDataType,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    return [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy &&
      reportDataType !== ReportDataType.TOTAL
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      {
        text: lang("components.baseTable.adjustInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}adjustInstalls`,
        width: 90,
      },
      {
        text: lang("components.baseTable.paidInstalls"),
        align: "end",
        value: `${ReportResultItem.PREFIX}paidInstalls`,
        width: 90,
      },
      {
        text: lang("components.baseTable.cpi"),
        align: "end",
        value: `${ReportResultItem.PREFIX}cpi`,
        hasGradient: true,
        fractionDigits: CohortHeaders.PRECISION,
        reverseGradient: true,
        width: 90,
      },
      {
        text: lang("components.baseTable.ecpiAdjust"),
        align: "end",
        value: `${ReportResultItem.PREFIX}ecpiAdjust`,
        hasGradient: true,
        fractionDigits: CohortHeaders.PRECISION,
        reverseGradient: true,
        width: 90,
      },
      {
        text: lang("components.baseTable.spend"),
        align: "end",
        value: `${ReportResultItem.PREFIX}spend`,
        fractionDigits: 0,
        width: 90,
      },
      {
        text: lang("components.baseTable.arpdau"),
        align: "end",
        value: `${ReportResultItem.PREFIX}arpdau`,
        hasGradient: true,
        fractionDigits: ArpdauHeaders.PRECISION,
        width: 90,
      },
      ...this.generateUaMainHeaders(
        ["roas", "returnRate", "timeSpent", "arpu"],
        lang,
        groupBy,
        report.cohortMetricsDays
      ),
    ];
  }

  static generateUaMainHeaders(
    names: Array<string>,
    lang: LangService,
    groupBy: GroupByType,
    days: Array<number>
  ): Array<ReportHeader> {
    const result: Array<ReportHeader> = [];
    const generateReportHeader = (name: string, day: number) => ({
      text: lang(`components.baseTable.metrics.${name}`, day),
      align: "end",
      value: `${ReportResultItem.PREFIX}${name}_${day}`,
      hasGradient: true,
      fractionDigits: ["arpu"].includes(name) ? 4 : CohortHeaders.PRECISION,
      class: "text-no-wrap",
      ...(name === "roas" ? { postfix: "%" } : {}),
    });

    if (groupBy === GroupByType.METRICS) {
      names.forEach((name) => {
        result.push(
          ...days.map(
            (day) =>
              ({
                ...generateReportHeader(name, day),
                groupBy: {
                  border: {
                    left: day === days[0],
                    right:
                      name === names[names.length - 1] &&
                      day === days[days.length - 1],
                  },
                  hasBackground: names
                    .filter((_, index) => index % 2 === 0)
                    .includes(name),
                },
              } as ReportHeader)
          )
        );
      });
    } else {
      days.forEach((day) => {
        result.push(
          ...names.map(
            (name) =>
              ({
                ...generateReportHeader(name, day),
                groupBy: {
                  border: {
                    left: name === names[0],
                    right:
                      name === names[names.length - 1] &&
                      day === days[days.length - 1],
                  },
                  hasBackground: days
                    .filter((_, index) => index % 2 === 0)
                    .includes(day),
                },
              } as ReportHeader)
          )
        );
      });
    }

    return result;
  }
}

export class UaMainMetricsOverviewFilter
  extends BaseReportFilter
  implements
    AdRevenueMethodReportFilterExtension,
    RevenueReportFilterExtension,
    ProvidedBannerReportFilterExtension,
    TimeSpentTypeReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    public groupByFilter = new GroupByFilter(AggregationPeriod.DAY, []),
    public adRevenueMethod: AdRevenueMethod = AdRevenueMethod.PRICED,
    public revenue: Array<string> = [],
    public providedBanner: string = DefaultValues.PROVIDED_BANNER,
    public timeSpentType = TimeSpentReportType.TIME_SPENT,
    public cohortMetricsDays: Array<number> = DEFAULT_COHORT_METRICS_DAYS,
    public netRevenue = true
  ) {
    super(
      ReportType.UA_MAIN_METRICS_OVERVIEW,
      app,
      filter,
      date,
      groupByFilter
    );
  }

  get isBannerProvidedDisabled(): boolean {
    return this.adRevenueMethod === AdRevenueMethod.AVERAGE;
  }

  get invalid(): boolean {
    return (
      !(this.app && this.date.valid) ||
      !this.cohortMetricsDays.length ||
      (this.isBannerProvidedDisabled &&
        this.providedBanner === ProvidedBannerType.FIREBASE)
    );
  }

  get previews() {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.AD_REVENUE_METHOD,
        value: this.adRevenueMethod,
      },
      ...(this.revenue.length
        ? [
            {
              id: FilterPreviewId.REVENUE,
              value: this.revenue,
              dictionary: DictionaryType.REVENUES,
            },
          ]
        : []),
      {
        id: FilterPreviewId.PROVIDED_BANNER,
        value: this.providedBanner,
      },
      {
        id: FilterPreviewId.TIME_SPENT_TYPE,
        value: this.timeSpentType,
      },
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  static of(
    app: Application,
    filter?: Record<string, any>
  ): UaMainMetricsOverviewFilter {
    return filter
      ? new UaMainMetricsOverviewFilter(
          app,
          filter.filter?.length
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.adRevenueMethod,
          filter.revenue,
          filter.providedBanner,
          filter.timeSpentType,
          filter.cohortMetricsDays
            ? filter.cohortMetricsDays.map((item: string | number) =>
                Number(item)
              )
            : DEFAULT_COHORT_METRICS_DAYS
        )
      : new UaMainMetricsOverviewFilter(app);
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      adRevenueMethod: this.adRevenueMethod,
      revenue: this.revenue,
      providedBanner: this.providedBanner,
      timeSpentType: this.timeSpentType,
      cohortMetricsDays: this.cohortMetricsDays,
    };
  }
}

export class UaMainMetricsOverviewResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();

    const { groupByFilter } = filter;

    if (row.getByHeader("installDate") || row.getByHeader("date")) {
      this.date = row.getByHeader("installDate") || row.getByHeader("date");
      this.setFormattedDate(
        this.date,
        filter.date.from,
        filter.date.to,
        groupByFilter.aggregationPeriod
      );
    }

    this.setGroupByValue(groupByFilter, row);
    this.data["adjustInstalls"] =
      super.parseFloat(row.getByHeader("adjustInstalls")) ?? 0;
    this.data["paidInstalls"] =
      super.parseFloat(row.getByHeader("paidInstalls")) ?? 0;
    this.data["cpi"] = super.parseFloat(
      row.getByHeader("cpi"),
      CohortHeaders.PRECISION
    );
    this.data["ecpiAdjust"] = super.parseFloat(
      row.getByHeader("ecpiAdjust"),
      CohortHeaders.PRECISION
    );
    this.data["spend"] = super.parseFloat(row.getByHeader("spend")) ?? 0;
    this.data["arpdau"] = super.parseFloat(
      row.getByHeader("arpdau"),
      ArpdauHeaders.PRECISION
    );
    this.data = {
      ...this.data,
      ...this.generateResultItemData(
        "roas",
        row,
        (filter as UaMainMetricsOverviewFilter).cohortMetricsDays
      ),
      ...this.generateResultItemData(
        "returnRate",
        row,
        (filter as UaMainMetricsOverviewFilter).cohortMetricsDays
      ),
      ...this.generateResultItemData(
        "timeSpent",
        row,
        (filter as UaMainMetricsOverviewFilter).cohortMetricsDays
      ),
      ...this.generateResultItemData(
        "arpu",
        row,
        (filter as UaMainMetricsOverviewFilter).cohortMetricsDays
      ),
    };
  }

  private generateResultItemData(
    name: string,
    row: ReportItemRowObject,
    days: Array<number>
  ): Record<string, number> {
    return days.reduce(
      (result, number) => ({
        ...result,
        [`${name}_${number}`]:
          super.parseFloat(
            row.getByHeader(`${name}_${number}`),
            ["arpu"].includes(name) ? 4 : CohortHeaders.PRECISION
          ) ?? undefined,
      }),
      {}
    );
  }
}
