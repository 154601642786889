import moment from "moment";
import DateUtil from "@/shared/utils/DateUtil";
import { CounterpartyCurrencyCode } from "@/accounting-portal/models/counterparties/CounterpartyCurrencyCode";
import ThresholdResponseModel from "@/accounting-portal/models/counterparties/ThresholdResponseModel";
import { ThresholdType } from "@/accounting-portal/models/counterparties/ThresholdType";

export enum InvoiceStatus {
  OPEN = "OPEN",
  PAID = "PAID",
  VALIDATED = "VALIDATED",
}

export enum InvoicePaymentStatus {
  OVERPAY = "OVERPAY",
  UNDERPAY = "UNDERPAY",
  OK = "OK",
}

export enum InvoiceExpectedDateStatus {
  OVERDUE = "OVERDUE",
  OK = "OK",
}

export enum InvoiceChartType {
  PAYMENT_STATUS = "PAYMENT_STATUS",
  EXPECTED_DATE_STATUS = "EXPECTED_DATE_STATUS",
}

export default class InvoiceResponseModel {
  constructor(
    public id: number,
    public counterpartyId: number,
    public counterpartyName: string,
    public currencyCode: CounterpartyCurrencyCode,
    public accrualMonth: string,
    public expectedAmount: number,
    public status: InvoiceStatus,
    public alertThresholds: Array<ThresholdResponseModel>,
    public showMenu = false,
    public finishDetails: InvoiceFinishDetailsModel | null = new InvoiceFinishDetailsModel(),
    public expectedPaymentDate?: string,
    public paymentDate?: string,
    public amount?: number
  ) {}

  get expectedDateStatus(): InvoiceExpectedDateStatus {
    return this.expectedPaymentDate &&
      moment(DateUtil.today()).isAfter(
        DateUtil.formatForDatePicker(moment(this.expectedPaymentDate))
      )
      ? InvoiceExpectedDateStatus.OVERDUE
      : InvoiceExpectedDateStatus.OK;
  }

  get paymentStatus(): InvoicePaymentStatus {
    const underpayAbsoluteValue =
      this.alertThresholds.find(({ type }) => type === ThresholdType.UNDERPAY)
        ?.absoluteValue ?? 0;
    const overpayAbsoluteValue =
      this.alertThresholds.find(({ type }) => type === ThresholdType.OVERPAY)
        ?.absoluteValue ?? 0;

    if (
      this.amount &&
      this.amount + underpayAbsoluteValue < this.expectedAmount
    ) {
      return InvoicePaymentStatus.UNDERPAY;
    } else if (
      this.amount &&
      this.amount - overpayAbsoluteValue > this.expectedAmount
    ) {
      return InvoicePaymentStatus.OVERPAY;
    }

    return InvoicePaymentStatus.OK;
  }

  static of(model: InvoiceResponseModel): InvoiceResponseModel {
    return new InvoiceResponseModel(
      model.id,
      model.counterpartyId,
      model.counterpartyName,
      model.currencyCode,
      model.accrualMonth,
      model.expectedAmount,
      model.status,
      model.alertThresholds,
      false,
      model.finishDetails
        ? InvoiceFinishDetailsModel.of(model.finishDetails)
        : null,
      model.expectedPaymentDate,
      model.paymentDate,
      model.amount
    );
  }

  static ofArray(
    items: Array<InvoiceResponseModel>
  ): Array<InvoiceResponseModel> {
    return items.map((item) => InvoiceResponseModel.of(item));
  }
}

export class InvoiceShortResponseModel {
  constructor(public id = 0, public accrualMonth = "") {}

  static of(model: InvoiceShortResponseModel): InvoiceShortResponseModel {
    return new InvoiceShortResponseModel(model.id, model.accrualMonth);
  }

  static ofArray(
    items: Array<InvoiceShortResponseModel>
  ): Array<InvoiceShortResponseModel> {
    return items.map((item) => InvoiceShortResponseModel.of(item));
  }
}

export class InvoiceFinishDetailsModel {
  constructor(
    public isFinishable = true,
    public unfinishedInvoices: Array<InvoiceShortResponseModel> = []
  ) {}

  static of(model: InvoiceFinishDetailsModel): InvoiceFinishDetailsModel {
    return new InvoiceFinishDetailsModel(
      model.isFinishable,
      InvoiceShortResponseModel.ofArray(model.unfinishedInvoices)
    );
  }
}

export interface InvoiceResponseExportModel {
  id: number;
  counterpartyId: number;
  counterpartyName: string;
  currencyCode: CounterpartyCurrencyCode;
  accrualMonth: string;
  expectedAmount: string;
  status: InvoiceStatus;
  alertThresholds: Array<ThresholdResponseModel>;
  showMenu: boolean;
  finishDetails: InvoiceFinishDetailsModel | null;
  expectedPaymentDate?: string;
  paymentDate?: string;
  amount?: string;
}
