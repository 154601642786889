export enum ChartName {
  DAU_DYNAMICS = "DAU_DYNAMICS",
  REVENUE_SPENDINGS_DAILY_DYNAMICS = "REVENUE_SPENDINGS_DAILY_DYNAMICS",
  CUMULATED_REVENUE_SPENDINGS_PROFIT = "CUMULATED_REVENUE_SPENDINGS_PROFIT",
  CUMULATED_ARPU_STRUCTURE = "CUMULATED_ARPU_STRUCTURE",
  CUMULATED_ARPU = "CUMULATED_ARPU",
  ROAS_N_DAY = "ROAS_N_DAY",
  RETENTION_RATE = "RETENTION_RATE",
  CUMULATED_PLAYTIME_PER_ACTIVE_USER = "CUMULATED_PLAYTIME_PER_ACTIVE_USER",
  REVENUE_REPORT_LINEAR_CHART = "Revenue report linear chart",
  REVENUE_REPORT_IAP_REVENUE_CHART = "Revenue report IAP Revenue chart",
  REVENUE_REPORT_REWARDED_CHART = "Revenue report Rewarded chart",
  REVENUE_REPORT_INTERSTITIAL_CHART = "Revenue report Interstitial chart",
  REVENUE_REPORT_BANNER_CHART = "Revenue report Banner chart",
  REVENUE_REPORT_STACKED_CHART = "Revenue report stacked chart",
  ARPDAU_REPORT_CHART = "Arpdau report chart",
  ARPDAU_ARPDAU_CHART = "Arpdau report ARPDAU chart",
  ARPDAU_ARPDAU_IAP_CHART = "Arpdau report ARPDAU IAP chart",
  ARPDAU_SUBSCRIPTION_CHART = "Arpdau report Subscription chart",
  ARPDAU_BANNER_CHART = "Arpdau report ARPDAU Banner chart",
  ARPDAU_INTERSTITIAL_CHART = "Arpdau report ARPDAU Interstitial chart",
  ARPDAU_REWARDED_CHART = "Arpdau report ARPDAU Rewarded chart",
  CALENDAR_PERMIN_REPORT_CHART = "Calendar permin report chart",
  CALENDAR_PERMIN_REPORT_EVENTS_BANNER_CHART = "Calendar permin report Events Banner chart",
  CALENDAR_PERMIN_REPORT_PERMIN_BANNER_CHART = "Calendar permin report Permin Banner chart",
  EVENTS_SUMMARY_REPORT_CHART = "Events summary report chart",
  EVENTS_SUMMARY_TOTAL_OCCURRENCES_CHART = "Events summary Total Occurrences chart",
  EVENTS_SUMMARY_AVG_EVENTS_PER_SESSION_CHART = "Events summary Avg Events per Session chart",
  EVENTS_SUMMARY_AVG_EVENTS_PER_DEVICE_CHART = "Events summary Avg Events per Device chart",
  EVENTS_SUMMARY_UNIQUE_DEVICES_CHART = "Events summary Unique Devices chart",
  TOTAL_OCCURRENCES_CHART = "Total Occurences chart",
  AVG_EVENTS_PER_SESSION_CHART = "Avg Events per Session chart",
  AVG_EVENTS_PER_DEVICE_CHART = "Avg Events per Device chart",
  UNIQUE_DEVICES_CHART = "Unique Devices chart",
  PROFIT_CHART = "Profit chart",
  CPM_REPORT_CHART = "CPM report chart",
  CPM_REPORT_BANNER_CHART = "CPM report Banner chart",
  CPM_REPORT_INTERSTITIAL_CHART = "CPM report Interstitial chart",
  CPM_REPORT_REWARDED_CHART = "CPM report Rewarded chart",
  ARPU_REPORT_CHART = "Arpu report chart",
  ARPU_REPORT_DAY_1_CHART = "Arpu report 1st day chart",
  ARPU_REPORT_DAY_7_CHART = "Arpu report 7th day chart",
  ARPU_REPORT_DAY_14_CHART = "Arpu report 14th day chart",
  ARPU_REPORT_TOTAL_CHART = "Arpu report total report chart",
  ARPU_REPORT_SUBTOTAL_CHART = "Arpu report subtotal report chart",
  RETURN_RATE_REPORT_CHART = "Return rate report chart",
  RETURN_RATE_REPORT_DAY_1_CHART = "Return rate  1st day report chart",
  RETURN_RATE_REPORT_DAY_7_CHART = "Return rate 7th day report chart",
  RETURN_RATE_REPORT_DAY_14_CHART = "Return rate 14th day report chart",
  RETURN_RATE_REPORT_TOTAL_CHART = "Return rate total report chart",
  RETURN_RATE_REPORT_SUBTOTAL_CHART = "Return rate subtotal report chart",
  MEASURES_LITE_REPORT_CHART = "Measures lite report chart",
  MEASURES_LITE_REPORT_DAY_1_CHART = "Measures lite 1st day report chart",
  MEASURES_LITE_REPORT_DAY_7_CHART = "Measures lite 7th day report chart",
  MEASURES_LITE_REPORT_DAY_14_CHART = "Measures lite 14th day report chart",
  MEASURES_LITE_REPORT_TOTAL_CHART = "Measures lite total report chart",
  MEASURES_LITE_REPORT_SUBTOTAL_CHART = "Measures lite subtotal report chart",
  TIME_SPENT_REPORT_CHART = "Time spent report chart",
  TIME_SPENT_REPORT_DAY_1_CHART = "Time spent 1st day report chart",
  TIME_SPENT_REPORT_DAY_7_CHART = "Time spent 7th day report chart",
  TIME_SPENT_REPORT_DAY_14_CHART = "Time spent 14th day report chart",
  TIME_SPENT_REPORT_TOTAL_CHART = "Time spent total report chart",
  TIME_SPENT_REPORT_SUBTOTAL_CHART = "Time spent subtotal report chart",
  COHORT_PERMIN_REPORT_CHART = "Cohort permin report chart",
  COHORT_PERMIN_REPORT_DAY_1_CHART = "Cohort permin report 1st day chart",
  COHORT_PERMIN_REPORT_DAY_7_CHART = "Cohort permin report 7th day chart",
  COHORT_PERMIN_REPORT_DAY_14_CHART = "Cohort permin report 14th day chart",
  METRICS_TOTAL_CHART = "Metrics total chart",
  RETURN_RATE_METRICS_CHART = "Return Rate Metrics chart",
  RETURN_RATE_METRICS_SUBTOTAL_CHART = "Metrics subtotal chart",
  RETURN_RATE_METRICS_DAY_3_CHART = "Return Rate 3rd day Metrics chart",
  RETURN_RATE_METRICS_DAY_7_CHART = "Return Rate 7th day Metrics chart",
  RETURN_RATE_METRICS_DAY_14_CHART = "Return Rate 14th day Metrics chart",
  RETURN_RATE_METRICS_DAY_21_CHART = "Return Rate 21th day Metrics chart",
  RETURN_RATE_METRICS_DAY_30_CHART = "Return Rate 30th day Metrics chart",
  TIME_SPENT_METRICS_CHART = "Time Spent Metrics chart",
  TIME_SPENT_METRICS_SUBTOTAL_CHART = "Time Spent Metrics subtotal chart",
  TIME_SPENT_METRICS_DAY_3_CHART = "Time spent 3rd day Metrics chart",
  TIME_SPENT_METRICS_DAY_7_CHART = "Time spent 7th day Metrics chart",
  TIME_SPENT_METRICS_DAY_14_CHART = "Time spent 14th day Metrics chart",
  TIME_SPENT_METRICS_DAY_21_CHART = "Time spent 21th day Metrics chart",
  TIME_SPENT_METRICS_DAY_30_CHART = "Time spent 30th day Metrics chart",
  ARPU_METRICS_CHART = "ARPU Metrics chart",
  ARPU_METRICS_SUBTOTAL_CHART = "ARPU Metrics subtotal chart",
  ARPU_METRICS_DAY_3_CHART = "ARPU 3rd day Metrics chart",
  ARPU_METRICS_DAY_7_CHART = "ARPU 7th day Metrics chart",
  ARPU_METRICS_DAY_14_CHART = "ARPU 14th day Metrics chart",
  ARPU_METRICS_DAY_21_CHART = "ARPU 21th day Metrics chart",
  ARPU_METRICS_DAY_30_CHART = "ARPU 30th day Metrics chart",
  // INSTALLS_AND_ARPU_METRICS_CHART = "Installs and ARPU Metrics chart",
  FUNNEL_CHART = "Funnel chart",
  FUNNEL_COLUMN_CHART = "Funnel column chart",
  REPEATED_CONVERSION_FUNNEL_CHART = "Repeated conversion funnel chart",
  REPEATED_CONVERSION_FUNNEL_COLUMN_CHART = "Repeated conversion funnel column chart",
  COLUMN_CHART = "Column chart",
  AB_TEST_ARPU_CHART = "AbTest ARPU chart",
  AB_TEST_RETENTION_RATE_CHART = "AbTest Retention Rate chart",
  AB_TEST_TIME_SPENT_CHART = "AbTest Time Spent chart",
  AB_TEST_ARPU_PREV_VALIDATION_CHART = "AbTest ARPU Prev Validation chart",
  AB_TEST_RETENTION_RATE_PREV_VALIDATION_CHART = "AbTest Retention Rate Prev Validation chart",
  AB_TEST_TIME_SPENT_PREV_VALIDATION_CHART = "AbTest Time Spent Prev Validation chart",
  ARPDAU_ALL_USERS_CHART = "Arpdau All users chart",
  ARPDAU_SIMPLE_ALL_USERS_ARPDAU_CHART = "Arpdau Simple All users ARPDAU chart",
  ARPDAU_SIMPLE_ALL_USERS_ARPDAU_IAP_CHART = "Arpdau Simple All users ARPDAU IAP chart",
  ARPDAU_SIMPLE_ALL_USERS_BANNER_CHART = "Arpdau Simple All users ARPDAU Banner chart",
  ARPDAU_SIMPLE_ALL_USERS_INTERSTITIAL_CHART = "Arpdau Simple All users ARPDAU Interstitial chart",
  ARPDAU_SIMPLE_ALL_USERS_REWARDED_CHART = "Arpdau Simple All users ARPDAU Rewarded chart",
  ARPDAU_ACTIVE_USERS_CHART = "Arpdau AdActive users chart",
  ARPDAU_SIMPLE_ACTIVE_USERS_ARPDAU_CHART = "Arpdau Simple Active users ARPDAU chart",
  ARPDAU_SIMPLE_ACTIVE_USERS_BANNER_CHART = "Arpdau Simple Active users ARPDAU Banner chart",
  ARPDAU_SIMPLE_ACTIVE_USERS_INTERSTITIAL_CHART = "Arpdau Simple Active users ARPDAU Interstitial chart",
  ARPDAU_SIMPLE_ACTIVE_USERS_REWARDED_CHART = "Arpdau Simple Active users ARPDAU Rewarded chart",
  CALENDAR_CTR_CHART = "Calendar CTR chart",
  CALENDAR_CTR_BANNER_CHART = "Calendar CTR Banner chart",
  CALENDAR_CTR_INTERSTITIAL_CHART = "Calendar CTR Interstitial chart",
  CALENDAR_CTR_REWARDED_CHART = "Calendar CTR Rewarded chart",
  DIFF_INSTALLS_STORES_CHART = "Diff Installs Stores chart",
  COHORT_CTR_CHART = "Cohort CTR chart",
  COHORT_CTR_TOTAL_CHART = "Cohort CTR total chart",
  COHORT_CTR_SUBTOTAL_CHART = "Cohort CTR subtotal chart",
  COHORT_CTR_DAY_3_CHART = "Cohort CTR 3rd day chart",
  COHORT_CTR_DAY_7_CHART = "Cohort CTR 7th day chart",
  COHORT_CTR_DAY_14_CHART = "Cohort CTR 14th day chart",
  COHORT_CTR_DAY_30_CHART = "Cohort CTR 30th day chart",
  AD_ROAS_COUNTRY_CHART = "Ad ROAS Country chart",
  AD_ROAS_COUNTRY_TOTAL_CHART = "Ad ROAS Country total chart",
  AD_ROAS_COUNTRY_SUBTOTAL_CHART = "Ad ROAS Country subtotal chart",
  AD_ROAS_COUNTRY_DAY_0_CHART = "Ad ROAS Country 0th day chart",
  AD_ROAS_COUNTRY_DAY_1_CHART = "Ad ROAS Country 1st day chart",
  AD_ROAS_COUNTRY_DAY_3_CHART = "Ad ROAS Country 3rd day chart",
  AD_ROAS_COUNTRY_DAY_7_CHART = "Ad ROAS Country 7th day chart",
  AD_ROAS_COUNTRY_DAY_14_CHART = "Ad ROAS Country 14th day chart",
  AD_ROAS_COUNTRY_DAY_30_CHART = "Ad ROAS Country 30th day chart",
  AD_ROAS_COUNTRY_DAY_90_CHART = "Ad ROAS Country 90th day chart",
  AD_ROAS_COUNTRY_DAY_120_CHART = "Ad ROAS Country 120th day chart",
  AD_ROAS_COUNTRY_DAY_150_CHART = "Ad ROAS Country 150th day chart",
  AD_ROAS_COUNTRY_DAY_180_CHART = "Ad ROAS Country 180th day chart",
  AD_ROAS_COUNTRY_DAY_210_CHART = "Ad ROAS Country 210th day chart",
  AD_ROAS_COUNTRY_DAY_240_CHART = "Ad ROAS Country 240th day chart",
  AD_ROAS_COUNTRY_DAY_270_CHART = "Ad ROAS Country 270th day chart",
  AD_ROAS_COUNTRY_DAY_300_CHART = "Ad ROAS Country 300th day chart",
  AD_ROAS_COUNTRY_DAY_330_CHART = "Ad ROAS Country 330th day chart",
  AD_ROAS_COUNTRY_DAY_364_CHART = "Ad ROAS Country 364th day chart",
  AD_ROAS_NETWORK_CHART = "Ad ROAS Network chart",
  AD_ROAS_NETWORK_TOTAL_CHART = "Ad ROAS Network total chart",
  AD_ROAS_NETWORK_SUBTOTAL_CHART = "Ad ROAS Network subtotal chart",
  AD_ROAS_NETWORK_DAY_0_CHART = "Ad ROAS Network 0th day chart",
  AD_ROAS_NETWORK_DAY_1_CHART = "Ad ROAS Network 1st day chart",
  AD_ROAS_NETWORK_DAY_3_CHART = "Ad ROAS Network 3rd day chart",
  AD_ROAS_NETWORK_DAY_7_CHART = "Ad ROAS Network 7th day chart",
  AD_ROAS_NETWORK_DAY_14_CHART = "Ad ROAS Network 14th day chart",
  AD_ROAS_NETWORK_DAY_30_CHART = "Ad ROAS Network 30th day chart",
  AD_ROAS_NETWORK_DAY_90_CHART = "Ad ROAS Network 90th day chart",
  AD_ROAS_NETWORK_DAY_120_CHART = "Ad ROAS Network 120th day chart",
  AD_ROAS_NETWORK_DAY_150_CHART = "Ad ROAS Network 150th day chart",
  AD_ROAS_NETWORK_DAY_180_CHART = "Ad ROAS Network 180th day chart",
  AD_ROAS_NETWORK_DAY_210_CHART = "Ad ROAS Network 210th day chart",
  AD_ROAS_NETWORK_DAY_240_CHART = "Ad ROAS Network 240th day chart",
  AD_ROAS_NETWORK_DAY_270_CHART = "Ad ROAS Network 270th day chart",
  AD_ROAS_NETWORK_DAY_300_CHART = "Ad ROAS Network 300th day chart",
  AD_ROAS_NETWORK_DAY_330_CHART = "Ad ROAS Network 330th day chart",
  AD_ROAS_NETWORK_DAY_364_CHART = "Ad ROAS Network 364th day chart",
  SOV_CHART = "Share of Voice",
  SOW_CHART = "Share of Wallet",
  COHORT_CPM_CHART = "Cohort CPM chart",
  COHORT_CPM_TOTAL_CHART = "Cohort CPM total chart",
  COHORT_CPM_SUBTOTAL_CHART = "Cohort CPM subtotal chart",
  COHORT_CPM_DAY_3_CHART = "Cohort CPM 3rd day chart",
  COHORT_CPM_DAY_7_CHART = "Cohort CPM 7th day chart",
  COHORT_CPM_DAY_14_CHART = "Cohort CPM 14th day chart",
  COHORT_CPM_DAY_30_CHART = "Cohort CPM 30th day chart",
  METRICS_SPEND_TOTAL_CHART = "Metrics and Spend total chart",
  METRICS_SPEND_RETURN_RATE_CHART = "Return Rate Metrics and Spend chart",
  METRICS_SPEND_RETURN_RATE_SUBTOTAL_CHART = "Return Rate Metrics and Spend subtotal chart",
  METRICS_SPEND_RETURN_RATE_DAY_3_CHART = "Return Rate Metrics and Spend 3rd day chart",
  METRICS_SPEND_RETURN_RATE_DAY_7_CHART = "Return Rate Metrics and Spend 7th day chart",
  METRICS_SPEND_RETURN_RATE_DAY_30_CHART = "Return Rate Metrics and Spend 30th day chart",
  METRICS_SPEND_TIME_SPENT_CHART = "Time Spent Metrics and Spend chart",
  METRICS_SPEND_TIME_SPENT_SUBTOTAL_CHART = "Time Spent Metrics and Spend subtotal chart",
  METRICS_SPEND_TIME_SPENT_DAY_3_CHART = "Time Spent Metrics and Spend 3rd day chart",
  METRICS_SPEND_TIME_SPENT_DAY_7_CHART = "Time Spent Metrics and Spend 7th day chart",
  METRICS_SPEND_TIME_SPENT_DAY_30_CHART = "Time Spent Metrics and Spend 30th day chart",
  METRICS_SPEND_ENGAGEMENT_CHART = "Engagement Metrics and Spend chart",
  METRICS_SPEND_ENGAGEMENT_SUBTOTAL_CHART = "Engagement Metrics and Spend subtotal chart",
  METRICS_SPEND_ENGAGEMENT_DAY_3_CHART = "Engagement Metrics and Spend 3rd day chart",
  METRICS_SPEND_ENGAGEMENT_DAY_7_CHART = "Engagement Metrics and Spend 7th day chart",
  METRICS_SPEND_ENGAGEMENT_DAY_30_CHART = "Engagement Metrics and Spend 30th day chart",
  METRICS_SPEND_VALUE_CHART = "Value Metrics and Spend chart",
  METRICS_SPEND_VALUE_SUBTOTAL_CHART = "Value Metrics and Spend subtotal chart",
  METRICS_SPEND_VALUE_DAY_3_CHART = "Value Metrics and Spend 3rd day chart",
  METRICS_SPEND_VALUE_DAY_7_CHART = "Value Metrics and Spend 7th day chart",
  METRICS_SPEND_VALUE_DAY_30_CHART = "Value Metrics and Spend 30th day chart",
  FILL_RATE_BANNER_CHART = "Fill Rate Banner chart",
  FILL_RATE_BANNER_PER_DAU_CHART = "Fill Rate Banner Per DAU chart",
  FILL_RATE_INTER_CHART = "Fill Rate Inter chart",
  FILL_RATE_INTER_PER_DAU_CHART = "Fill Rate Inter Per DAU chart",
  FILL_RATE_REWARD_CHART = "Fill Rate Reward chart",
  FILL_RATE_REWARD_PER_DAU_CHART = "Fill Rate Reward Per DAU chart",
  FILL_RATE_GROUPED_BANNER_CHART = "Fill Rate Grouped Banner chart",
  FILL_RATE_GROUPED_BANNER_PER_DAU_CHART = "Fill Rate Grouped Banner Per DAU chart",
  FILL_RATE_GROUPED_INTER_CHART = "Fill Rate Grouped Inter chart",
  FILL_RATE_GROUPED_INTER_PER_DAU_CHART = "Fill Rate Grouped Inter Per DAU chart",
  FILL_RATE_GROUPED_REWARD_CHART = "Fill Rate Grouped Reward chart",
  FILL_RATE_GROUPED_REWARD_PER_DAU_CHART = "Fill Rate Grouped Reward Per DAU chart",
  PRICED_REVENUE_LINEAR_CHART = "Priced Revenue report linear chart",
  PRICED_REVENUE_IAP_REVENUE_CHART = "Priced Revenue report IAP Revenue chart",
  PRICED_REVENUE_SUBSCRIPTION_CHART = "Priced Revenue report Subscription chart",
  PRICED_REVENUE_REWARDED_CHART = "Priced Revenue report Rewarded chart",
  PRICED_REVENUE_INTERSTITIAL_CHART = "Priced Revenue report Interstitial chart",
  PRICED_REVENUE_BANNER_CHART = "Priced Revenue report Banner chart",
  PRICED_REVENUE_STACKED_CHART = "Priced Revenue report stacked chart",
  REVENUE_DIFF_PERCENT_DATE_COUNTRY_BASED_DIFF_REVENUE_DAY = "Revenue diff percent date country chart based diff revenue day",
  REVENUE_DIFF_PERCENT_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_DAY = "Revenue diff percent date country chart based diff impressions day",
  REVENUE_DIFF_PERCENT_DATE_NETWORK_BASED_DIFF_REVENUE_DAY = "Revenue diff percent date network chart based diff revenue day",
  REVENUE_DIFF_PERCENT_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_DAY = "Revenue diff percent date network chart based diff impressions day",
  ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_REVENUE_PREV_DAY = "Arpdau diff date country chart based diff revenue prev day",
  ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_PREV_DAY = "Arpdau diff date country chart based diff impressions prev day",
  ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_REVENUE_PREV_YEAR = "Arpdau diff date country chart based diff revenue prev year",
  ARPDAU_DIFF_DATE_COUNTRY_BASED_DIFF_IMPRESSIONS_PREV_YEAR = "Arpdau diff date country chart based diff impressions prev year",
  ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_REVENUE_PREV_DAY = "Arpdau diff date network chart based diff revenue prev day",
  ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_PREV_DAY = "Arpdau diff date network chart based diff impressions prev day",
  ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_REVENUE_PREV_YEAR = "Arpdau diff date network chart based diff revenue prev year",
  ARPDAU_DIFF_DATE_NETWORK_BASED_DIFF_IMPRESSIONS_PREV_YEAR = "Arpdau diff date network chart based diff impressions prev year",
  TRAFFIC_QUALITY_TOTAL_CHART = "Traffic Quality total chart",
  TRAFFIC_QUALITY_TIME_SPENT_CHART = "Traffic Quality Time Spent chart",
  TRAFFIC_QUALITY_TIME_SPENT_SUBTOTAL_CHART = "Traffic Quality Time Spent subtotal chart",
  TRAFFIC_QUALITY_TIME_SPENT_DAY_7_CHART = "Traffic Quality Time Spent 7th day chart",
  TRAFFIC_QUALITY_TIME_SPENT_DAY_14_CHART = "Traffic Quality Time Spent 14th day chart",
  TRAFFIC_QUALITY_TIME_SPENT_DAY_30_CHART = "Traffic Quality Time Spent 30th day chart",
  TRAFFIC_QUALITY_RETURN_RATE_CHART = "Traffic Quality Return Rate chart",
  TRAFFIC_QUALITY_RETURN_RATE_SUBTOTAL_CHART = "Traffic Quality Return Rate subtotal chart",
  TRAFFIC_QUALITY_RETURN_RATE_DAY_7_CHART = "Traffic Quality Return Rate 7th day chart",
  TRAFFIC_QUALITY_RETURN_RATE_DAY_14_CHART = "Traffic Quality Return Rate 14th day chart",
  TRAFFIC_QUALITY_RETURN_RATE_DAY_30_CHART = "Traffic Quality Return Rate 30th day chart",
  TRAFFIC_QUALITY_ARPU_CHART = "Traffic Quality ARPU chart",
  TRAFFIC_QUALITY_ARPU_SUBTOTAL_CHART = "Traffic Quality ARPU subtotal chart",
  TRAFFIC_QUALITY_ARPU_DAY_7_CHART = "Traffic Quality ARPU 7th day chart",
  TRAFFIC_QUALITY_ARPU_DAY_14_CHART = "Traffic Quality ARPU 14th day chart",
  TRAFFIC_QUALITY_ARPU_DAY_30_CHART = "Traffic Quality ARPU 30th day chart",
  TRAFFIC_QUALITY_ARPPU_CHART = "Traffic Quality ARPPU chart",
  TRAFFIC_QUALITY_ARPPU_SUBTOTAL_CHART = "Traffic Quality ARPPU subtotal chart",
  TRAFFIC_QUALITY_ARPPU_DAY_7_CHART = "Traffic Quality ARPPU 7th day chart",
  TRAFFIC_QUALITY_ARPPU_DAY_14_CHART = "Traffic Quality ARPPU 14th day chart",
  TRAFFIC_QUALITY_ARPPU_DAY_30_CHART = "Traffic Quality ARPPU 30th day chart",
  TRAFFIC_QUALITY_PAYING_USERS_CHART = "Traffic Quality Paying Users chart",
  TRAFFIC_QUALITY_PAYING_USERS_SUBTOTAL_CHART = "Traffic Quality Paying Users subtotal chart",
  TRAFFIC_QUALITY_PAYING_USERS_DAY_7_CHART = "Traffic Quality Paying Users 7th day chart",
  TRAFFIC_QUALITY_PAYING_USERS_DAY_14_CHART = "Traffic Quality Paying Users 14th day chart",
  TRAFFIC_QUALITY_PAYING_USERS_DAY_30_CHART = "Traffic Quality Paying Users 30th day chart",
}

export enum AbTestSeriesType {
  CONFIDENCE_INTERVAL = "CONFIDENCE_INTERVAL",
  CONTROL_GROUP = "CONTROL_GROUP",
  TARGET_GROUP = "TARGET_GROUP",
}

export enum XAxisType {
  CATEGORY = "CATEGORY",
  DATE = "DATE",
}

export enum XYSeriesType {
  COLUMN = "COLUMN",
  LINE = "LINE",
}

export interface ChartOptions {
  legend?: boolean;
}

export interface AbTestChartOptions extends ChartOptions {
  xAxis: AbTestXAxis;
  yAxis: AbTestYAxis;
  series: Array<AbTestSeries>;
  legend?: boolean;
}

export interface SlicedChartOptions extends ChartOptions {
  series: SliceSeries;
  legend?: boolean;
}

export interface XYChartOptions extends ChartOptions {
  xAxis: XAxis;
  yAxes: Array<YAxis>;
  scrollbar?: boolean;
  legend?: boolean;
  sortDataBy?: string;
  hasToggle?: boolean;
  hasAdditionalBottomChart?: boolean;
  additionalChartValue?: string;
}

export interface AbTestXAxis {
  title: string;
  category: string;
  validationRange?: boolean;
}

export interface AbTestYAxis {
  title: string;
}

export interface AbTestSeries {
  type: AbTestSeriesType;
  name: string;
  x: string;
  y: string;
  yOpen?: string;
  tooltipText?: string;
  label?: string;
}

export interface SliceSeries {
  value: string;
  category: string;
  legendLabelText?: string;
  legendValueText?: string;
  labelText?: string;
  tooltipText?: string;
}

export interface XAxis {
  title?: string;
  type: XAxisType;
  category?: string;
  hiddenLabels?: boolean;
}

export interface YAxis {
  min?: 0;
  title?: string;
  postfix?: string;
  series: Array<XYSeries>;
  numberFormat?: string;
  rangeAxes?: Array<XYRangeAxis>;
}

export interface XYRangeAxis {
  value: string;
  color: number;
  label: string;
  endValue?: string;
}

export interface XYSeries {
  name: string;
  y: string;
  x: string;
  type: XYSeriesType;
  color?: number;
  stacked?: boolean;
  tooltipText?: string;
  labelText?: string;
  strokeWidth?: number;
  range?: {
    color: number;
    value?: number;
    endValue: number;
  };
  hasUnfilledData?: boolean;
}
