import { CohortFilter, CohortHeaders } from "./CohortReport";
import { GroupByFilter } from "./GroupByFilter";
import { ReportType } from "./ReportType";
import {
  DatesFilterModel,
  FilterId,
  recordToFilterModel,
  FilterModel,
  FilterPreviewId,
  Application,
} from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import { GroupByType, ReportHeader, ReportHeaderUtil } from "./ReportHeader";
import { ReportFilter, ReportResultItem } from "./Report";
import { ReportDataType } from "./ReportVisualization";
import {
  NetRevenueReportFilterExtension,
  TimeSpentTypeReportFilterExtension,
} from "./ReportFilterExtension";
import { ChartName } from "@/chart/models/ChartModel";
import { TimeSpentReportType } from "./TimeSpentReportType";
import { ReportItemRowObject } from "./ReportItemRowObject";

const DAYS = [7, 14, 30];

export class TrafficQualityHeaders {
  static init(
    lang: LangService,
    report: TrafficQualityFilter,
    reportDataType: ReportDataType,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    return [
      ...(![ReportDataType.TOTAL, ReportDataType.SUB_TOTAL].includes(
        reportDataType
      )
        ? [
            ReportHeaderUtil.createDate(
              lang,
              report.groupByFilter.aggregationPeriod
            ),
          ]
        : []),
      ...(report.groupByFilter.isNotEmptyGroupBy &&
      reportDataType !== ReportDataType.TOTAL
        ? ReportHeaderUtil.createGroupBy(lang, report.groupByFilter, 200)
        : []),
      {
        text: lang("components.baseTable.spend"),
        align: "end",
        value: ReportResultItem.PREFIX + "spend",
        width: 90,
      },
      {
        text: lang("components.baseTable.installs"),
        align: "end",
        value: ReportResultItem.PREFIX + "installs",
        width: 90,
      },
      ...this.generateTrafficQualityHeaders(
        ["returnRate", "timeSpent", "arpu", "arppu", "payingUsers"],
        lang,
        groupBy
      ),
    ];
  }

  static generateTrafficQualityHeaders(
    names: Array<string>,
    lang: LangService,
    groupBy: GroupByType
  ): Array<ReportHeader> {
    const result: Array<ReportHeader> = [];
    const generateReportHeader = (name: string, day: number) => ({
      text: lang(`components.baseTable.metrics.${name}`, day),
      align: "end",
      value: `${ReportResultItem.PREFIX}${name}_${day}`,
      hasGradient: true,
      fractionDigits: ["arpu", "arppu"].includes(name)
        ? 4
        : CohortHeaders.PRECISION,
      class: "text-no-wrap",
      ...(name === "payingUsers" ? { postfix: "%" } : {}),
    });

    if (groupBy === GroupByType.METRICS) {
      names.forEach((name) => {
        result.push(
          ...DAYS.map(
            (day) =>
              ({
                ...generateReportHeader(name, day),
                groupBy: {
                  border: {
                    left: day === DAYS[0],
                    right:
                      name === names[names.length - 1] &&
                      day === DAYS[DAYS.length - 1],
                  },
                  hasBackground: names
                    .filter((_, index) => index % 2 === 0)
                    .includes(name),
                },
              } as ReportHeader)
          )
        );
      });
    } else {
      DAYS.forEach((day) => {
        result.push(
          ...names.map(
            (name) =>
              ({
                ...generateReportHeader(name, day),
                groupBy: {
                  border: {
                    left: name === names[0],
                    right:
                      name === names[names.length - 1] &&
                      day === DAYS[DAYS.length - 1],
                  },
                  hasBackground: DAYS.filter(
                    (_, index) => index % 2 === 0
                  ).includes(day),
                },
              } as ReportHeader)
          )
        );
      });
    }

    return result;
  }
}

export class TrafficQualityFilter
  extends CohortFilter
  implements
    NetRevenueReportFilterExtension,
    TimeSpentTypeReportFilterExtension
{
  constructor(
    app: Application,
    filter?: Array<FilterModel>,
    date?: DatesFilterModel,
    groupByFilter?: GroupByFilter,
    public timeSpentType = TimeSpentReportType.TIME_SPENT,
    public netRevenue = true
  ) {
    super(ReportType.TRAFFIC_QUALITY, app, filter, date, groupByFilter, 31);
  }

  get previews() {
    return [
      ...super.previews,
      {
        id: FilterPreviewId.TIME_SPENT_TYPE,
        value: this.timeSpentType,
      },
      {
        id: FilterPreviewId.NET_REVENUE,
        value: this.netRevenue,
      },
    ];
  }

  get charts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ...this.getGroupedCohortChartNames(
            "TRAFFIC_QUALITY_RETURN_RATE",
            DAYS
          ),
          ...this.getGroupedCohortChartNames(
            "TRAFFIC_QUALITY_TIME_SPENT",
            DAYS
          ),
          ...this.getGroupedCohortChartNames("TRAFFIC_QUALITY_ARPU", DAYS),
          ...this.getGroupedCohortChartNames("TRAFFIC_QUALITY_ARPPU", DAYS),
          ...this.getGroupedCohortChartNames(
            "TRAFFIC_QUALITY_PAYING_USERS",
            DAYS
          ),
        ]
      : [
          ChartName.TRAFFIC_QUALITY_RETURN_RATE_CHART,
          ChartName.TRAFFIC_QUALITY_TIME_SPENT_CHART,
          ChartName.TRAFFIC_QUALITY_ARPU_CHART,
          ChartName.TRAFFIC_QUALITY_ARPPU_CHART,
          ChartName.TRAFFIC_QUALITY_PAYING_USERS_CHART,
        ];
  }

  get groupedCharts(): Array<ChartName> {
    return this.groupByFilter.isNotEmptyGroupBy
      ? [
          ChartName.TRAFFIC_QUALITY_RETURN_RATE_CHART,
          ChartName.TRAFFIC_QUALITY_TIME_SPENT_CHART,
          ChartName.TRAFFIC_QUALITY_ARPU_CHART,
          ChartName.TRAFFIC_QUALITY_ARPPU_CHART,
          ChartName.TRAFFIC_QUALITY_PAYING_USERS_CHART,
        ]
      : [];
  }

  static of(
    app: Application,
    filter?: Record<string, any>
  ): TrafficQualityFilter {
    return filter
      ? new TrafficQualityFilter(
          app,
          filter.filter?.length
            ? filter.filter.map((it: any) => recordToFilterModel(it))
            : [],
          filter.date
            ? DatesFilterModel.ofRecord(
                FilterId.ATTRIBUTION_DATE_VALUE,
                filter.date
              )
            : undefined,
          filter.groupByFilter
            ? GroupByFilter.of(filter.groupByFilter)
            : undefined,
          filter.timeSpentType
          // TODO: NET_REVENUE need to uncomment later
          // typeof filter.netRevenue === "string"
          //   ? filter.netRevenue === "true"
          //   : filter.netRevenue,
        )
      : new TrafficQualityFilter(app);
  }

  toRequestQuery(): Record<string, any> {
    return {
      ...super.toRequestQuery(),
      timeSpentType: this.timeSpentType,
      // TODO: NET_REVENUE need to uncomment later
      // netRevenue: this.netRevenue,
    };
  }
}

export class TrafficQualityResultItem extends ReportResultItem {
  constructor(row: ReportItemRowObject, filter: ReportFilter) {
    super();

    const { groupByFilter } = filter;

    if (row.getByHeader("installDate") || row.getByHeader("date")) {
      this.date = row.getByHeader("installDate") || row.getByHeader("date");
      this.setFormattedDate(
        this.date,
        filter.date.from,
        filter.date.to,
        groupByFilter.aggregationPeriod
      );
    }

    this.setGroupByValue(groupByFilter, row);
    this.data["spend"] = row.getByHeader("spend");
    this.data["installs"] = row.getByHeader("installs") ?? 0;
    this.data = {
      ...this.data,
      ...this.generateResultItemData("timeSpent", row),
      ...this.generateResultItemData("returnRate", row),
      ...this.generateResultItemData("arpu", row),
      ...this.generateResultItemData("arppu", row),
      ...this.generateResultItemData("payingUsers", row),
    };
  }

  private generateResultItemData(
    name: string,
    row: ReportItemRowObject
  ): Record<string, number> {
    return DAYS.reduce(
      (result, number) => ({
        ...result,
        [`${name}_${number}`]:
          row.getByHeader(`${name}_${number}`) ?? undefined,
      }),
      {}
    );
  }
}
